import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { useTable, usePagination, useGlobalFilter, useSortBy } from 'react-table';
import Swal from 'sweetalert2';
import Select from "react-select";
import { SERVER_BASE } from '../config/server';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

const LedgerMasterPage = () => {
  const [ledgerGroups, setLedgerGroups] = useState([]);
  const [ledgers, setLedgers] = useState([]);
  const [formVisible, setFormVisible] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const code = sessionStorage.getItem('code');

  const [formData, setFormData] = useState({
    selectedGroup:'',
    ledgerGroupCode: '',
    ledgerName: '',
    ledgerCode: '',
    gstin: '',
    address: '',
    pincode: '',
    state: '',
    person1: '',
    position1: '',
    mobile1: '',
    person2: '',
    position2: '',
    mobile2: '',
  });

  useEffect(() => {
    fetchLedgerGroups();
    fetchLedgerMasters(); // Fetch ledger masters on component load
  }, []);

  const fetchLedgerMasters = async () => {
    try {
      const response = await axios.get(SERVER_BASE+'/ledgerMasters');
      // console.log(response.data);
      
      setLedgers(response.data); // Set the ledger data fetched from backend
    } catch (error) {
      console.error('Error fetching ledger masters:', error);
    }
  };

  const fetchLedgerGroups = async () => {
    try {
      const response = await axios.get(SERVER_BASE+'/ledger-groups', {
        params: { code },
      });
      const groups = response.data.map((group) => ({
        value: group.groupName,
        label: group.groupName,
        ledgerGroupCode: group.groupCode,
      }));
      setLedgerGroups(groups);
    } catch (error) {
      console.error('Error fetching ledger groups:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
      setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSelectChange = (name, value) => {
    if (name === "selectedGroup") {
      setFormData({
        ...formData,
        selectedGroup: value,
        ledgerGroupCode: value?.ledgerGroupCode || "",
      });
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const toggleFormVisibility = () => {
    setFormVisible(!formVisible);
    resetForm();
    setEditIndex(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const { selectedGroup, ledgerName, ledgerCode } = formData;
  
    // Validation to check if the required fields are filled
    if (ledgerName.trim() === '' || ledgerCode.trim() === '' || selectedGroup === '') {
      Swal.fire('Please fill all the required fields', '', 'warning');
      return;
    }
  
    // Show confirmation popup for creating or updating ledger
    Swal.fire({
      title: editIndex !== null ? 'Confirm Update' : 'Confirm Create',
      text: editIndex !== null ? 'Are you sure you want to update this ledger?' : 'Are you sure you want to create this ledger?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          // Prepare data to be saved
          const ledgerData = { ...formData, code };
          // console.log(ledgerData);
          
  
          if (editIndex !== null) {
            // Update existing ledger in the database
            const response = await axios.put(`${SERVER_BASE}/updateLedgerMaster/${ledgers[editIndex].id}`, ledgerData);
  
            if (response.status === 200) {
              fetchLedgerMasters();
              setEditIndex(null);
              Swal.fire('Updated!', 'Ledger has been updated.', 'success');
            }
          } else {
            // Create new ledger in the database
            const response = await axios.post(SERVER_BASE+'/addledgerMaster', ledgerData);
  
            if (response.status === 200) {
              fetchLedgerMasters();
              Swal.fire('Created!', 'Ledger has been created.', 'success');
            }
          }
  
          // Reset form and hide the form after submission
          toggleFormVisibility();
        } catch (error) {
          console.error('Error saving ledger:', error);
          Swal.fire('Error!', 'There was an error while saving the ledger.', 'error');
        }
      }
    });
  };
  

  const resetForm = () => {
    setFormData({
      selectedGroup: '',
      ledgerGroupCode: '',
      ledgerName: '',
      ledgerCode: '',
      gstin: '',
      address: '',
      pincode: '',
      state: '',
      person1: '',
      position1: '',
      mobile1: '',
      person2: '',
      position2: '',
      mobile2: '',
    });
  };
  const handleEdit = (index) => {
    const selectedGroupOption = ledgerGroups.find(
      (group) => group.value === ledgers[index].ledgerGroup
    ) || null; // Fallback to null if no match is found
  
    setFormData({
      ...ledgers[index],
      selectedGroup: selectedGroupOption,
    });
    setEditIndex(index);
    setFormVisible(true);
  };
  
  const handleDelete = (index) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this ledger!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        // Call the backend delete route
        axios
          .delete(`${SERVER_BASE}/deleteLedgerMaster/${ledgers[index].id}`)
          .then((response) => {
            // Display the success message from the response
            Swal.fire('Deleted!', response.data.message, 'success');
  
            // Remove the deleted ledger from the state
            const updatedLedgers = ledgers.filter((_, i) => i !== index);
            setLedgers(updatedLedgers);
          })
          .catch((error) => {
            console.error('Error deleting the ledger:', error);
            Swal.fire('Error!', 'There was an issue deleting the ledger.', 'error');
          });
      }
    });
  };  
  

  const columns = useMemo(
    () => [
      {
        Header: 'Ledger Group',
        accessor: 'ledgerGroup',
      },      
      { Header: 'Ledger Name', accessor: 'ledgerName' },
      { Header: 'Ledger Code', accessor: 'ledgerCode' },
      { Header: 'GSTIN', accessor: 'gstin' },
      { Header: 'Address', accessor: 'address' },
      {
        Header: 'Contact Person 1',
        accessor: (row) => `${row.person1} (${row.mobile1})`,
        id: 'contact1',
      },
      {
        Header: 'Action',
        Cell: ({ row }) => (
          <>
            <button
              className="btn btn-sm btn-success me-2"
              onClick={() => handleEdit(row.index)}
            >
              <FontAwesomeIcon icon={faEdit} />
            </button>
            <button
              className="btn btn-sm btn-danger"
              onClick={() => handleDelete(row.index)}
            >
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </>
        ),
      },
    ],
    [ledgers]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setGlobalFilter,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    state: { pageIndex, globalFilter },
  } = useTable(
    { columns, data: ledgers, initialState: { pageIndex: 0 } },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <div className="container mt-5">
      <h3>Ledger Master</h3>
      <button onClick={toggleFormVisibility} className="btn btn-success mb-3">
        {formVisible ? 'Cancel' : 'Add Ledger'}
      </button>

      {formVisible && (
        <form onSubmit={handleSubmit} className="formcontainer mb-3">
        <div className="row mb-3">
          <div className="col-md-3">
            <label htmlFor="selectedGroup">Ledger Group</label>
            <Select
              options={ledgerGroups}
              value={formData.selectedGroup}
              onChange={(value) => handleSelectChange("selectedGroup", value)}
            />
          </div>
          <div className="col-md-3">
            <label htmlFor="ledgerGroupCode">Ledger Group Code</label>
            <input
              type="text"
              id="ledgerGroupCode"
              name="ledgerGroupCode"
              className="form-control"
              value={formData.ledgerGroupCode}
              onChange={handleInputChange}
              required
              readOnly
            />
          </div>
          <div className="col-md-3">
            <label htmlFor="ledgerName">Ledger Name</label>
            <input
              type="text"
              id="ledgerName"
              name="ledgerName"
              className="form-control"
              value={formData.ledgerName}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="col-md-3">
            <label htmlFor="ledgerCode">Ledger Code</label>
            <input
              type="text"
              id="ledgerCode"
              name="ledgerCode"
              className="form-control"
              value={formData.ledgerCode}
              onChange={handleInputChange}
              required
            />
          </div>
        </div>
      
        <div className="row mb-3">
          <div className="col-md-3">
            <label htmlFor="gstin">GSTIN</label>
            <input
              type="text"
              id="gstin"
              name="gstin"
              className="form-control"
              value={formData.gstin}
              onChange={handleInputChange}
            />
          </div>
          <div className="col-md-3">
            <label htmlFor="address">Address</label>
            <input
              type="text"
              id="address"
              name="address"
              className="form-control"
              value={formData.address}
              onChange={handleInputChange}
            />
          </div>
          <div className="col-md-3">
            <label htmlFor="pincode">Pincode</label>
            <input
              type="text"
              id="pincode"
              name="pincode"
              className="form-control"
              value={formData.pincode}
              onChange={handleInputChange}
            />
          </div>
          <div className="col-md-3">
            <label htmlFor="state">State</label>
            <input
              type="text"
              id="state"
              name="state"
              className="form-control"
              value={formData.state}
              onChange={handleInputChange}
            />
          </div>
        </div>
      
        <div className="row mb-3">
          <div className="col-md-4">
            <label htmlFor="person1">Contact Person 1</label>
            <input
              type="text"
              id="person1"
              name="person1"
              className="form-control"
              value={formData.person1}
              onChange={handleInputChange}
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="position1">Position (Person 1)</label>
            <input
              type="text"
              id="position1"
              name="position1"
              className="form-control"
              value={formData.position1}
              onChange={handleInputChange}
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="mobile1">Mobile (Person 1)</label>
            <input
              type="text"
              id="mobile1"
              name="mobile1"
              className="form-control"
              value={formData.mobile1}
              onChange={handleInputChange}
            />
          </div>
        </div>
      
        <div className="row mb-3">
          <div className="col-md-4">
            <label htmlFor="person2">Contact Person 2</label>
            <input
              type="text"
              id="person2"
              name="person2"
              className="form-control"
              value={formData.person2}
              onChange={handleInputChange}
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="position2">Position (Person 2)</label>
            <input
              type="text"
              id="position2"
              name="position2"
              className="form-control"
              value={formData.position2}
              onChange={handleInputChange}
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="mobile2">Mobile (Person 2)</label>
            <input
              type="text"
              id="mobile2"
              name="mobile2"
              className="form-control"
              value={formData.mobile2}
              onChange={handleInputChange}
            />
          </div>
        </div>
      
        <button type="submit" className="btn btn-success">
          {editIndex !== null ? 'Update' : 'Add'}
        </button>
      </form>      
      )}
      <div className='formcontainer'>

      <table {...getTableProps()} className="table table-striped">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="pagination d-flex justify-content-between align-items-center">
        <button
          className="btn btn-secondary"
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
          >
          Previous
        </button>
        <span>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>
        </span>
        <button
          className="btn btn-secondary"
          onClick={() => nextPage()}
          disabled={!canNextPage}
          >
          Next
        </button>
      </div>
      </div>
    </div>
  );
};

export default LedgerMasterPage;
