import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { SERVER_BASE } from '../config/server';
import { useTable, usePagination, useGlobalFilter, useSortBy } from "react-table";
import "bootstrap/dist/css/bootstrap.min.css";

const UOMList = () => {
  const [uoms, setUoms] = useState([]); // UOM data
  const [unit, setUnit] = useState(""); // Unit input
  const [abbreviation, setAbbreviation] = useState(""); // Abbreviation input
  const [editId, setEditId] = useState(null); // ID of the UOM being 
  const code = sessionStorage.getItem('code');


  // Fetch all UOMs from the server
  useEffect(() => {
    fetchUOMs();
  }, []);

  const fetchUOMs = () => {
    axios
      .get(SERVER_BASE+"/getUoms",{params:{code}}) 
      .then((response) => setUoms(response.data))
      .catch((error) => console.error(error));
  };

  // Add or Update a UOM
  const handleSaveUOM = () => {
    if (!unit || !abbreviation) {
      Swal.fire("Error", "Both fields are required.", "error");
      return;
    }
  
    Swal.fire({
      title: editId ? "Confirm Update" : "Confirm Add",
      text: editId
        ? "Are you sure you want to update this UOM?"
        : "Are you sure you want to add this UOM?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        if (editId) {
          // Update UOM
          axios
            .put(`${SERVER_BASE}/updateUoms/${editId}`, { unit, abbreviation })
            .then(() => {
              Swal.fire("Success", "UOM updated successfully.", "success");
              fetchUOMs();
              resetForm();
            })
            .catch((err) => {
              Swal.fire("Error", err.response?.data?.message || "An error occurred.", "error");
            });
        } else {
          // Add UOM
          axios
            .post(SERVER_BASE+"/addUoms", { unit, abbreviation, code })
            .then((response) => {
              Swal.fire("Success", "UOM added successfully.", "success");
              fetchUOMs();
              resetForm();
            })
            .catch((err) => {
              Swal.fire("Error", err.response?.data?.message || "An error occurred.", "error");
            });
        }
      }
    });
  };
  

  // Delete UOM
  const handleDeleteUOM = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${SERVER_BASE}/deleteUoms/${id}`)
          .then(() => {
            Swal.fire("Deleted!", "Your UOM has been deleted.", "success");
            fetchUOMs();
          })
          .catch((error) => {
            Swal.fire("Error", "An error occurred while deleting the UOM.", "error");
          });
      }
    });
  };

  // Edit UOM
  const handleEditUOM = (uom) => {
    setEditId(uom.id);
    setUnit(uom.unit);
    setAbbreviation(uom.abbreviation);
  };

  // Reset form
  const resetForm = () => {
    setEditId(null);
    setUnit("");
    setAbbreviation("");
  };

  // React-table setup
  const columns = useMemo(
    () => [
      { Header: "Unit", accessor: "unit" },
      { Header: "Abbreviation", accessor: "abbreviation" },
      {
        Header: "Actions",
        Cell: ({ row }) => (
          <div>
            <button
              className="btn btn-warning btn-sm me-2"
              onClick={() => handleEditUOM(row.original)}
            >
              Edit
            </button>
            <button
              className="btn btn-danger btn-sm"
              onClick={() => handleDeleteUOM(row.original.id)}
            >
              Delete
            </button>
          </div>
        ),
      },
    ],
    []
  );

  const tableInstance = useTable(
    {
      columns,
      data: uoms,
    },
    useGlobalFilter, // Enables search
    useSortBy,       // Enables sorting
    usePagination    // Enables pagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Current page rows
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setGlobalFilter,
    state: { pageIndex, globalFilter },
  } = tableInstance;

  return (
    <div className="container mt-5">
      <h3>UOM List</h3>

      {/* Add/Edit UOM Form */}
        <form
            onSubmit={(e) => {
            e.preventDefault(); // Prevent default form submission
            handleSaveUOM();
            }}
            className="formcontainer mb-4"
        >
            <div className="row">
            {/* Unit Field */}
            <div className="col-md-3">
                <label htmlFor="unit" className="form-label">
                Unit
                </label>
                <input
                type="text"
                id="unit"
                className="form-control"
                placeholder="Enter Unit"
                value={unit}
                onChange={(e) => setUnit(e.target.value)}
                />
            </div>

            {/* Abbreviation Field */}
            <div className="col-md-3">
                <label htmlFor="abbreviation" className="form-label">
                Abbreviation
                </label>
                <input
                type="text"
                id="abbreviation"
                className="form-control"
                placeholder="Enter Abbreviation"
                value={abbreviation}
                onChange={(e) => setAbbreviation(e.target.value)}
                />
            </div>

            {/* Action Buttons */}
            <div className="col-md-4 d-flex align-items-end">
                <button type="submit" className="btn btn-success me-2">
                {editId ? "Update" : "Add"}
                </button>
                {editId && (
                <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={resetForm}
                >
                    Cancel
                </button>
                )}
            </div>
            </div>
        </form>

      {/* React Table */}
      <div className="formcontainer">
        <input
          className="form-control mb-3"
          placeholder="Search"
          value={globalFilter || ""}
          onChange={(e) => setGlobalFilter(e.target.value)}
        />
        <div className="table-responsive">
        <table className="table table-striped" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    {column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
        </div>

        {/* Pagination */}
        <div className="d-flex justify-content-between">
          <button
            className="btn btn-secondary"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            Previous
          </button>
          <span>
            Page {pageIndex + 1} of {pageOptions.length}
          </span>
          <button
            className="btn btn-secondary"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default UOMList;
