import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { SERVER_BASE } from '../config/server';
import '../styles/addCenter.css';

const AddCenter = () => {
    const [activeTab, setActiveTab] = useState('basicInfo');
    const [prefixRows, setPrefixRows] = useState([{ prefix: '', startingNumber: '' }]);
    const [formData, setFormData] = useState({
        centerCode:'',
        centerName: '',
        panNumber: '',
        email: '',
        password: '',
        gstin: '',
        state: '',
        district: '',
        pincode: '',
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        contactPerson1: '',
        position1: '',
        mobile1: '',
        contactPerson2: '',
        position2: '',
        mobile2: ''
    });
    const [inchargeOptions, setInchargeOptions] = useState([]);

    // Fetch incharges list (assuming the endpoint to get incharges)
    useEffect(() => {
        axios.get(`${SERVER_BASE}/centerIncharge`)
            .then(response => {
                const options = response.data.map(incharge => ({
                    value: incharge.id,
                    label: incharge.name,
                    mobile: incharge.mobile
                }));
                setInchargeOptions(options);
            })
            .catch(error => console.error('Error fetching incharges:', error));
    }, []);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    // Handle Contact Person 1 selection and autofill mobile
    const handleContactPerson1Change = (selectedOption) => {
        setFormData({
            ...formData,
            contactPerson1: selectedOption.label,
            mobile1: selectedOption.mobile
        });
    };

    const handleAddRow = () => {
        setPrefixRows([...prefixRows, { prefix: '', startingNumber: '' }]);
    };

    const handleRowChange = (index, event) => {
        const { name, value } = event.target;
        const updatedRows = [...prefixRows];
        updatedRows[index][name] = value;
        setPrefixRows(updatedRows);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const completeFormData = { formData, prefixRows };
    
        try {
            const response = await axios.post(SERVER_BASE+'/addCenter', completeFormData);
            // console.log(response.data.message);
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };
    

    const goToNextTab = () => {
        if (activeTab === 'basicInfo') {
            setActiveTab('prefix');
        }
    };

    const goToPreviousTab = () => {
        if (activeTab === 'prefix') {
            setActiveTab('basicInfo');
        }
    };


    return (
        <div className="container formcontainer mt-5">
            <h3>Center Information</h3>
            <ul className="nav nav-tabs">
                <li className="nav-item">
                    <button className={`nav-link ${activeTab === 'basicInfo' ? 'active' : ''}`} onClick={() => setActiveTab('basicInfo')}>Basic Info</button>
                </li>
                <li className="nav-item">
                    <button className={`nav-link ${activeTab === 'prefix' ? 'active' : ''}`} onClick={() => setActiveTab('prefix')}>Prefix</button>
                </li>
            </ul>

            <form onSubmit={handleSubmit}>
                {activeTab === 'basicInfo' && (
                    <div className="row p-4">
                        {/* Basic Info Fields */}
                        <div className="col-md-4 mb-3">
                            <label htmlFor="centerCode" className="">Center Code *</label>
                            <input type="text" className="form-control" name="centerCode" placeholder="Enter center code" value={formData.centerCode} onChange={handleChange} required/>
                        </div>
                        <div className="col-md-4 mb-3">
                            <label htmlFor="centerName" className="">Center Name *</label>
                            <input type="text" className="form-control" name="centerName" placeholder="Enter center name" value={formData.centerName} onChange={handleChange} required/>
                        </div>
                        <div className="col-md-4 mb-3">
                            <label htmlFor="panNumber" className="">PAN Number *</label>
                            <input type="text" className="form-control" name="panNumber" placeholder="Enter PAN number" value={formData.panNumber} onChange={handleChange} />
                        </div>
                        <div className="col-md-4 mb-3">
                            <label htmlFor="email">Email *</label>
                            <input type="email" className="form-control" name="email" placeholder="Enter email" value={formData.email} onChange={handleChange} required />
                        </div>
                        <div className="col-md-4 mb-3">
                            <label htmlFor="password">Password *</label>
                            <input type="password" className="form-control" name="password" placeholder="Enter password" value={formData.password} onChange={handleChange} required />
                        </div>
                        <div className="col-md-4 mb-3">
                            <label htmlFor="gstin" className="">GSTIN *</label>
                            <input type="text" className="form-control" name="gstin" placeholder="Enter GSTIN" value={formData.gstin} onChange={handleChange} />
                        </div>
                        <div className="col-md-4 mb-3">
                            <label htmlFor="state" className="">State *</label>
                            <input type="text" className="form-control" name="state" placeholder="Enter state" value={formData.state} onChange={handleChange} />
                        </div>
                        <div className="col-md-4 mb-3">
                            <label htmlFor="district" className="">District *</label>
                            <input type="text" className="form-control" name="district" placeholder="Enter district" value={formData.district} onChange={handleChange} />
                        </div>
                        <div className="col-md-4 mb-3">
                            <label htmlFor="pincode" className="">Pincode *</label>
                            <input type="text" className="form-control" name="pincode" placeholder="Enter pincode" value={formData.pincode} onChange={handleChange} />
                        </div>
                        <div className="col-md-4 mb-3">
                            <label htmlFor="addressLine1" className="">Address Line 1 *</label>
                            <input type="text" className="form-control" name="addressLine1" placeholder="Enter address line 1" value={formData.addressLine1} onChange={handleChange} />
                        </div>
                        <div className="col-md-4 mb-3">
                            <label htmlFor="addressLine2" className="">Address Line 2 *</label>
                            <input type="text" className="form-control" name="addressLine2" placeholder="Enter address line 2" value={formData.addressLine2} onChange={handleChange} />
                        </div>
                        <div className="col-md-4 mb-3">
                            <label htmlFor="addressLine3" className="">Address Line 3 *</label>
                            <input type="text" className="form-control" name="addressLine3" placeholder="Enter address line 3" value={formData.addressLine3} onChange={handleChange} />
                        </div>
                        <div className="col-md-4 mb-3">
                            <label htmlFor="contactPerson1" className="">Contact Person 1</label>
                            <Select
                                options={inchargeOptions}
                                onChange={handleContactPerson1Change}
                                placeholder="Select Contact Person 1"
                            />
                        </div>
                        <div className="col-md-4 mb-3">
                            <label htmlFor="mobile1" className="">Mobile No. of Contact Person 1</label>
                            <input type="text" className="form-control" name="mobile1" value={formData.mobile1} readOnly />
                        </div>
                        <div className="col-md-4 mb-3">
                            <label htmlFor="position1" className="">Position of Contact Person 1</label>
                            <input type="text" className="form-control" name="position1" placeholder="Enter position" value={formData.position1} onChange={handleChange} />
                        </div>
                        <div className="col-md-4 mb-3">
                            <label htmlFor="contactPerson2" className="">Contact Person 2</label>
                            <input type="text" className="form-control" name="contactPerson2" placeholder="Enter contact person 2" value={formData.contactPerson2} onChange={handleChange} />
                        </div>
                        <div className="col-md-4 mb-3">
                            <label htmlFor="mobile2" className="">Mobile No. of Contact Person 2</label>
                            <input type="text" className="form-control" name="mobile2" placeholder="Enter mobile number" value={formData.mobile2} onChange={handleChange} />
                        </div>
                        <div className="col-md-4 mb-3">
                            <label htmlFor="position2" className="">Position of Contact Person 2</label>
                            <input type="text" className="form-control" name="position2" placeholder="Enter position" value={formData.position2} onChange={handleChange} />
                        </div>
                        <div className="d-flex justify-content-between mt-3">
                            <button type="button" className="btn btn-success" onClick={goToNextTab}>Next</button>
                            <button type="submit" className="btn btn-success">Submit</button>
                        </div>
                    </div>
                )}

                {activeTab === 'prefix' && (
                    <div className="row p-4">
                        {prefixRows.map((row, index) => (
                            <div className="row mb-3" key={index}>
                                <div className="col-md-5">
                                    <label htmlFor={`prefix${index}`} className="">Prefix {index + 1}</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="prefix"
                                        value={row.prefix}
                                        onChange={(e) => handleRowChange(index, e)}
                                    />
                                </div>
                                <div className="col-md-5">
                                    <label htmlFor={`startingNumber${index}`} className="">Starting Number</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="startingNumber"
                                        value={row.startingNumber}
                                        onChange={(e) => handleRowChange(index, e)}
                                    />
                                </div>
                                <div className="col-md-2 d-flex align-items-end">
                                    <button type="button" className="btn btn-success " onClick={handleAddRow}>
                                        <FontAwesomeIcon icon={faPlusCircle} />
                                    </button>
                                    {prefixRows.length > 1 && (
                                        <button type="button" className="btn btn-danger ms-2" onClick={() => setPrefixRows(prefixRows.filter((_, i) => i !== index))}>
                                            <FontAwesomeIcon icon={faMinusCircle} />
                                        </button>
                                    )}
                                </div>
                            </div>
                        ))}
                       <div className="d-flex justify-content-between mt-3">
                            <button type="button" className="btn btn-success" onClick={goToPreviousTab}>Previous</button>
                            <button type="submit" className="btn btn-success">Submit</button>
                        </div>
                    </div>
                )}
            </form>
        </div>
    );
};

export default AddCenter;
