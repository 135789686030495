import React, { useState, useEffect } from 'react';
import { Routes, Route, Link, useNavigate } from 'react-router-dom';
import '../styles/dashboard.css';
import axios from 'axios';
import { SERVER_BASE } from '../config/server';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarsProgress, faClockRotateLeft,faChartPie, faSignOutAlt, faUserPlus, faList, faHouse, faUser,faCircleDot, faBriefcase, faBusinessTime, faListCheck, faCalendarDays, faBuilding, faBars, faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import DepartmentPage from './department';
import LedgerGroupPage from './ledgerGroup'
import LedgerMasterPage from './ledgerMaster';
import PurposePage from './purpose'
import ItemGroupPage from './itemGroup';
import SewadarForm from './Sewadar';
import ItemMaster from './itemMaster';
import SecurityIn from './SecurityIn';
import AddCenter from './addCenter';
import CenterIncharge from './CenterIncharge';
import DeliveryChallan from './deliveryChallan';
import CenterList from './centerList';
// import SetPasswordFirstTime from './setPasswordFirstTime';
import UOMList from './uomList';
import LocationList from './locationList';
import AddInventory from './addInventory';
import LessInventory from './lessInventory';
import PurposeInventory from './purposeInventory';
import ReceiptVoucher from './receiptVoucher';
import GatePass from './gatePass';
import SecurityOutForm from './SecurityOut';
import ITManager from './itManager';
import DepartmentItem from './departmentalItemsReport';
const Swal = require('sweetalert2');

const Navbar = () => {
  const navigate = useNavigate();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [activeLink, setActiveLink] = useState('Dashboard');
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [refNo, setRefNo] = useState('');
  const [prefix, setPrefix] = useState('');
  const [toggleStates, setToggleStates] = useState({
    center:false,
    groups:false,
    masters: false,
    masterList:false,
    security:false,
    dept:false,
    inventory:false,
    itManager:false,
    letters:false,
    reports:false,
  });
  const name = sessionStorage.getItem('name')
  const centerCode = sessionStorage.getItem('code');
  console.log(name);
  
  console.log(refNo,prefix,"dfb");
  
  const toggleNavbar = () => {
    setIsCollapsed(!isCollapsed);
  };

  const toggleNavHeading = (heading) => {
    setToggleStates((prev) => ({ ...prev, [heading]: !prev[heading] }));
  };
  const handleLinkClick = (link) => {
    if (unsavedChanges) {
      // Prevent the default navigation and show the confirmation alert
      Swal.fire({
        title: "You have unsaved changes!",
        text: "Are you sure you want to leave this page?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Leave",
        cancelButtonText: "Stay",
      }).then((result) => {
        if (result.isConfirmed) {
          // If confirmed, navigate to the stored link
          setUnsavedChanges(false);
          handleDelete()
          navigate(link); // Perform the navigation
        }
      });
    } else {
      // If no unsaved changes, navigate immediately
      navigate(link);
    }
  };
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (unsavedChanges) {
        event.preventDefault();
        event.returnValue = ''; // Required for modern browsers
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
  
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [unsavedChanges]);
    
  

  const handleLogout = () => {
    Swal.fire({
      title: 'Do you want to log out?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      icon: 'warning',
    }).then((result) => {
      if (result.isConfirmed) {
        sessionStorage.removeItem('email');
        navigate('/');
      }
    });
  };
  
  const handleDelete = async () => {
    try {
      await axios.delete(`${SERVER_BASE}/deleteModalData`, {
        params: { refNo, prefix,centerCode } });
      // Swal.fire(' deleted.', 'success');
    } catch (error) {
      console.error('Error deleting data:', error);
    }
  };

  return (
    <>
      <div className="top-navbar">
        <div className="top-navbar-content">
          <div className="toggle-btn" onClick={toggleNavbar}>
            <FontAwesomeIcon icon={faBars} />
          </div>
          <span className="admin-welcome">
          {name}
          </span>
          <div>
            <Link to="teammemberprofile" className="logout-btn me-2" onClick={() => handleLinkClick('')}>
              <FontAwesomeIcon icon={faUser} />
            </Link>
            <button onClick={handleLogout} className="logout-btn">
              <FontAwesomeIcon icon={faSignOutAlt} />
            </button>
          </div>
        </div>
      </div>

      <div className="admin-dashboard">
        <div className={`sidebar ${isCollapsed ? 'collapsed' : ''}`}>
          <div className="logo-section">
            <div className="toggle-btn" onClick={toggleNavbar}>
              {isCollapsed ? '>' : '<'}
            </div>
          </div>
          <ul className="nav flex-column">
           <li 
          className={`nav-item ${activeLink === 'Dashboard' ? 'active' : ''}`} 
          onClick={(e) => {
            e.preventDefault(); // Prevent default Link behavior
            handleLinkClick('Dashboard'); // Pass the correct link to handleLinkClick
            setActiveLink('Dashboard');
          }}
        >
          <FontAwesomeIcon icon={faHouse} />
          {/* Only show the text if the navbar is not collapsed */}
          {!isCollapsed && <span>Dashboard</span>}
        </li>
            <li className="nav-heading" onClick={() => toggleNavHeading('center')}>
              {toggleStates.center ? ( // Change the icon based on toggle state
                <>
                  <FontAwesomeIcon icon={faCaretDown} />
                  {!isCollapsed && ' Center Info'}
                </>
              ) : (
                <>
                  <FontAwesomeIcon icon={faCaretRight} />
                  {!isCollapsed && ' Center Info'}
                </>
              )}
            </li>
            {toggleStates.center && (
              <>
                  <li
                    className={`nav-item ${activeLink === 'Add Center' ? 'active' : ''}`}
                    onClick={(e) =>{e.preventDefault();
                       handleLinkClick('addCenter');
                       setActiveLink('Add Center');
                      }}
                  >
                          <FontAwesomeIcon icon={faBarsProgress} />
                          {!isCollapsed && <span>Add Center</span>}
                        </li>

                  <li
                    className={`nav-item ${activeLink === 'Center List' ? 'active' : ''}`}
                    onClick={(e) =>{e.preventDefault();
                      handleLinkClick('centerList');
                      setActiveLink('Center List');
                     }}

                  >
                          <FontAwesomeIcon icon={faBarsProgress} />
                          {!isCollapsed && <span>Center List</span>}
                        </li> 
                  <li
                    className={`nav-item ${activeLink === 'Add Incharge' ? 'active' : ''}`}
                    onClick={(e) =>{e.preventDefault();
                      handleLinkClick('addCenterIncharge');
                      setActiveLink('Add Incharge');
                     }}
                  >
                          <FontAwesomeIcon icon={faUserPlus} />
                          {!isCollapsed && <span>Add Incharge</span>}
                        </li>
                
              </>
            )}
            <li className="nav-heading" onClick={() => toggleNavHeading('letters')}>
              {toggleStates.letters ? ( // Change the icon based on toggle state
                <>
                  <FontAwesomeIcon icon={faCaretDown} />
                  {!isCollapsed && ' Letters'}
                </>
              ) : (
                <>
                  <FontAwesomeIcon icon={faCaretRight} />
                  {!isCollapsed && ' Letters'}
                </>
              )}
            </li>
            {toggleStates.letters && (
              <>
                  <li
                    className={`nav-item ${activeLink === 'Authorization' ? 'active' : ''}`}
                    onClick={(e) =>{e.preventDefault();
                       handleLinkClick('authorization');
                       setActiveLink('Authorization');
                      }}
                  >
                          <FontAwesomeIcon icon={faBarsProgress} />
                          {!isCollapsed && <span>Authorization</span>}
                        </li>

                  <li
                    className={`nav-item ${activeLink === 'Acknowledgement' ? 'active' : ''}`}
                    onClick={(e) =>{e.preventDefault();
                      handleLinkClick('acknowledgement');
                      setActiveLink('Acknowledgement');
                     }}

                  >
                          <FontAwesomeIcon icon={faBarsProgress} />
                          {!isCollapsed && <span>Acknowledgement</span>}
                        </li> 
                
                
              </>
            )}
            <li className="nav-heading" onClick={() => toggleNavHeading('itManager')}>
              {toggleStates.itManager ? ( // Change the icon based on toggle state
                <>
                  <FontAwesomeIcon icon={faCaretDown} />
                  {!isCollapsed && ' IT Manager'}
                </>
              ) : (
                <>
                  <FontAwesomeIcon icon={faCaretRight} />
                  {!isCollapsed && ' IT Manager'}
                </>
              )}
            </li>
            {toggleStates.itManager && (
              <>
                  <li
                    className={`nav-item ${activeLink === 'Manage IT Manager' ? 'active' : ''}`}
                    onClick={(e) => {e.preventDefault();
                      handleLinkClick('itManager')
                      setActiveLink('Manage IT Manager');
                    }}
                  >
                          <FontAwesomeIcon icon={faBarsProgress} />
                          {!isCollapsed && <span>Manage IT Manager</span>}
                        </li>
              </>
            )}
            <li className="nav-heading" onClick={() => toggleNavHeading('groups')}>
              {toggleStates.groups ? ( // Change the icon based on toggle state
                <>
                  <FontAwesomeIcon icon={faCaretDown} />
                  {!isCollapsed && ' Groups'}
                </>
              ) : (
                <>
                  <FontAwesomeIcon icon={faCaretRight} />
                  {!isCollapsed && ' Groups'}
                </>
              )}
            </li>
            {toggleStates.groups && (
              <>
                  <li
                    className={`nav-item ${activeLink === 'Department' ? 'active' : ''}`}
                    onClick={(e) =>{e.preventDefault();
                      handleLinkClick('department');
                      setActiveLink('Department');
                     }}

                  >
                          <FontAwesomeIcon icon={faBarsProgress} />
                          {!isCollapsed && <span>Department</span>}
                        </li>
                
                
                  <li
                    className={`nav-item ${activeLink === 'Ledger Group' ? 'active' : ''}`}
                    onClick={(e) =>{e.preventDefault();
                      handleLinkClick('ledgerGroup');
                      setActiveLink('Ledger Group');
                     }}
                  >
                          <FontAwesomeIcon icon={faBarsProgress} />
                          {!isCollapsed && <span>Ledger Group</span>}
                        </li> 
                  <li
                    className={`nav-item ${activeLink === 'Purpose - Ledger' ? 'active' : ''}`}
                    onClick={(e) =>{e.preventDefault();
                      handleLinkClick('purposeLedger');
                      setActiveLink('Purpose - Ledger');
                     }}
                  >
                          <FontAwesomeIcon icon={faBarsProgress} />
                          {!isCollapsed && <span>Purpose - Ledger</span>}
                        </li>
                  <li
                    className={`nav-item ${activeLink === 'Purpose - Inventory' ? 'active' : ''}`}
                    onClick={(e) =>{e.preventDefault();
                      handleLinkClick('purposeInventory');
                      setActiveLink('Purpose - Inventory');
                     }}
                  >
                          <FontAwesomeIcon icon={faBarsProgress} />
                          {!isCollapsed && <span>Purpose - Inventory</span>}
                        </li>
                  <li
                    className={`nav-item ${activeLink === 'Item Group' ? 'active' : ''}`}
                    onClick={(e) =>{e.preventDefault();
                      handleLinkClick('itemGroup');
                      setActiveLink('Item Group');
                     }}
                  >
                          <FontAwesomeIcon icon={faBarsProgress} />
                          {!isCollapsed && <span>Item Group</span>}
                        </li>
                  <li
                    className={`nav-item ${activeLink === 'Sewadar' ? 'active' : ''}`}
                    onClick={(e) =>{e.preventDefault();
                      handleLinkClick('sewadar');
                      setActiveLink('Sewadar');
                     }}
                  >
                          <FontAwesomeIcon icon={faBarsProgress} />
                          {!isCollapsed && <span>Sewadar</span>}
                        </li>
              </>
            )}

            <li className="nav-heading" onClick={() => toggleNavHeading('masters')}>
              {toggleStates.masters ? ( // Change the icon based on toggle state
                <>
                  <FontAwesomeIcon icon={faCaretDown} />
                  {!isCollapsed && ' Masters'}
                </>
              ) : (
                <>
                  <FontAwesomeIcon icon={faCaretRight} />
                  {!isCollapsed && ' Masters'}
                </>
              )}
            </li>

            {toggleStates.masters && (
              <>
                  <li
                    className={`nav-item ${activeLink === 'Ledger Master' ? 'active' : ''}`}
                    onClick={(e) =>{e.preventDefault();
                      handleLinkClick('ledgerMaster');
                      setActiveLink('Ledger Master');
                     }}
                  >
                          <FontAwesomeIcon icon={faBarsProgress} />
                          {!isCollapsed && <span>Ledger Master</span>}
                        </li>
                  <li
                    className={`nav-item ${activeLink === 'Item Master' ? 'active' : ''}`}
                    onClick={(e) =>{e.preventDefault();
                      handleLinkClick('itemMaster');
                      setActiveLink('Item Master');
                     }}
                  >
                          <FontAwesomeIcon icon={faBarsProgress} />
                          {!isCollapsed && <span>Item Master</span>}
                        </li>
              </>
            )}
            <li className="nav-heading" onClick={() => toggleNavHeading('masterList')}>
              {toggleStates.masterList ? ( // Change the icon based on toggle state
                <>
                  <FontAwesomeIcon icon={faCaretDown} />
                  {!isCollapsed && ' Master List'}
                </>
              ) : (
                <>
                  <FontAwesomeIcon icon={faCaretRight} />
                  {!isCollapsed && ' Master List'}
                </>
              )}
            </li>
            {toggleStates.masterList && (
              <>
                  <li
                    className={`nav-item ${activeLink === 'UOM List' ? 'active' : ''}`}
                    onClick={(e) =>{e.preventDefault();
                      handleLinkClick('uomList');
                      setActiveLink('UOM List');
                     }}
                  >
                          <FontAwesomeIcon icon={faBarsProgress} />
                          {!isCollapsed && <span>UOM List</span>}
                        </li>
                  <li
                    className={`nav-item ${activeLink === 'Location List' ? 'active' : ''}`}
                    onClick={(e) =>{e.preventDefault();
                      handleLinkClick('locationList');
                      setActiveLink('Location List');
                     }}
                  >
                          <FontAwesomeIcon icon={faBarsProgress} />
                          {!isCollapsed && <span>Location List</span>}
                        </li>
              </>
            )}
            <li className="nav-heading" onClick={() => toggleNavHeading('reports')}>
              {toggleStates.reports ? ( // Change the icon based on toggle state
                <>
                  <FontAwesomeIcon icon={faCaretDown} />
                  {!isCollapsed && ' Reports'}
                </>
              ) : (
                <>
                  <FontAwesomeIcon icon={faCaretRight} />
                  {!isCollapsed && ' Reports'}
                </>
              )}
            </li>
            {toggleStates.reports && (
              <>
                  <li
                    className={`nav-item ${activeLink === 'Department Item' ? 'active' : ''}`}
                    onClick={(e) =>{e.preventDefault();
                      handleLinkClick('departmentItem');
                      setActiveLink('Department Item');
                     }}
                  >
                          <FontAwesomeIcon icon={faBarsProgress} />
                          {!isCollapsed && <span>Department Item</span>}
                        </li>
              </>
            )}
            <li className="nav-heading" onClick={() => toggleNavHeading('inventory')}>
              {toggleStates.inventory ? ( // Change the icon based on toggle state
                <>
                  <FontAwesomeIcon icon={faCaretDown} />
                  {!isCollapsed && ' Manage Inventory'}
                </>
              ) : (
                <>
                  <FontAwesomeIcon icon={faCaretRight} />
                  {!isCollapsed && ' Manage Inventory'}
                </>
              )}
            </li>
            {toggleStates.inventory && (
              <>
                  <li
                    className={`nav-item ${activeLink === 'Add Inventory' ? 'active' : ''}`}
                    onClick={(e) =>{e.preventDefault();
                      handleLinkClick('addInventory');
                      setActiveLink('Add Inventory');
                     }}
                  >
                          <FontAwesomeIcon icon={faBarsProgress} />
                          {!isCollapsed && <span>Add Inventory</span>}
                        </li>
                  <li
                    className={`nav-item ${activeLink === 'Less Inventory' ? 'active' : ''}`}
                    onClick={(e) =>{e.preventDefault();
                      handleLinkClick('lessInventory');
                      setActiveLink('Less Inventory');
                     }}
                  >
                          <FontAwesomeIcon icon={faBarsProgress} />
                          {!isCollapsed && <span>Less Inventory</span>}
                        </li>
              </>
            )}
            <li className="nav-heading" onClick={() => toggleNavHeading('security')}>
              {toggleStates.security ? ( // Change the icon based on toggle state
                <>
                  <FontAwesomeIcon icon={faCaretDown} />
                  {!isCollapsed && ' Security Functions'}
                </>
              ) : (
                <>
                  <FontAwesomeIcon icon={faCaretRight} />
                  {!isCollapsed && ' Security Functions'}
                </>
              )}
            </li>
            {toggleStates.security && (
              <>

                  <li
                    className={`nav-item ${activeLink === 'Security In' ? 'active' : ''}`}
                    onClick={(e) =>{e.preventDefault();
                      handleLinkClick('securityIn');
                      setActiveLink('Security In');
                     }}
                  >
                          <FontAwesomeIcon icon={faBarsProgress} />
                          {!isCollapsed && <span>Security In</span>}
                        </li>
                        <li
                    className={`nav-item ${activeLink === 'Security Out' ? 'active' : ''}`}
                    onClick={(e) =>{e.preventDefault();
                      handleLinkClick('securityOut');
                      setActiveLink('Security Out');
                     }}
                  >
                          <FontAwesomeIcon icon={faBarsProgress} />
                          {!isCollapsed && <span>Security Out</span>}
                        </li>

              </>
            )}
            <li className="nav-heading" onClick={() => toggleNavHeading('dept')}>
              {toggleStates.dept ? ( // Change the icon based on toggle state
                <>
                  <FontAwesomeIcon icon={faCaretDown} />
                  {!isCollapsed && ' Dept Functions'}
                </>
              ) : (
                <>
                  <FontAwesomeIcon icon={faCaretRight} />
                  {!isCollapsed && ' Dept Functions'}
                </>
              )}
            </li>
            {toggleStates.dept && (
              <>
                  <li
                    className={`nav-item ${activeLink === 'Delivery Challan' ? 'active' : ''}`}
                    onClick={(e) =>{e.preventDefault();
                      handleLinkClick('addDeliveryChallan');
                      setActiveLink('Delivery Challan');
                     }}
                  >
                          <FontAwesomeIcon icon={faBarsProgress} />
                          {!isCollapsed && <span>Delivery Challan</span>}
                        </li>
                  <li
                    className={`nav-item ${activeLink === 'Receipt Voucher' ? 'active' : ''}`}
                    onClick={(e) =>{e.preventDefault();
                      handleLinkClick('addReceiptVoucher');
                      setActiveLink('Receipt Voucher');
                     }}
                  >
                          <FontAwesomeIcon icon={faBarsProgress} />
                          {!isCollapsed && <span>Receipt Voucher</span>}
                        </li>
                  <li
                    className={`nav-item ${activeLink === 'Gate Pass' ? 'active' : ''}`}
                    onClick={(e) =>{e.preventDefault();
                      handleLinkClick('addGatePass');
                      setActiveLink('Gate Pass');
                     }}
                  >
                          <FontAwesomeIcon icon={faBarsProgress} />
                          {!isCollapsed && <span>Gate Pass</span>}
                        </li>

              </>
            )}
          </ul>
        </div>

        <div className='content-area'>
          <Routes>
          <Route path="/"/>
          <Route path="/department" element={<DepartmentPage  setUnsavedChanges={setUnsavedChanges} />} />
          <Route path="/ledgerGroup" element={<LedgerGroupPage  setUnsavedChanges={setUnsavedChanges} />} />
          <Route path="/ledgerMaster" element={<LedgerMasterPage  setUnsavedChanges={setUnsavedChanges} />} />
          <Route path="/purposeLedger" element={<PurposePage setUnsavedChanges={setUnsavedChanges} />} />
          <Route path="/purposeInventory" element={<PurposeInventory setUnsavedChanges={setUnsavedChanges} />} />
          <Route path="/itemGroup" element={<ItemGroupPage setUnsavedChanges={setUnsavedChanges}  />} />
          <Route path="/itemMaster" element={<ItemMaster setUnsavedChanges={setUnsavedChanges}  />} />
          <Route path="/sewadar" element={<SewadarForm setUnsavedChanges={setUnsavedChanges}  />} />
          <Route path="/securityIn" element={<SecurityIn setUnsavedChanges={setUnsavedChanges}  />} />
          <Route path="/securityOut" element={<SecurityOutForm setUnsavedChanges={setUnsavedChanges}/>} />
          <Route path='/addCenter' element={<AddCenter setUnsavedChanges={setUnsavedChanges} />}/>
          <Route path='/addCenterIncharge' element={<CenterIncharge setUnsavedChanges={setUnsavedChanges} />}/>
          <Route path='/addDeliveryChallan' element={<DeliveryChallan setUnsavedChanges={setUnsavedChanges} setRefNo={setRefNo} setPrefix={setPrefix}/>}/>
          <Route path='/centerList' element={<CenterList setUnsavedChanges={setUnsavedChanges} />}/>
          <Route path='/uomList' element={<UOMList setUnsavedChanges={setUnsavedChanges}/>}/>
          <Route path='/locationList' element={<LocationList setUnsavedChanges={setUnsavedChanges}/>}/>
          <Route path='/addInventory' element={<AddInventory setUnsavedChanges={setUnsavedChanges}/>}/>
          <Route path='/lessInventory' element={<LessInventory setUnsavedChanges={setUnsavedChanges}/>}/>
          <Route path='/addReceiptVoucher' element={<ReceiptVoucher setUnsavedChanges={setUnsavedChanges}/>}/>
          <Route path='/addGatePass' element={<GatePass setUnsavedChanges={setUnsavedChanges}/>}/>
          <Route path='/itManager' element={<ITManager setUnsavedChanges={setUnsavedChanges}/>}/>
          <Route path='/departmentItem' element={<DepartmentItem/>}/>
          {/* <Route path='/setPassword' element={<SetPasswordFirstTime/>}/> */}
          </Routes>
        </div>
      </div>
    </>
  );
};

export default Navbar;
