import React, { useState } from 'react';
import { SERVER_BASE } from '../config/server';
import 'bootstrap/dist/css/bootstrap.min.css';

const GatePass = () => {
  // State for general form fields
  const [isBilwaVehicle, setIsBilwaVehicle] = useState(false);
  const [freightOn, setFreightOn] = useState(false); // false for On Sewa, true for On Freight
  const [copyCollected, setCopyCollected] = useState(false);

  // Individual states for form fields
  const [siNumber, setSiNumber] = useState('');
  const [gpNo, setGpNo] = useState('');
  const [vehicleNumber, setVehicleNumber] = useState('');
  const [driverName, setDriverName] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [ledgerName, setLedgerName] = useState('');
  const [remarks, setRemarks] = useState('');
  const [totalFreight, setTotalFreight] = useState('');
  const [advancePaid, setAdvancePaid] = useState('');
  const [toPay, setToPay] = useState('');
  const [transporterName, setTransporterName] = useState('');
  const [transporterGSTIN, setTransporterGSTIN] = useState('');
  const [documentGRNo, setDocumentGRNo] = useState('');
  const [documentGRDate, setDocumentGRDate] = useState('');
  const [documentStatus, setDocumentStatus] = useState('');

  return (
    <div className="container mt-5">
      <h3 className="mb-4">Gate Pass</h3>
      <form className='formcontainer'>
        <div className="row mb-3">
          <div className="col-md-3">
            <label className="form-label">Bilwa Vehicle</label>
            <select
              className="form-select"
              onChange={(e) => setIsBilwaVehicle(e.target.value === 'Yes')}
            >
              <option value="No">No</option>
              <option value="Yes">Yes</option>
            </select>
          </div>

          {!isBilwaVehicle && (
            <div className="col-md-3">
              <label className="form-label">SI Number/RV Number</label>
              <input
                type="text"
                className="form-control"
                value={siNumber}
                onChange={(e) => setSiNumber(e.target.value)}
              />
            </div>
          )}

          <div className="col-md-3">
            <label className="form-label">GP No.</label>
            <input
              type="text"
              className="form-control"
              value={gpNo}
              onChange={(e) => setGpNo(e.target.value)}
            />
          </div>

          <div className="col-md-3">
            <label className="form-label">Vehicle Number</label>
            <input
              type="text"
              className="form-control"
              value={vehicleNumber}
              onChange={(e) => setVehicleNumber(e.target.value)}
            />
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-md-3">
            <label className="form-label">Driver Name</label>
            <input
              type="text"
              className="form-control"
              value={driverName}
              onChange={(e) => setDriverName(e.target.value)}
            />
          </div>

          <div className="col-md-3">
            <label className="form-label">Mobile Number</label>
            <input
              type="text"
              className="form-control"
              value={mobileNumber}
              onChange={(e) => setMobileNumber(e.target.value)}
            />
          </div>

          <div className="col-md-3">
            <label className="form-label">Ledger Name/Name</label>
            <input
              type="text"
              className="form-control"
              value={ledgerName}
              onChange={(e) => setLedgerName(e.target.value)}
            />
          </div>

          <div className="col-md-3">
            <label className="form-label">Remarks</label>
            <input
              className="form-control"
              rows="1"
              value={remarks}
              onChange={(e) => setRemarks(e.target.value)}
            />
          </div>

        </div>

        <div className="row mb-3 align-items-center">
                      <div className="col-md-3 ">
          <label className="form-label">Document Status</label>
          <select
            className="form-select"
            value={documentStatus}
            onChange={(e) => setDocumentStatus(e.target.value)}
          >
            <option value="">Select</option>
            <option value="Original">Original</option>
            <option value="Copy of Original">Copy of Original</option>
          </select>
        </div>
          <div className="col-md-3 d-flex align-items-center">
            <label htmlFor='freightOn' className="me-2">On Sewa</label>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                id='freightOn'
                checked={freightOn}
                onChange={() => setFreightOn(!freightOn)}
              />
            </div>
            <label htmlFor='freightOn' className="ms-2">On Freight</label>
          </div>

          {freightOn && (
            <div className="col-md-6">
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id='copyCollected'
                  checked={copyCollected}
                  onChange={() => setCopyCollected(!copyCollected)}
                />
                <label htmlFor='copyCollected' className="form-check-label">
                  Copy of bilty or freight receipt collected and informed to area office
                </label>
              </div>
            </div>
          )}
        </div>

        {freightOn && (
          <>
            <div className="row mb-3">
              <div className="col-md-3">
                <label className="form-label">Total Freight</label>
                <input
                  type="text"
                  className="form-control"
                  value={totalFreight}
                  onChange={(e) => setTotalFreight(e.target.value)}
                />
              </div>

              <div className="col-md-3">
                <label className="form-label">Advance Paid</label>
                <input
                  type="text"
                  className="form-control"
                  value={advancePaid}
                  onChange={(e) => setAdvancePaid(e.target.value)}
                />
              </div>

              <div className="col-md-3">
                <label className="form-label">To Pay</label>
                <input
                  type="text"
                  className="form-control"
                  value={toPay}
                  onChange={(e) => setToPay(e.target.value)}
                />
              </div>

              <div className="col-md-3">
                <label className="form-label">Transporter Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={transporterName}
                  onChange={(e) => setTransporterName(e.target.value)}
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-3">
                <label className="form-label">Transporter GSTIN</label>
                <input
                  type="text"
                  className="form-control"
                  value={transporterGSTIN}
                  onChange={(e) => setTransporterGSTIN(e.target.value)}
                />
              </div>

              <div className="col-md-3">
                <label className="form-label">Document/GR No.</label>
                <input
                  type="text"
                  className="form-control"
                  value={documentGRNo}
                  onChange={(e) => setDocumentGRNo(e.target.value)}
                />
              </div>

              <div className="col-md-3">
                <label className="form-label">Document/GR Date</label>
                <input
                  type="date"
                  className="form-control"
                  value={documentGRDate}
                  onChange={(e) => setDocumentGRDate(e.target.value)}
                />
              </div>
            </div>
          </>
        )}

        <button type="submit" className="btn btn-success">
          Submit
        </button>
      </form>
    </div>
  );
};

export default GatePass;
