import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { SERVER_BASE } from '../config/server';
import { useTable, usePagination, useGlobalFilter, useSortBy } from "react-table";
import "bootstrap/dist/css/bootstrap.min.css";

const LocationList = () => {
  const [locations, setLocations] = useState([]); // Location data
  const [location, setLocation] = useState(""); // Location input
  const [remarks, setRemarks] = useState(""); // Remarks input
  const [editId, setEditId] = useState(null); // ID of the Location being edited
  const code = sessionStorage.getItem('code');

  // Fetch all Locations from the server
  useEffect(() => {
    fetchLocations();
  }, []);

  const fetchLocations = () => {
    axios
      .get(SERVER_BASE+"/getLocations",{params:{code}}) 
      .then((response) => setLocations(response.data))
      .catch((error) => console.error(error));
  };

  // Add or Update a Location
  const handleSaveLocation = () => {
    if (!location) {
      Swal.fire("Error", "Location is required.", "error");
      return;
    }
  
    Swal.fire({
      title: editId ? "Confirm Update" : "Confirm Add",
      text: editId
        ? "Are you sure you want to update this Location?"
        : "Are you sure you want to add this Location?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        if (editId) {
          // Update Location
          axios
            .put(`${SERVER_BASE}/updateLocations/${editId}`, { location, remarks })
            .then(() => {
              Swal.fire("Success", "Location updated successfully.", "success");
              fetchLocations();
              resetForm();
            })
            .catch((err) => {
              Swal.fire("Error", err.response?.data?.message || "An error occurred.", "error");
            });
        } else {
          // Add Location
          axios
            .post(SERVER_BASE+"/addLocations", { location, remarks, code })
            .then((response) => {
              Swal.fire("Success", "Location added successfully.", "success");
              fetchLocations();
              resetForm();
            })
            .catch((err) => {
              Swal.fire("Error", err.response?.data?.message || "An error occurred.", "error");
            });
        }
      }
    });
  };
  

  // Delete Location
  const handleDeleteLocation = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${SERVER_BASE}/deleteLocations/${id}`)
          .then(() => {
            Swal.fire("Deleted!", "Your Location has been deleted.", "success");
            fetchLocations();
          })
          .catch((error) => {
            Swal.fire("Error", "An error occurred while deleting the Location.", "error");
          });
      }
    });
  };

  // Edit Location
  const handleEditLocation = (location) => {
    setEditId(location.id);
    setLocation(location.location);
    setRemarks(location.remarks);
  };

  // Reset form
  const resetForm = () => {
    setEditId(null);
    setLocation("");
    setRemarks("");
  };

  // React-table setup
  const columns = useMemo(
    () => [
      { Header: "Location", accessor: "location" },
      { Header: "Remarks", accessor: "remarks" },
      {
        Header: "Actions",
        Cell: ({ row }) => (
          <div>
            <button
              className="btn btn-warning btn-sm me-2"
              onClick={() => handleEditLocation(row.original)}
            >
              Edit
            </button>
            <button
              className="btn btn-danger btn-sm"
              onClick={() => handleDeleteLocation(row.original.id)}
            >
              Delete
            </button>
          </div>
        ),
      },
    ],
    []
  );

  const tableInstance = useTable(
    {
      columns,
      data: locations,
    },
    useGlobalFilter, // Enables search
    useSortBy,       // Enables sorting
    usePagination    // Enables pagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Current page rows
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setGlobalFilter,
    state: { pageIndex, globalFilter },
  } = tableInstance;

  return (
    <div className="container mt-5">
      <h3>Location List</h3>

      {/* Add/Edit Location Form */}
        <form
            onSubmit={(e) => {
            e.preventDefault(); // Prevent default form submission
            handleSaveLocation();
            }}
            className="formcontainer mb-4"
        >
            <div className="row">
            {/* Location Field */}
            <div className="col-md-3">
                <label htmlFor="location" className="form-label">
                Location
                </label>
                <input
                type="text"
                id="location"
                className="form-control"
                placeholder="Enter Location"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                />
            </div>

            {/* Remarks Field */}
            <div className="col-md-3">
                <label htmlFor="remarks" className="form-label">
                Remarks
                </label>
                <input
                type="text"
                id="remarks"
                className="form-control"
                placeholder="Enter Remarks"
                value={remarks}
                onChange={(e) => setRemarks(e.target.value)}
                />
            </div>

            {/* Action Buttons */}
            <div className="col-md-4 d-flex align-items-end">
                <button type="submit" className="btn btn-success me-2">
                {editId ? "Update" : "Add"}
                </button>
                {editId && (
                <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={resetForm}
                >
                    Cancel
                </button>
                )}
            </div>
            </div>
        </form>

      {/* React Table */}
      <div className="formcontainer">
        <input
          className="form-control mb-3"
          placeholder="Search"
          value={globalFilter || ""}
          onChange={(e) => setGlobalFilter(e.target.value)}
        />
        <div className="table-responsive">
        <table className="table table-striped" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    {column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
        </div>

        {/* Pagination */}
        <div className="d-flex justify-content-between">
          <button
            className="btn btn-secondary"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            Previous
          </button>
          <span>
            Page {pageIndex + 1} of {pageOptions.length}
          </span>
          <button
            className="btn btn-secondary"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default LocationList;
