import React, { useState } from 'react';
import { SERVER_BASE } from '../config/server';
import 'bootstrap/dist/css/bootstrap.min.css';

const SecurityOutForm = () => {
  const [vehicleStatus, setVehicleStatus] = useState('');
  const [formData, setFormData] = useState({
    prefix: '',
    refNo: '',
    gatePassNo: '',
    vehicleNo: '',
    driverName: '',
    mobileNo: '',
    remarks: '',
    checked: false,
  });

  const handleStatusChange = (e) => {
    setVehicleStatus(e.target.value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCheckboxChange = (e) => {
    setFormData({
      ...formData,
      checked: e.target.checked,
    });
  };

  return (
    <div className="container mt-5">
      <h3>Security Out</h3>
      <form className='formcontainer'>
        <div className='row mb-2'>
        <div className="col-md-3">
          <label htmlFor="vehicleStatus" className="form-label">
            Vehicle Status
          </label>
          <select
            className="form-select"
            id="vehicleStatus"
            value={vehicleStatus}
            onChange={handleStatusChange}
          >
            <option value="">Select Vehicle Status</option>
            <option value="loaded">Loaded Vehicle</option>
            <option value="empty">Empty Vehicle</option>
          </select>
        </div>
        {vehicleStatus === 'loaded' && (
          <>
              <div className="col-3">
                <label htmlFor="prefix" className="form-label">
                  Prefix
                </label>
                <select
                  className="form-select"
                  id="prefix"
                  name="prefix"
                  value={formData.prefix}
                  onChange={handleChange}
                >
                  <option value="">Select Prefix</option>
                  <option value="A">A</option>
                  <option value="B">B</option>
                  <option value="C">C</option>
                  {/* Add more options as needed */}
                </select>
              </div>
              <div className="col-3">
                <label htmlFor="refNo" className="form-label">
                  Ref No
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="refNo"
                  name="refNo"
                  value={formData.refNo}
                  onChange={handleChange}
                />
              </div>
          </>
        )}

        {vehicleStatus === 'empty' && (
          <div className=" col-md-3">
            <label htmlFor="gatePassNo" className="form-label">
              Gate Pass No
            </label>
            <input
              type="text"
              className="form-control"
              id="gatePassNo"
              name="gatePassNo"
              value={formData.gatePassNo}
              onChange={handleChange}
            />
          </div>
        )}
        </div>

        <div className="row mb-3">
          <div className="col-3">
            <label htmlFor="vehicleNo" className="form-label">
              Vehicle No.
            </label>
            <input
              type="text"
              className="form-control"
              id="vehicleNo"
              name="vehicleNo"
              value={formData.vehicleNo}
              onChange={handleChange}
            />
          </div>
          <div className="col-3">
            <label htmlFor="driverName" className="form-label">
              Driver Name
            </label>
            <input
              type="text"
              className="form-control"
              id="driverName"
              name="driverName"
              value={formData.driverName}
              onChange={handleChange}
            />
          </div>
          <div className="col-3">
            <label htmlFor="mobileNo" className="form-label">
              Mobile No.
            </label>
            <input
              type="tel"
              className="form-control"
              id="mobileNo"
              name="mobileNo"
              value={formData.mobileNo}
              onChange={handleChange}
            />
          </div>
          <div className="col-3">
            <label htmlFor="remarks" className="form-label">
              Remarks
            </label>
            <input
              className="form-control"
              id="remarks"
              name="remarks"
              value={formData.remarks}
              onChange={handleChange}
            />
          </div>
        </div>


        <div className="mb-3 form-check">
          <input
            type="checkbox"
            className="form-check-input"
            id="checked"
            name="checked"
            checked={formData.checked}
            onChange={handleCheckboxChange}
          />
          <label className="form-check-label" htmlFor="checked">
            I have checked all the above information personally at the time of security check out of vehicle.
          </label>
        </div>

        <button type="submit" className="btn btn-success">
          Submit
        </button>
      </form>
    </div>
  );
};

export default SecurityOutForm;
