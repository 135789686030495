import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { SERVER_BASE } from '../config/server';
import { useTable, useSortBy, usePagination } from 'react-table';
import 'bootstrap/dist/css/bootstrap.min.css';

const DepartmentItem = () => {
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const code = sessionStorage.getItem('code');

  const fetchDepartments = async () => {
    try {
      const response = await axios.get(SERVER_BASE+"/departments", {
        params: { code },
      });
      const departmentsData = response.data;
      setDepartments(
        departmentsData.map((dept) => ({
          value: dept.name,
          label: dept.name,
        }))
      );
    } catch (error) {
      console.error("Error fetching departments:", error);
    }
  };
  useEffect(() => {
    fetchDepartments();
  }, []);

//   const handleGetItems = () => {
//     if (!selectedDepartment) {
//       alert('Please select a department');
//       return;
//     }

//     setLoading(true);
//     axios.get(`/api/items?departmentId=${selectedDepartment.value}`)
//       .then(response => {
//         setItems(response.data);
//         setLoading(false);
//       })
//       .catch(error => {
//         console.error('Error fetching items:', error);
//         setLoading(false);
//       });
//   };

  const handleGetItems = async () => {
    if (!selectedDepartment) {
        alert('Please select a department');
        return;
      }
      setLoading(true);
    try {
      const response = await axios.get(SERVER_BASE+"/getItemsByDept", {
        params: { centerCode:code, deptName:selectedDepartment.value },
      });
      console.log(response.data);
      
      setItems(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching items:", error);
      setLoading(false);
      return [];
    }
  };
  

  // Define columns for the React Table
  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'itemName', // Key from the data
      },
      {
        Header: 'Item Code',
        accessor: 'itemCode', // Key from the data
      },
      {
        Header: 'Group',
        accessor: 'itemGroup', // Key from the data
      },
      {
        Header: 'Group Code',
        accessor: 'itemGroupCode', // Key from the data
      },
      {
        Header: 'Description',
        accessor: 'itemDesc',
      },
      {
        Header: 'Item Type',
        accessor: 'type',
      },
      {
        Header: 'Size',
        accessor: 'size',
      },
      {
        Header: 'Make',
        accessor: 'make',
      },
      {
        Header: 'Color',
        accessor: 'color',
      },
      {
        Header: 'Avg Weight',
        accessor: 'avgWeight',
      },
      {
        Header: 'UOM',
        accessor: row => `${row.uom} - ${row.unit}`, // Combine `uom` and `unit`
        Cell: ({ value }) => <span>{value}</span>, // Render combined value
      },
      
    ],
    []
  );

  // Set up the table instance
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { pageIndex, pageSize },
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
  } = useTable(
    {
      columns,
      data: items,
      initialState: { pageIndex: 0 }, // Starting page
    },
    useSortBy,
    usePagination
  );

  return (
    <div className="container mt-5">
    <h3>Department's Item</h3>
      <div className="mb-4">
        <div className="row align-items-end">
          <div className="col-md-4">
            <label>Select Department:</label>
            <Select
              options={departments}
              value={selectedDepartment}
              onChange={setSelectedDepartment}
            />
          </div>
          <button onClick={handleGetItems} disabled={loading} className="btn btn-success col-md-1">
            {loading ? 'Loading...' : 'Get Items'}
          </button>
        </div>
      </div>

      {/* Display the table */}
      {items.length > 0 && (
        <div className="table-responsive">
          <table style={{ fontSize: '14px', verticalAlign: 'baseline' }} className="table table-striped" {...getTableProps()}>
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render('Header')}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? ' 🔽'
                            : ' 🔼'
                          : ''}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map(row => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map(cell => (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>

          <div className="d-flex justify-content-between align-items-center mt-3">
            <div>
              <button
                className="btn btn-secondary me-2"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                Previous
              </button>
              <button
                className="btn btn-secondary"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                Next
              </button>
            </div>
            <span>
              Page {pageIndex + 1} of {pageOptions.length}
            </span>
            <select
              className="form-select w-auto"
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50].map((size) => (
                <option key={size} value={size}>
                  Show {size}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}
    </div>
  );
};

export default DepartmentItem;
