import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: {}, // Store rows keyed by itemName or refNo
};

const inventorySlice = createSlice({
  name: "inventory",
  initialState,
  reducers: {
    setItemRows(state, action) {
      const { itemName, rows } = action.payload;
      state.items[itemName] = rows;
    },
    updateRowForItem(state, action) {
      const { itemName, index, field, value } = action.payload;
      if (state.items[itemName]) {
        state.items[itemName][index] = {
          ...state.items[itemName][index],
          [field]: value,
        };
      }
    },
  },
});

export const { setItemRows, updateRowForItem } = inventorySlice.actions;

export default inventorySlice.reducer;
