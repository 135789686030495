import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; 
import AddCenter from './components/addCenter';
// import DepartmentPage from './components/department';
// import LedgerGroupPage from './components/ledgerGroup';
import Login from './components/login'
import Navbar from './components/dashboard';
import SetPasswordFirstTime from './components/setPasswordFirstTime';
import store from './redux/store';
import { Provider } from 'react-redux';
const App = () => {
  
  return (

    <Provider store={store}>
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        {/* <Route path="/" element={<AddCenter />} /> */}
        <Route path="/dashboard/*" element={<Navbar />} />
        <Route path='/setPassword' element={<SetPasswordFirstTime/>}/>
      </Routes>
    </Router>
    </Provider>
  );
};

export default App;