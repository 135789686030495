import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { SERVER_BASE } from '../config/server';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment/moment';

const AddInventory = () => {
  const [isFormVisible, setIsFormVisible] = useState(false); // 'add' or 'less'
  const [header, setHeader] = useState({
    date: '',
    purpose: null,
    onAccountOf: null,
  });
  const [inventory, setInventory] = useState([
    { item: null, quantity: '', location: null, remarks: '', state: null, itemCode:'', uom:'', unit:'' },
  ]);
  const [drafts, setDrafts] = useState([]);
  const code = sessionStorage.getItem('code');
  const [editingDraftId, setEditingDraftId] = useState(null);

  const [purposes, setPurposes] = useState([]);
  const [locations, setLocations] = useState([]); // Location data

  const transactionOptions = [
    { value: 'Regular', label: 'Regular' },
    { value: 'Visit', label: 'Visit' },
  ];

  const [itemOptions, setItemOptions] = useState([]); // State for item options

  useEffect(() => {
    const fetchItemOptions = async () => {
        try {
            // Fetch the data separately from both routes
            const response = await axios.get(`${SERVER_BASE}/getItemOptions`, {
                params: { code }
            });

            // Set the combined data in the state
            setItemOptions(response.data.map((item) => ({
              value: item.itemName,
              label: `${item.itemName} ${item.additionalNames ? ` - [ ${item.additionalNames} ]` : ''}`,
              itemCode: item.itemCode,
              uom: item.uom,
              unit: item.unit,
          })));
        } catch (error) {
            console.error('Error fetching or merging item data:', error.message);
            console.error('Details:', error.response?.data || error.message);
        }
    };

    fetchItemOptions();
}, [code]);



  const stateOptions = [
    { value: 'As Stock', label: 'As Stock' },
    { value: 'As Installed', label: 'As Installed' },
    { value: 'As Attached with Land', label: 'As Attached with Land' },
    { value: 'As Installed under Earth', label: 'As Installed under Earth' },
  ];
  useEffect(() => {
    fetchPurposes();
    fetchLocations();
  }, []);

  const fetchPurposes = async () => {
    try {
      const response = await axios.get(`${SERVER_BASE}/getpurposesInventory`, {
        params: { code }, // Pass 'code' as a parameter in the request
      });
      setPurposes(
        response.data
          .filter((purpose) => purpose.purposeType !== 'Less') // Filter out 'less'
          .map((purpose) => ({
            value: purpose.purposeName,
            label: purpose.purposeName,
            type: purpose.purposeType,
          }))
      );
    } catch (error) {
      console.error('Error fetching purposes:', error);
    }
  };
  const fetchLocations = () => {
    axios
      .get(SERVER_BASE+"/getLocations", { params: { code } })
      .then((response) =>
        setLocations(
          response.data.map((location) => ({
            value: location.location,
            label: location.location,
          }))
        )
      )
      .catch((error) => console.error(error));
  };
  

  useEffect(() => {
    fetchDrafts();
  }, []);

  const fetchDrafts = async () => {
    try {
      const response = await axios.get(SERVER_BASE+'/getAddDrafts', {
        params: { code }, // Pass 'code' as a parameter in the request
      });
      setDrafts(response.data);
    } catch (error) {
      console.error('Error fetching drafts:', error);
    }
  };

  const handleAddRow = () => {
    setInventory((prev) => [
      ...prev,
      { item: null, quantity: '', location: null, remarks: '', state: null, itemCode:'', uom:'', unit:'' },
    ]);
  };

  const handleRemoveRow = (index) => {
    setInventory((prev) => prev.filter((_, i) => i !== index));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!header.date || !header.purpose || inventory.length === 0) {
      alert('Please fill all required fields.');
      return;
    }

    try {
      const payload = {
        date: header.date,
        purpose: header.purpose?.value || header.purpose, // Extract `value` if object
        on_account_of: header.onAccountOf?.value || header.onAccountOf, // Match backend field
        type:'add',
        code,
        inventory_data: inventory.map((item) => ({
          item: item.item?.value || item.item,
          location: item.location?.value || item.location,
          quantity: parseInt(item.quantity, 10), // Ensure quantity is numeric
          remarks: item.remarks || '',
          state: item.state?.value || item.state, // Extract `value` if object+
          unit: item.unit || '',
          itemCode: item.itemCode || '',
          uom: item.uom || '',
        })),
      };
      

      if (editingDraftId) {
        await axios.put(`${SERVER_BASE}/updateDraft/${editingDraftId}`, payload);
        alert('Draft updated successfully.');
      } else {
        await axios.post(SERVER_BASE+'/addInventoryDraft', payload);
        alert('Draft saved successfully.');
      }

      setHeader({ date: '', purpose: null, onAccountOf: null });
      setInventory([{ item: null, quantity: '', location: null, remarks: '', state: null, itemCode:'', uom:'', unit:'' }]);
      setEditingDraftId(null);
      fetchDrafts();
    } catch (error) {
      console.error('Error submitting draft:', error);
      alert('Error submitting draft.');
    }
  };

  const handleEditDraft = (draft) => {
    const { date, purpose, onAccountOf, inventory_data } = draft;
  
    setIsFormVisible(true);
  
    // Parse and correct the date to the format yyyy-mm-dd
    const formattedDate = new Date(date).toISOString().split('T')[0];
    // Find corresponding labels for purpose and authorized person
    const purposeLabel = purposes.find(p => p.value === purpose)?.label || '';
    const onAccountOfLabel = transactionOptions.find(p => p.value === onAccountOf)?.label || '';
  
    setHeader({
      date: formattedDate,
      purpose: { value: purpose, label: purposeLabel },
      onAccountOf: { value: onAccountOf, label: onAccountOfLabel }
    });
  
    // Map the inventory_data to the required format for the form
    const mappedInventory = inventory_data.map((item) => {
      // Find the corresponding label for the item and location
      const itemLabel = itemOptions.find(option => option.value === item.item)?.label || '';
      const locationLabel = locations.find(option => option.value === item.location)?.label || '';
      const stateLabel = stateOptions.find(option => option.value === item.state)?.label || '';
  
      return {
        item: { value: item.item, label: itemLabel },
        location: { value: item.location, label: locationLabel },
        state: { value: item.state, label: stateLabel }, 
        remarks: item.remarks || '', 
        quantity: item.quantity || '', 
        itemCode: item.itemCode || '', 
        uom: item.uom || '', 
        unit: item.unit || '', 
      };
    });
  
    setInventory(mappedInventory);
    setEditingDraftId(draft.id);
  };
  

  const handleDeleteDraft = async (draftId) => {
    if (window.confirm('Are you sure you want to delete this draft?')) {
      try {
        await axios.delete(`${SERVER_BASE}/deleteDraft/${draftId}`);
        alert('Draft deleted successfully.');
        fetchDrafts();
      } catch (error) {
        console.error('Error deleting draft:', error);
        alert('Error deleting draft.');
      }
    }
  };

  return (
    <div className="container mt-5">
      <h3 className="mb-3">{editingDraftId ? 'Edit Draft' : 'Add Inventory'}</h3>
      <button onClick={() => {
        setIsFormVisible(!isFormVisible)
        setHeader({ date: '', purpose: null, onAccountOf: null });
        setInventory([{ item: null, quantity: '', location: null, remarks: '', state: null, itemCode:'', uom:'', unit:'' }]);
      }
      } 
        className="btn btn-success mb-3">
        {isFormVisible ? 'Cancel' : 'Add Inventory'}
      </button>

      {/* Form */}
      {isFormVisible && (
      <form onSubmit={handleSubmit} className=''>
        <div className="row mb-3 formcontainer">
          <div className="col-md-4">
            <label htmlFor="date" className="form-label">Date</label>
            <input
              type="date"
              id="date"
              className="form-control"
              value={header.date}
              onChange={(e) => setHeader({ ...header, date: e.target.value })}
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="purpose" className="form-label">Purpose</label>
            <Select
              id="purpose"
              options={purposes}
              value={header.purpose}
              onChange={(selected) => setHeader({ ...header, purpose: selected })}
              isClearable
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="onAccountOf" className="form-label">On Account Of</label>
            <Select
              id="onAccountOf"
              options={transactionOptions}
              value={header.onAccountOf}
              onChange={(selected) => setHeader({ ...header, onAccountOf: selected })}
              isClearable
            />
          </div>
        </div>

        {/* Inventory Section */}
        <div className='formcontainer'>
        {inventory.map((data, index) => (
          <div className="row mb-2" key={index}>
            <div className="col-md-5">
              <label htmlFor={`item-${index}`} className="form-label">Item</label>
              <Select
                id={`item-${index}`}
                options={itemOptions}
                value={data.item}
                onChange={(selected) =>
                  setInventory((prev) => {
                    const updated = [...prev];
                    const selectedItem = itemOptions.find(option => option.value === selected.value);
                    updated[index] = {
                      ...updated[index], 
                      item: selectedItem, 
                      itemCode: selectedItem?.itemCode || '', 
                      uom: selectedItem?.uom || '', 
                      unit: selectedItem?.unit || '', 
                    };
                    return updated;
                  })
                }
              />
                          </div>
            <div className="col-md-1">
              <label htmlFor={`quantity-${index}`} className="form-label">Quantity</label>
              <input
                type="text"
                id={`quantity-${index}`}
                className="form-control"
                value={data.quantity}
                onChange={(e) =>
                  setInventory((prev) => {
                    const updated = [...prev];
                    updated[index].quantity = e.target.value;
                    return updated;
                  })
                }
              />
            </div>
            <div className="col-md-3">
              <label htmlFor={`location-${index}`} className="form-label">Location</label>
              <Select
                id={`location-${index}`}
                options={locations}
                value={data.location}
                onChange={(selected) =>
                  setInventory((prev) => {
                    const updated = [...prev];
                    updated[index].location = selected;
                    return updated;
                  })
                }
              />
            </div>
            <div className="col-md-2">
              <label htmlFor={`state-${index}`} className="form-label">State</label>
              <Select
                id={`state-${index}`}
                options={stateOptions}
                value={data.state}
                onChange={(selected) =>
                  setInventory((prev) => {
                    const updated = [...prev];
                    updated[index].state = selected;
                    return updated;
                  })
                }
              />
            </div>
            {/* <div className="col-md-2">
              <label htmlFor={`remarks-${index}`} className="form-label">Remarks</label>
              <input
                type="text"
                id={`remarks-${index}`}
                className="form-control"
                value={data.remarks}
                onChange={(e) =>
                  setInventory((prev) => {
                    const updated = [...prev];
                    updated[index].remarks = e.target.value;
                    return updated;
                  })
                }
              />
            </div> */}
            <div className="col-md-1 d-flex align-items-end">
              <button
                type="button"
                className="btn btn-success"
                onClick={handleAddRow}
                >
                <FontAwesomeIcon icon={faPlus} />
                </button>
                    {inventory.length > 1 && (
                      <button
                        type="button"
                        className="btn btn-danger ms-2"
                        onClick={() => handleRemoveRow(index)}
                      >
                        <FontAwesomeIcon icon={faMinus} />
                      </button>
                    )}
            </div>
          </div>
        ))}

        

        <button type="submit" className="btn btn-success">
          {editingDraftId ? 'Update Draft' : 'Save Draft'}
        </button>
        </div>
      </form>
      )}

      {/* Pending Drafts Table */}
      <h4 className="mt-5">Pending Drafts</h4>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Date</th>
            <th>Purpose</th>
            <th>On Account Of</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {drafts.map((draft) => (
            <tr key={draft.id}>
              <td>{moment(draft.date).format('DD-MM-YYYY')}</td>
              <td>{draft.purpose}</td>
              <td>{draft.onAccountOf}</td>
              <td>
                <button className="btn btn-warning me-2" onClick={() => handleEditDraft(draft)}>
                  <FontAwesomeIcon icon={faEdit} />
                </button>
                <button className="btn btn-danger" onClick={() => handleDeleteDraft(draft.id)}>
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AddInventory;
