import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { useTable, usePagination, useGlobalFilter, useSortBy } from 'react-table';
import Swal from 'sweetalert2';
import { SERVER_BASE } from '../config/server';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

const DepartmentPage = () => {
  const [departments, setDepartments] = useState([]);
  const [departmentName, setDepartmentName] = useState('');
  const [departmentalGroupCode, setDepartmentalGroupCode] = useState('');
  const [editIndex, setEditIndex] = useState(null);

  // Retrieve 'code' from session storage
  const code = sessionStorage.getItem('code');

  useEffect(() => {
    fetchDepartments();
  }, []);

  const fetchDepartments = async () => {
    try {
      const response = await axios.get(SERVER_BASE+'/departments', {
        params: { code }, // Pass 'code' as a parameter in the request
      });
      setDepartments(response.data);
    } catch (error) {
      console.error('Error fetching departments:', error);
    }
  };

  const handleDepartmentChange = (e) => setDepartmentName(e.target.value);
  const handleDepartmentCodeChange = (e) => setDepartmentalGroupCode(e.target.value);
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (departmentName.trim() === '') {
      Swal.fire('Please enter a department name', '', 'warning');
      return;
    }
  
    Swal.fire({
      title: editIndex !== null ? 'Confirm Update' : 'Confirm Create',
      text: editIndex !== null ? 'Are you sure you want to update this department?' : 'Are you sure you want to create this department?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          let responseMessage = ''; // Variable to hold response message
  
          if (editIndex !== null) {
            // Update department
            const response = await axios.put(`${SERVER_BASE}/updateDepartments/${departments[editIndex].id}`, {
              name: departmentName,
              departmentalGroupCode,
              code // Include 'code' in the update
            });
            responseMessage = response.data || 'Department updated successfully'; // Get response message from backend
          } else {
            // Create department
            const response = await axios.post(SERVER_BASE+'/addDepartments', {
              name: departmentName,
              departmentalGroupCode,
              code // Include 'code' in the creation
            });
            responseMessage = response.data || 'Department created successfully'; // Get response message from backend
          }
  
          setDepartmentName('');
          setDepartmentalGroupCode('')
          setEditIndex(null);
          fetchDepartments();
  
          // Show success message with the backend response
          Swal.fire({
            icon: 'success',
            title: editIndex !== null ? 'Updated!' : 'Created!',
            text: responseMessage,
            position: 'top-end', // Position at the top-end of the screen
            toast: true, // Display as toast
            timer: 2000,
            showConfirmButton:false // Show for 3 seconds
          });
        } catch (error) {
          console.error('Error saving department:', error);
  
          // Show error message if something went wrong
          Swal.fire({
            icon: 'error',
            // title: 'Error!',
            text: error.response?.data || 'There was an error processing your request.',
            position: 'top-end', // Position at the top-end of the screen
            toast: true, // Display as toast
            timer: 2000,
            showConfirmButton:false // Show for 3 seconds // Show for 3 seconds
          });
        }
      }
    });
  };
  

  const handleEdit = (index) => {
    setDepartmentName(departments[index].name);
    setDepartmentalGroupCode(departments[index].departmentalGroupCode)
    setEditIndex(index);
  };

  const handleDelete = async (index) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this department!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(`${SERVER_BASE}/deleteDepartments/${departments[index].id}`, {
            data: { code }, // Include 'code' in the delete request
          });
          fetchDepartments();
          Swal.fire('Deleted!', 'Your department has been deleted.', 'success');
        } catch (error) {
          console.error('Error deleting department:', error);
        }
      }
    });
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Department Name',
        accessor: 'name',
      },
      {
        Header: 'Departmental Group Code',
        accessor: 'departmentalGroupCode',
      },
      {
        Header: 'Action',
        Cell: ({ row }) => (
          <>
            <button className="btn btn-sm btn-success me-2" onClick={() => handleEdit(row.index)}>
              <FontAwesomeIcon icon={faEdit} />
            </button>
            <button className="btn btn-sm btn-danger" onClick={() => handleDelete(row.index)}>
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </>
        ),
      },
    ],
    [departments]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setGlobalFilter,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    state: { pageIndex, globalFilter },
  } = useTable(
    {
      columns,
      data: departments,
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <div className="container mt-5">
      <h3>Department</h3>
      <form onSubmit={handleSubmit} className="formcontainer mb-3">
        <div className="row align-items-end">
          <div className="form-group col-md-3">
            <label htmlFor="departmentName">Department Name</label>
            <input
              type="text"
              id="departmentName"
              className="form-control"
              value={departmentName}
              onChange={handleDepartmentChange}
              placeholder="Enter Department Name"
            />
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="departmentName">Departmental Group Code</label>
            <input
              type="text"
              id="departmentalGroupCode"
              className="form-control"
              value={departmentalGroupCode}
              onChange={handleDepartmentCodeChange}
              placeholder="Departmental Group Code"
            />
          </div>
          <div className="col-md-2">
            <button type="submit" className="btn btn-success w-50">
              {editIndex !== null ? 'Update' : 'Add'}
            </button>
          </div>
        </div>
      </form>

      <div className="formcontainer">
        <div className="mb-3">
          <input
            type="text"
            className="form-control"
            placeholder="Search departments"
            value={globalFilter || ''}
            onChange={(e) => setGlobalFilter(e.target.value)}
          />
        </div>

        <table {...getTableProps()} className="table table-striped">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}
                    <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>

        <div className="d-flex justify-content-between">
          <button onClick={() => previousPage()} disabled={!canPreviousPage} className="btn btn-secondary">
            Previous
          </button>
          <span>
            Page{' '}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>
          </span>
          <button onClick={() => nextPage()} disabled={!canNextPage} className="btn btn-secondary">
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default DepartmentPage;
