import React, { useState, useMemo,useEffect } from 'react';
import Select from 'react-select';
import { useTable, useSortBy, usePagination } from 'react-table';
import axios from 'axios'
import { SERVER_BASE } from '../config/server';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import imageCompression from 'browser-image-compression';
import Swal from 'sweetalert2';
// import 'sweetalert2/src/sweetalert2.scss';

const ITManager = () => {
  const [showForm, setShowForm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [itManagers, setITManagers] = useState([]);
  const [editItManagerId, setEditItManagerId] = useState(null);

  // Form state
  const [badgeId, setBadgeId] = useState('');
  const [zonalBadge, setZonalBadge] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [contactNo, setContactNo] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  const centerCode = sessionStorage.getItem('code');

  
  useEffect(() => {
    fetchITManagers();
  }, [centerCode]); // Re-fetch departments when centerCode changes

    const fetchITManagers = async () => {
      try {
        const response = await axios.get(SERVER_BASE+'/getITManager', {
          params: { code: centerCode },
        });
  
        setITManagers(response.data);
      } catch (error) {
        console.error('Error fetching ITManagers:', error);
      }
  };
const handleSubmit = async (e) => {
  e.preventDefault();

  // Validation: At least one of Badge ID or Zonal Badge ID must be filled
  if (!badgeId && !zonalBadge) {
    Swal.fire({
      icon: 'error',
      text: 'Please fill at least one of Badge ID or Zonal Badge ID.',
      toast: true,
      position: 'top-end',
      timer: 3000,
      showConfirmButton: false,
    });
    return;
  }

  // Validation: Check for unique email
  const emailExists = itManagers.some(
    (itManager) => itManager.email.toLowerCase() === email.toLowerCase() && itManager.id !== editItManagerId
  );
  if (emailExists) {
    Swal.fire({
      icon: 'error',
      text: 'Email already exists!',
      toast: true,
      position: 'top-end',
      timer: 3000,
      showConfirmButton: false,
    });
    return;
  }

  const payload = {
    badgeId,
    zonalBadge,
    firstName,
    lastName,
    username,
    email,
    contactNo,
    centerCode
  };

  try {
    if (isEdit) {
      // Update API call
      await axios.post(`${SERVER_BASE}/editITManager/${editItManagerId}`, payload);
      Swal.fire({
        icon: 'success',
        title: 'IT Manager updated successfully!',
        toast: true,
        position: 'top-end',
        timer: 3000,
        showConfirmButton: false,
      });
    } else {
      // Create API call
      const response = await axios.post(SERVER_BASE+'/addITManager', payload);
      setITManagers((prev) => [...prev, response.data]);
      Swal.fire({
        icon: 'success',
        title: 'IT Manager added successfully!',
        toast: true,
        position: 'top-end',
        timer: 3000,
        showConfirmButton: false,
      });
    }
    resetForm();
    fetchITManagers();
  } catch (error) {
    console.error('Error:', error);
    Swal.fire({
      icon: 'error',
      title: 'An error occurred while saving the IT Manager.',
      toast: true,
      position: 'top-end',
      timer: 3000,
      showConfirmButton: false,
    });
  }
};




const handleEdit = (itManager) => {
  console.log(itManager,'sewa');
  
  setShowForm(true);
  setIsEdit(true);
  setEditItManagerId(itManager.id);
  setBadgeId(itManager.badgeId);
  setZonalBadge(itManager.zonalBadgeId);
  setFirstName(itManager.firstName);
  setLastName(itManager.lastName);
  setUsername(itManager.username);
  setEmail(itManager.email);
  setContactNo(itManager.contactNo);
};  

const handleDelete = async (row) => {
  const { id, email, code,username } = row;  // Destructure the necessary fields from row.original

  const confirmation = await Swal.fire({
    title: 'Are you sure?',
    text: 'Do you want to delete this IT Manager? This action cannot be undone!',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#d33',
    cancelButtonColor: '#3085d6',
    confirmButtonText: 'Yes, delete it!',
    cancelButtonText: 'Cancel',
  });

  if (confirmation.isConfirmed) {
    try {
      // Send the entire row data to the server
      await axios.delete(SERVER_BASE+'/deleteITManager', {
        data: { ITManagerId:id, email, code:centerCode ,username},
      });

      setITManagers((prev) => prev.filter((itManager) => itManager.id !== id));

      Swal.fire({
        icon: 'success',
        title: 'ITManager deleted successfully!',
        toast: true,
        position: 'top-end',
        timer: 3000,
        showConfirmButton: false,
      });
    } catch (error) {
      console.error('Error deleting ITManager:', error);

      Swal.fire({
        icon: 'error',
        title: 'An error occurred while deleting the ITManager.',
        toast: true,
        position: 'top-end',
        timer: 3000,
        showConfirmButton: false,
      });
    }
  }
};


const resetForm = () => {
  setBadgeId('');
  setZonalBadge('');
  setFirstName('');
  setLastName('');
  setUsername('');
  setEmail('');
  setContactNo('');
  setShowForm(false);
  setIsEdit(false);
  setEditItManagerId(null);
};

const columns = useMemo(() => [
  { Header: 'Badge ID', accessor: 'badgeId' },
  { Header: 'Zonal ID', accessor: 'zonalBadgeId' },
  { Header: 'Name', accessor: (row) => `${row.firstName} ${row.lastName}` },
  { Header: 'Username', accessor: 'username' },
  { Header: 'Email', accessor: 'email' },
  { Header: 'Contact No', accessor: 'contactNo' },
  {
    Header: 'Actions',
    Cell: ({ row }) => (
      <>
        <button
          className="btn btn-sm btn-warning me-2"
          onClick={() => handleEdit(row.original)}
        >
          <FontAwesomeIcon icon={faEdit} />
        </button>
        <button
  className="btn btn-sm btn-danger"
  onClick={() => handleDelete(row.original)}
>
  <FontAwesomeIcon icon={faTrash} />
</button>

      </>
    ),
  },
], []);


  // Filter the data based on the search term
  const filteredData = useMemo(() => 
    itManagers.filter(itManager => 
      `${itManager.firstName} ${itManager.lastName}`
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    ),  
    [itManagers, searchTerm]
  );

  const data = useMemo(() => filteredData, [filteredData]);

  // Use the useTable hook with sorting and pagination
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page, // Use page instead of rows for pagination
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useSortBy, // Place useSortBy before usePagination
    usePagination // Place usePagination after useSortBy
  );

  return (
    <div className="container mt-5">
      <h3>IT Manager</h3>
      <button
  className="btn btn-success mb-3"
  onClick={() => {
    if (showForm) {
      resetForm(); // Reset the form when closing
    }
    setShowForm(!showForm);
  }}
>
  {showForm ? 'Close Form' : 'Add IT Manager'}
</button>


      {showForm && (
        <form onSubmit={handleSubmit} className="formcontainer mb-4">
          <div className="row mb-3">
            <div className="col-md-3">
              <label htmlFor="badgeId">Badge ID *</label>
              <input
                type="text"
                id="badgeId"
                className="form-control"
                value={badgeId}
                onChange={(e) => setBadgeId(e.target.value)}
                
              />
            </div>
            <div className="col-md-3">
              <label htmlFor="badgeId">Zonal Badge ID *</label>
              <input
                type="text"
                id="zonalBadge"
                className="form-control"
                value={zonalBadge}
                onChange={(e) => setZonalBadge(e.target.value)}
                
              />
            </div>
            <div className="col-md-3">
              <label htmlFor="firstName">First Name*</label>
              <input
                type="text"
                id="firstName"
                className="form-control"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
            </div>
            <div className="col-md-3">
              <label htmlFor="lastName">Last Name*</label>
              <input
                type="text"
                id="lastName"
                className="form-control"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-md-3">
              <label htmlFor="username">Username *</label>
              <input
                type="text"
                id="username"
                className="form-control"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
            <div className="col-md-3">
              <label htmlFor="email">Email *</label>
              <input
                type="email"
                id="email"
                className="form-control"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="col-md-3">
              <label htmlFor="contactNo">Contact No *</label>
              <input
                type="text"
                id="contactNo"
                className="form-control"
                value={contactNo}
                onChange={(e) => setContactNo(e.target.value)}
                required
              />
            </div>
          </div>
          <div>
          <button type="submit" className="btn btn-success me-2">Save IT Manager</button>
          {/* {isEdit && <button type="submit" className="btn btn-success" onClick={resetForm}>Cancel</button>} */}

          </div>
        </form>
      )}

      {/* Search Input */}
      <div className="formcontainer">
      <div className="mb-3">
        <input
          type="text"
          placeholder="Search"
          className="form-control"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className="table-responsive">
      <table className="table mt-4" {...getTableProps()} style={{ fontSize: '13px', verticalAlign: 'baseline' }}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())} key={column.id}>
                  {column.render('Header')}
                  {/* Add sort indicator */}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽' // Descending sort
                        : ' 🔼' // Ascending sort
                      : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
  {page.map(row => {
    prepareRow(row);
    return (
      <tr {...row.getRowProps()} key={row.id}>
        {row.cells.map(cell => (
          <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
        ))}
      </tr>
    );
  })}
</tbody>

      </table>
    </div>  

      {/* Updated Pagination Section */}
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div>
          <button
            className="btn btn-secondary me-2"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            {'< Previous'}
          </button>
          <button
            className="btn btn-secondary"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            {'Next >'}
          </button>
        </div>
        
        <div className="text-center">
          Page {pageIndex + 1} of {pageOptions.length}
        </div>
        
        <div>
          <select
            className="form-select"
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
          >
            {[5, 10, 20, 30].map(size => (
              <option key={size} value={size}>
                Show {size}
              </option>
            ))}
          </select>
        </div>
      </div>
      </div>
    </div>
  );
};

export default ITManager;
