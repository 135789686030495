import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { useTable, usePagination, useGlobalFilter, useSortBy } from 'react-table';
import Select from 'react-select';
import { SERVER_BASE } from '../config/server';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

const PurposePage = () => {
  const [ledgerGroups, setLedgerGroups] = useState([]);
  const [purposes, setPurposes] = useState([]);
  const [purposeName, setPurposeName] = useState('');
  const [selectedLedgerGroups, setSelectedLedgerGroups] = useState([]);
  const [editIndex, setEditIndex] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const code = sessionStorage.getItem('code');

  useEffect(() => {
    fetchLedgerGroups();
    fetchPurposes();
  }, []);

  const fetchLedgerGroups = async () => {
    try {
      const response = await axios.get(SERVER_BASE+'/ledger-groups', { params: { code } });
      setLedgerGroups(response.data);
    } catch (error) {
      console.error('Error fetching ledger groups:', error);
    }
  };

  const fetchPurposes = async () => {
    try {
      const response = await axios.get(SERVER_BASE+'/getpurposes');
      // console.log(response.data);
      
      setPurposes(response.data);
    } catch (error) {
      console.error('Error fetching purposes:', error);
    }
  };

  const handleInputChange = (setter) => (e) => {
    setter(e.target.value);
  };

  const handleLedgerGroupChange = (selectedOptions) => {
    setSelectedLedgerGroups(selectedOptions || []);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (purposeName.trim() === '' || selectedLedgerGroups.length === 0) {
      Swal.fire('Please fill all the required fields', '', 'warning');
      return;
    }

    const ledgerGroupsNames = selectedLedgerGroups.map((group) => group.label)

    Swal.fire({
      title: editIndex !== null ? 'Confirm Update' : 'Confirm Create',
      text: editIndex !== null ? 'Are you sure you want to update this purpose?' : 'Are you sure you want to create this purpose?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          if (editIndex !== null) {
            // console.log(purposes[editIndex])
            // Update existing purpose
            await axios.put(`${SERVER_BASE}/updatePurposes/${purposes[editIndex].purpose_id}`, {
              name: purposeName,
              ledgerGroups: ledgerGroupsNames,
              code
            });
            fetchPurposes();
            setEditIndex(null);
          } else {
            // Create new purpose
            await axios.post(SERVER_BASE+'/addpurposes', { name:purposeName, ledgerGroups: ledgerGroupsNames,code }, {
              headers: { "Content-Type": "application/json" },
            });
            fetchPurposes();
          }

          resetForm();
          Swal.fire(editIndex !== null ? 'Updated!' : 'Created!', `Purpose has been ${editIndex !== null ? 'updated' : 'created'}.`, 'success');
        } catch (error) {
          console.error('Error saving purpose:', error);
          Swal.fire('Error', 'There was an issue saving the purpose. Please try again later.', 'error');
        }
      }
    });
  };

  const resetForm = () => {
    setPurposeName('');
    setSelectedLedgerGroups([]);
  };

  const handleEdit = (index) => {
    const purpose = purposes[index];
    setPurposeName(purpose.purpose_name); // Assuming 'purpose_name' is the correct field
    const associatedGroups = purpose.ledgerGroups.map((group) => {
      return { value: group.ledger_id, label: group.ledger_name }; // Mapping directly from the ledgerGroups array
    });
    setSelectedLedgerGroups(associatedGroups);
    setEditIndex(index);
    setShowForm(true);
  };
  

  const handleDelete = (index) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this purpose!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(`${SERVER_BASE}/deletePurposes/${purposes[index].purpose_id}`, {
            data: { code }, // Include 'code' in the delete request
          });
          setPurposes((prev) => prev.filter((_, i) => i !== index));
          Swal.fire('Deleted!', 'Your purpose has been deleted.', 'success');
        } catch (error) {
          console.error('Error deleting purpose:', error);
          Swal.fire('Error', 'There was an issue deleting the purpose. Please try again later.', 'error');
        }
      }
    });
  };

  const columns = useMemo(
    () => [
      { Header: 'Purpose', accessor: 'purpose_name' }, // Assuming the column name is `purpose_name` in the data
      {
        Header: 'Ledger Groups',
        accessor: 'ledgerGroups', // This still points to the ledgerGroups array
        Cell: ({ cell: { value } }) => (
          <span>
            {value.map((group, index) => group.ledger_name).join(', ')}
          </span>
        ),
      },      
      {
        Header: 'Action',
        Cell: ({ row }) => (
          <>
            <button className="btn btn-sm btn-success me-2" onClick={() => handleEdit(row.index)}>
              <FontAwesomeIcon icon={faEdit} />
            </button>
            <button className="btn btn-sm btn-danger" onClick={() => handleDelete(row.index)}>
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </>
        ),
      },
    ],
    [purposes]
  );
  

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setGlobalFilter,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    state: { pageIndex, globalFilter },
  } = useTable(
    { columns, data: purposes, initialState: { pageIndex: 0 } },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <div className="container mt-5">
      <h3>Purpose Management</h3>

      <button className="btn btn-success mb-3" onClick={() => setShowForm(!showForm)}>
        {showForm ? 'Cancel' : 'Add Purpose'}
      </button>

      {showForm && (
       <form onSubmit={handleSubmit} className="formcontainer mb-3">
        <div className="row mb-3 align-items-end">
        <div className="col-md-5">
            <label htmlFor="purposeName" className="form-label">Purpose Name</label>
            <input
              type="text"
              id="purposeName"
              value={purposeName}
              onChange={handleInputChange(setPurposeName)}
              className="form-control"
              required
            />
          </div>

          <div className="col-md-5">
            <label htmlFor="ledgerGroups" className="form-label">Ledger Groups</label>
            <Select
              isMulti
              options={ledgerGroups.map((group) => ({ value: group.groupName, label: group.groupName }))}
              value={selectedLedgerGroups}
              onChange={handleLedgerGroupChange}
            />
          </div>
          <div className="col-md-2">
          <button type="submit" className="btn btn-success">
            {editIndex !== null ? 'Update' : 'Add'}
          </button>
          </div>
          </div>
        </form>
      )}
      <div className='formcontainer'>  
      <table {...getTableProps()} className="table table-striped">
      <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}
                    <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>

      <div className="pagination d-flex justify-content-between align-items-center">
        <button
          className="btn btn-secondary"
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          Previous
        </button>
        <span>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>
        </span>
        <button
          className="btn btn-secondary"
          onClick={() => nextPage()}
          disabled={!canNextPage}
        >
          Next
        </button>
      </div>
          </div>
    </div>
  );
};

export default PurposePage;
