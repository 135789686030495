import React, { useEffect, useState, useMemo } from 'react';
import axios from 'axios';
import { SERVER_BASE } from '../config/server';
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination
} from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

// Global Search Filter Component
const GlobalFilter = ({ filter, setFilter }) => {
  return (
    <input
      value={filter || ''}
      onChange={(e) => setFilter(e.target.value)}
      placeholder="Search centers..."
      style={{
        marginBottom: '10px',
        padding: '8px',
        fontSize: '16px',
        width: '100%',
        boxSizing: 'border-box',
      }}
      className='form-control'
    />
  );
};

const CenterList = () => {
  const [data, setData] = useState([]);

  // Fetch data from the backend
  useEffect(() => {
    const fetchCenters = async () => {
      try {
        const response = await axios.get(SERVER_BASE+'/centerInfo');
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchCenters();
  }, []);

  // Handle Edit action (you can customize this based on your need)
  const handleEdit = (centerId) => {
    // console.log('Editing center with ID:', centerId);
    // Implement your edit functionality here (e.g., open a form, etc.)
  };

  // Handle Delete action
  const handleDelete = (centerId) => {
    if (window.confirm('Are you sure you want to delete this center?')) {
      axios.delete(`${SERVER_BASE}/centerInfo/${centerId}`)
        .then((response) => {
          console.log('Center deleted:', response);
          // Refresh data after deletion
          setData((prevData) => prevData.filter(center => center.id !== centerId));
        })
        .catch((error) => {
          console.error('Error deleting center:', error);
        });
    }
  };

  // Define columns for the table
  const columns = useMemo(
    () => [
      { accessor: 'centerCode', Header: 'Center Code' },
      { accessor: 'centerName', Header: 'Center Name' },
      { accessor: 'gstin', Header: 'GSTIN' },
      { accessor: 'contactPerson1', Header: 'Contact Person 1' },
      {
        Header: 'Actions',
        Cell: ({ row }) => (
          <div>
            <button
              onClick={() => handleEdit(row.original.id)}
              className="btn btn-sm btn-success me-2"
            >
              <FontAwesomeIcon icon={faEdit} />
            </button>
            <button
              onClick={() => handleDelete(row.original.id)}
              className="btn btn-sm btn-danger"
            >
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </div>
        ),
      },
    ],
    []
  );

  // React Table hooks
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    gotoPage,
    setPageSize,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 5 }, // Default page size
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <div className='mt-5'>
      <h2 style={{ textAlign: 'center' }}>Center Details</h2>

      {/* Global Filter */}
      <GlobalFilter filter={state.globalFilter} setFilter={setGlobalFilter} />

      {/* Table */}
      <table {...getTableProps()} className="table table-striped">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  <span>
                    {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* Pagination Controls */}
      <div className="pagination d-flex justify-content-between align-items-center">
        <button onClick={() => previousPage()} disabled={!canPreviousPage} className='btn btn-secondary'>
          Previous
        </button>
        <span>
          Page <strong>{state.pageIndex + 1}</strong> of {pageOptions.length}
        </span>
        <button onClick={() => nextPage()} disabled={!canNextPage} className='btn btn-secondary'>
          Next
        </button>
      </div>
    </div>
  );
};

export default CenterList;
