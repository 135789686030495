import React, { useState } from "react";
import { SERVER_BASE } from '../config/server';
import "bootstrap/dist/css/bootstrap.min.css";

const ReceiptVoucher = () => {
  const [activeTab, setActiveTab] = useState("receiptInfo");

  return (
    <div className="container formcontainer mt-5">
      <h3 className="mb-4">Receipt Voucher</h3>
      <ul className="nav nav-tabs">
        <li className="nav-item">
          <button
            className={`nav-link ${activeTab === "receiptInfo" ? "active" : ""}`}
            onClick={() => setActiveTab("receiptInfo")}
          >
            Receipt Info
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`nav-link ${activeTab === "inventoryInfo" ? "active" : ""}`}
            onClick={() => setActiveTab("inventoryInfo")}
          >
            Inventory Info
          </button>
        </li>
      </ul>

      <div className="tab-content mt-4">
        {activeTab === "receiptInfo" && (
          <div className="tab-pane fade show active">
            <form>
              <div className="row mb-3">
                <div className="col-md-3">
                  <label className="form-label">SI No.</label>
                  <input type="text" className="form-control" placeholder="Enter SI No." />
                </div>
                <div className="col-md-3">
                  <label className="form-label">Receipt Date</label>
                  <input type="date" className="form-control" />
                </div>
                <div className="col-md-3">
                  <label className="form-label">Ledger Group</label>
                  <input type="text" className="form-control" placeholder="Enter Ledger Group" />
                </div>
                <div className="col-md-3">
                  <label className="form-label">Purpose</label>
                  <input type="text" className="form-control" placeholder="Enter Purpose" />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-md-3">
                  <label className="form-label">Ledger Name/Name</label>
                  <input type="text" className="form-control" placeholder="Enter Ledger Name" />
                </div>
                <div className="col-md-3">
                  <label className="form-label">Document Number</label>
                  <input type="text" className="form-control" placeholder="Enter Document Number" />
                </div>
                <div className="col-md-3">
                  <label className="form-label">Document Date</label>
                  <input type="date" className="form-control" />
                </div>
                <div className="col-md-3">
                  <label className="form-label">Vehicle No.</label>
                  <input type="text" className="form-control" placeholder="Enter Vehicle No." />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-md-3">
                  <label className="form-label">Driver Name/Name</label>
                  <input type="text" className="form-control" placeholder="Enter Driver Name" />
                </div>
                <div className="col-md-3">
                  <label className="form-label">Mob No.</label>
                  <input type="text" className="form-control" placeholder="Enter Mobile No." />
                </div>
              </div>
            </form>
          </div>
        )}

        {activeTab === "inventoryInfo" && (
          <div className="tab-pane fade show active">
            <h5>Inventory Info</h5>
            
          </div>
        )}
      </div>
    </div>
  );
};

export default ReceiptVoucher;
