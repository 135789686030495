import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { useTable, usePagination, useGlobalFilter, useSortBy } from 'react-table';
import Swal from 'sweetalert2';
import { SERVER_BASE } from '../config/server';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

const LedgerGroupPage = () => {
  const [ledgerGroups, setLedgerGroups] = useState([]);
  const [ledgerGroupName, setLedgerGroupName] = useState('');
  const [ledgerGroupCode, setLedgerGroupCode] = useState('');
  const [editIndex, setEditIndex] = useState(null);
  const code = sessionStorage.getItem('code');

  useEffect(() => {
    fetchLedgerGroups();
  }, []);

  const fetchLedgerGroups = async () => {
    try {
      const response = await axios.get(SERVER_BASE+'/ledger-groups', {
        params: { code },
      });
      setLedgerGroups(response.data);
    } catch (error) {
      console.error('Error fetching ledger groups:', error);
    }
  };

  const handleLedgerGroupChange = (e) => {
    setLedgerGroupName(e.target.value);
  };
  const handleLedgerGroupCodeChange = (e) => {
    setLedgerGroupCode(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (ledgerGroupName.trim() === '') {
      Swal.fire('Please enter a ledger group name', '', 'warning');
      return;
    }
    if (ledgerGroupCode.trim() === '') {
      Swal.fire('Please enter a ledger group code', '', 'warning');
      return;
    }

    Swal.fire({
      title: editIndex !== null ? 'Confirm Update' : 'Confirm Create',
      text: editIndex !== null ? 'Are you sure you want to update this ledger group?' : 'Are you sure you want to create this ledger group?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          let responseMessage = '';
  
          if (editIndex !== null) {
            const response = await axios.put(`${SERVER_BASE}/updateLedgerGroup/${ledgerGroups[editIndex].id}`, {
              name: ledgerGroupName,
              groupCode: ledgerGroupCode,
              code,
            });
            responseMessage = response.data || 'Ledger Group updated successfully';
          } else {
            const response = await axios.post(SERVER_BASE+'/addLedgerGroup', {
              name: ledgerGroupName,
              groupCode: ledgerGroupCode,
              code,
            });
            responseMessage = response.data || 'Ledger Group created successfully';
          }

          setLedgerGroupCode('');
          setLedgerGroupName('');
          setEditIndex(null);
          fetchLedgerGroups();
  
          Swal.fire({
            icon: 'success',
            title: editIndex !== null ? 'Updated!' : 'Created!',
            text: responseMessage,
            position: 'top-end',
            toast: true,
            timer: 2000,
            showConfirmButton: false,
          });
        } catch (error) {
          console.error('Error saving ledger group:', error);
          Swal.fire({
            icon: 'error',
            text: error.response?.data || 'There was an error processing your request.',
            position: 'top-end',
            toast: true,
            timer: 2000,
            showConfirmButton: false,
          });
        }
      }
    });
  };

  const handleEdit = (index) => {
    setLedgerGroupName(ledgerGroups[index].groupName);
    setLedgerGroupCode(ledgerGroups[index].groupCode);
    setEditIndex(index);
  };

  const handleDelete = (index) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this ledger group!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(`${SERVER_BASE}/deleteLedgerGroup/${ledgerGroups[index].id}`, {
            data: { code },
          });
          fetchLedgerGroups();
          Swal.fire('Deleted!', 'Your ledger group has been deleted.', 'success');
        } catch (error) {
          console.error('Error deleting ledger group:', error);
        }
      }
    });
  };

  // Define columns for react-table
  const columns = useMemo(
    () => [
      {
        Header: 'Ledger Group Name',
        accessor: 'groupName', // Access the 'name' field in the data
      },
      {
        Header: 'Ledger Group Code',
        accessor: 'groupCode', // Access the 'name' field in the data
      },
      {
        Header: 'Action',
        Cell: ({ row }) => (
          <>
            <button
              className="btn btn-sm btn-success me-2"
              onClick={() => handleEdit(row.index)}
            >
              <FontAwesomeIcon icon={faEdit} />
            </button>
            <button
              className="btn btn-sm btn-danger"
              onClick={() => handleDelete(row.index)}
            >
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </>
        ),
      },
    ],
    [ledgerGroups]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setGlobalFilter,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    state: { pageIndex, globalFilter },
  } = useTable(
    {
      columns,
      data: ledgerGroups,
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <div className=" container mt-5">
      <h3>Ledger Group</h3>
      <form onSubmit={handleSubmit} className="formcontainer mb-3">
      <div className="row align-items-end">
        <div className="form-group col-md-3">
            <label htmlFor="ledgerGroupName">Ledger Group Name</label>
            <input
              type="text"
              id="ledgerGroupName"
              className="form-control"
              value={ledgerGroupName}
              onChange={handleLedgerGroupChange}
              placeholder="Enter Ledger Group Name"
            />
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="ledgerGroupCode">Ledger Group Code</label>
            <input
              type="text"
              id="ledgerGroupCode"
              className="form-control"
              value={ledgerGroupCode}
              onChange={handleLedgerGroupCodeChange}
              placeholder="Enter Ledger Group Code"
            />
          </div>
          <div className="col-md-2">
            <button type="submit" className="btn btn-success w-50">
              {editIndex !== null ? 'Update' : 'Add'}
            </button>
          </div>
        </div>
      </form>

      <div className='formcontainer'>
      <div className="mb-3">
        <input
          type="text"
          className="form-control"
          placeholder="Search ledger groups"
          value={globalFilter || ''}
          onChange={(e) => setGlobalFilter(e.target.value)}
        />
      </div>

      <table {...getTableProps()} className="table table-striped">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  <span>
                    {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      
      <div className="pagination d-flex justify-content-between align-items-center">
        <button
          className="btn btn-secondary"
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          Previous
        </button>
        <span>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>
        </span>
        <button
          className="btn btn-secondary"
          onClick={() => nextPage()}
          disabled={!canNextPage}
        >
          Next
        </button>
      </div>
      </div>
    </div>
  );
};

export default LedgerGroupPage;
