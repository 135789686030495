import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import Swal from 'sweetalert2';
import { SERVER_BASE } from '../config/server';
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faMinusCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useTable, usePagination, useSortBy, useGlobalFilter } from "react-table";

const ItemMaster = () => {
  const [formData, setFormData] = useState({
    itemGroup: null,
    type: null,
    itemGroupCode: "",
    itemName: "",
    itemDesc: "",
    size: "Standard",
    make:"",
    color: "",
    avgWeight: "",
    uom: null,
    unit:'',
    concernedDepartments: [],
    remarks: "",
    additionalNames: [""],
    mmsCode: "",
    mmsName: "",
    mmsDesc: "",
  });

  const [itemGroups, setItemGroups] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [items, setItems] = useState([]); // State for table data
  const code = sessionStorage.getItem("code");
  const [formVisible, setFormVisible] = useState(false);
  const [uoms, setUoms] = useState([]); // UOM data

  // Fetch Item Groups
  const fetchItemGroups = async () => {
    try {
      const response = await axios.get(SERVER_BASE+"/item-groups", {
        params: { code },
      });
      setItemGroups(
        response.data.map((group) => ({
          value: group.groupName,
          label: group.groupName,
          type: group.type,
          itemGroupCode: group.groupCode,
        }))
      );
    } catch (error) {
      console.error("Error fetching item groups:", error);
    }
  };
  const fetchUOMs = async() => {
    try {
      const response = await axios.get(SERVER_BASE+"/getUoms", {
        params: { code },
      });
      setUoms(
        response.data.map((uom) => ({
          value: uom.abbreviation,
          label: `${uom.unit} - ${uom.abbreviation}`,
          unit: uom.unit
        }))
      );
    } catch (error) {
      console.error("Error fetching item groups:", error);
    }
  };

  // Fetch Departments
  const fetchDepartments = async () => {
    try {
      const response = await axios.get(SERVER_BASE+"/departments", {
        params: { code },
      });
      const departmentsData = response.data;
      setDepartments(
        departmentsData.map((dept) => ({
          value: dept.name,
          label: dept.name,
        }))
      );
    } catch (error) {
      console.error("Error fetching departments:", error);
    }
  };

  // Fetch items for the table
  const fetchItems = async () => {
    try {
      const response = await axios.get(SERVER_BASE+"/get-items", {
        params: { centerCode:code },
      });
      setItems(response.data);
    } catch (error) {
      console.error("Error fetching items:", error);
    }
  };

  const toggleFormVisibility = () => {
    setFormVisible(!formVisible);
    setFormData({
      itemGroup: null,
      type: null,
      itemGroupCode: "",
      itemName: "",
      itemDesc: "",
      size: "",
      make:"",
      color: "",
      avgWeight: "",
      uom: null,
      unit:'',
      concernedDepartments: [],
      remarks: "",
      additionalNames: [""],
      mmsCode: "",
      mmsName: "",
      mmsDesc: "",
    });
  };

  // Form Handlers
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSelectChange = (selectedOption, { name }) => {
    setFormData({ ...formData, [name]: selectedOption });
    if (name === "itemGroup") {
      setFormData((prevState) => ({
        ...prevState,
        itemGroupCode: selectedOption?.itemGroupCode || "",
      }));
    }
    if (name === "uom") {
      setFormData((prevState) => ({
        ...prevState,
        unit: selectedOption?.unit || "",
      }));
    }
  };

  const handleMultiSelectChange = (selectedOptions, { name }) => {
    setFormData({ ...formData, [name]: selectedOptions });
  };

  const handleAdditionalNameChange = (index, value) => {
    const newAdditionalNames = [...formData.additionalNames];
    newAdditionalNames[index] = value;
    setFormData({ ...formData, additionalNames: newAdditionalNames });
  };

  const handleAddAdditionalName = () => {
    setFormData({
      ...formData,
      additionalNames: [...formData.additionalNames, ""],
    });
  };

  const handleDeleteAdditionalName = (index) => {
    const newAdditionalNames = [...formData.additionalNames];
    newAdditionalNames.splice(index, 1);
    setFormData({ ...formData, additionalNames: newAdditionalNames });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Check if all required fields are filled
    if (!formData.itemName || !formData.itemGroup) {
      Swal.fire({
        icon: 'warning',
        title: 'Oops...',
        text: 'Please fill all required fields!',
        position: 'top-end',  // Positioning Swal to the right end
        showConfirmButton: false,
        timer: 1500, // Auto close after 1.5 seconds
      });
      return;
    }
  
    const submissionData = {
      ...formData,
      itemGroup: formData.itemGroup?.value || null,
      type: formData.type?.value || null,
      uom: formData.uom?.value || null,
      concernedDepartments: formData.concernedDepartments?.map((d) => d.value) || [],
      additionalNames: formData.additionalNames || [],
      code,
    };
  
    try {
      // Attempt to save the item
      const response = await axios.post(SERVER_BASE+"/save-item", submissionData, {
        headers: { "Content-Type": "application/json" },
      });
  
      // Success message if the item is saved successfully
      Swal.fire({
        icon: 'success',
        title: 'Created!',
        text: 'Item saved successfully!',
        position: 'top-end',  // Positioning Swal to the right end
        showConfirmButton: false,
        timer: 1500, // Auto close after 1.5 seconds
      });
  
      // Reset the form after successful submission
      setFormData({
        itemGroup: null,
        type: null,
        itemGroupCode: "",
        itemName: "",
        itemDesc: "",
        size: "",
        make:"",
        color: "",
        avgWeight: "",
        uom: null,
        unit:'',
        concernedDepartments: [],
        remarks: "",
        additionalNames: [""],
        mmsCode: "",
        mmsName: "",
        mmsDesc: "",
      });
  
      fetchItems(); // Refresh the table after submission
    } catch (error) {
      if (error.status === 400) {
        // If the response status is 400, show an error message indicating the item already exists
        Swal.fire({
          icon: 'error',
          title: 'Item already exists',
          text: 'This item already exists in the database.',
          position: 'top-end',  // Positioning Swal to the right end
          showConfirmButton: false,
          timer: 1500, // Auto close after 1.5 seconds
        });
        return;
      }
      if (error.status === 401) {
        // If the response status is 400, show an error message indicating the item already exists
        Swal.fire({
          icon: 'error',
          title: 'Item already exists',
          text: 'Item name already exists as Additional Name',
          position: 'top-end',  // Positioning Swal to the right end
          showConfirmButton: false,
          timer: 1500, // Auto close after 1.5 seconds
        });
        return;
      }
      if (error.status === 402) {
        // If the response status is 400, show an error message indicating the item already exists
        Swal.fire({
          icon: 'error',
          title: 'Item already exists',
          text: 'One or more additional names already exist.',
          position: 'top-end',  // Positioning Swal to the right end
          showConfirmButton: false,
          timer: 1500, // Auto close after 1.5 seconds
        });
        return;
      }
      console.error("Error saving data:", error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'There was an error saving the item.',
        position: 'top-end',  // Positioning Swal to the right end
        showConfirmButton: false,
        timer: 1500, // Auto close after 1.5 seconds
      });
    }
  };

  const handleDelete = async (row) => {
    const {id} = row;  // Destructure the necessary fields from row.original
    console.log(row);
    
  
    const confirmation = await Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to delete this Item? This action cannot be undone!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel',
    });
  
    if (confirmation.isConfirmed) {
      try {
        // Send the entire row data to the server
        await axios.delete(SERVER_BASE+'/deleteItemMaster', {
          data: {id:id, code},
        });
  
        fetchItems();
  
        Swal.fire({
          icon: 'success',
          title: 'Item deleted successfully!',
          toast: true,
          position: 'top-end',
          timer: 3000,
          showConfirmButton: false,
        });
      } catch (error) {
        console.error('Error deleting Item:', error);
  
        Swal.fire({
          icon: 'error',
          title: 'An error occurred while deleting the Item.',
          toast: true,
          position: 'top-end',
          timer: 3000,
          showConfirmButton: false,
        });
      }
    }
  };
  

  useEffect(() => {
    fetchItemGroups();
    fetchDepartments();
    fetchItems(); // Fetch items for the table
    fetchUOMs();
  }, []);

  // React Table configuration
  const columns = React.useMemo(
    () => [
      { Header: "Name", accessor: "itemName" },
      { Header: "Item Code", accessor: "itemCode" },
      { Header: "Group Code", accessor: "itemGroupCode" },
      { Header: "Type", accessor: "type" },
      { Header: "Size", accessor: "size" },
      { Header: "Make", accessor: "make" },
      { Header: "Color", accessor: "color" },
      { Header: "UOM", accessor: "uom" },
      { Header: "Weight", accessor: "avgWeight" },
      { Header: "Additional Names", accessor: "additionalNames" },
      { Header: "Depts", accessor: "concernedDepartments" },
      { Header: "Description", accessor: "itemDesc" },
      {
        Header: 'Actions',
        Cell: ({ row }) => (
          <>
            <button
              className="btn btn-sm btn-danger"
              onClick={() => handleDelete(row.original)}
            >
              <FontAwesomeIcon icon={faTrash} />
            </button>
    
          </>
        ),
      },
    ],
    []
  );

  const tableInstance = useTable(
    { columns, data: items, initialState: { pageIndex: 0 } },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    state,
    setGlobalFilter,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    pageOptions,
    gotoPage,
    pageCount,
    state: { pageIndex, globalFilter },
  } = tableInstance;

  const GlobalFilter = ({ filter, setFilter }) => {
    return (
      <span>
        Search:{" "}
        <input
          value={filter || ""}
          onChange={(e) => setFilter(e.target.value)}
          placeholder="Type to search..."
          style={{ marginBottom: "10px" }}
          className="form-control"
        />
      </span>
    );
  };

  return (
    <div className="container mt-5">
      <h3 className="mb-2">Item Master</h3>
      <button onClick={toggleFormVisibility} className="btn btn-success mb-3">
        {formVisible ? 'Cancel' : 'Add Item'}
      </button>
      {formVisible && (
      <div className="formcontainer"> 
      <form onSubmit={handleSubmit}>
      <div className="row">
          <div className="col-md-3">
            <label>Item Group</label>
            <Select
              options={itemGroups}
              name="itemGroup"
              onChange={handleSelectChange}
              value={formData.itemGroup}
            />
          </div>
          <div className="col-md-3">
            <label>Item Group Code</label>
            <input
              type="text"
              className="form-control"
              name="itemGroupCode"
              value={formData.itemGroupCode}
              readOnly
            />
          </div>
          <div className="col-md-3">
            <label>Type</label>
            <Select
              options={[
                { value: "Asset", label: "Asset" },
                { value: "Useable", label: "Useable" },
                { value: "Fixtures & Fittings", label: "Fixtures & Fittings" },
              ]}
              name="type"
              onChange={handleSelectChange}
              value={formData.type}
            />
          </div>

          <div className="col-md-3">
            <label>Item Name</label>
            <input
              type="text"
              className="form-control"
              name="itemName"
              value={formData.itemName}
              onChange={handleChange}
            />
          </div>
          <div className="col-md-3">
            <label>Item Description</label>
            <input
              type="text"
              className="form-control"
              name="itemDesc"
              value={formData.itemDesc}
              onChange={handleChange}
            />
          </div>
          <div className="col-md-3">
            <label>Size</label>
            <input
              type="text"
              className="form-control"
              name="size"
              value={formData.size}
              onChange={handleChange}
            />
          </div>
          <div className="col-md-3">
            <label>Make</label>
            <input
              type="text"
              className="form-control"
              name="make"
              value={formData.make}
              onChange={handleChange}
            />
          </div>
          <div className="col-md-3">
            <label>Color</label>
            <input
              type="text"
              className="form-control"
              name="color"
              value={formData.color}
              onChange={handleChange}
            />
          </div>

          <div className="col-md-3">
            <label>Avg Weight</label>
            <input
              type="text"
              className="form-control"
              name="avgWeight"
              value={formData.avgWeight}
              onChange={handleChange}
            />
          </div>
          <div className="col-md-3">
            <label>UOM</label>
            <Select
              options={uoms}
              name="uom"
              onChange={handleSelectChange}
              value={formData.uom}
            />
          </div>
          <div className="col-md-3">
            <label>Store Issuer Deptt</label>
            <Select
              isMulti
              options={departments}
              name="concernedDepartments"
              onChange={handleMultiSelectChange}
              value={formData.concernedDepartments}
            />
          </div>
          <div className="col-md-3">
            <label>Remarks</label>
            <input
              type="text"
              className="form-control"
              name="remarks"
              value={formData.remarks}
              onChange={handleChange}
            />
          </div>
          </div>

        <div className="mb-3">
          <label>Additional Names</label>
          <div className="d-flex flex-wrap">
            {formData.additionalNames.map((name, index) => (
              <div
                key={index}
                className="me-3 mb-2 d-flex align-items-center"
                style={{ flexShrink: 0 }}
              >
                <input
                  type="text"
                  className="form-control"
                  style={{ width: "210px" }}
                  placeholder={`Name ${index + 1}`}
                  value={name}
                  onChange={(e) =>
                    handleAdditionalNameChange(index, e.target.value)
                  }
                />
                {formData.additionalNames.length > 1 && (
                  <button
                    type="button"
                    className="btn btn-danger ms-2"
                    onClick={() => handleDeleteAdditionalName(index)}
                  >
                    <FontAwesomeIcon icon={faMinusCircle} />
                  </button>
                )}
                {index === formData.additionalNames.length - 1 && (
                  <button
                    type="button"
                    className="btn btn-success   ms-2"
                    onClick={handleAddAdditionalName}
                    >
                   <FontAwesomeIcon icon={faPlusCircle} />
                  </button>
                )}
              </div>
            ))}
          </div>
        </div>
          {/* <div class="border-top border-success p-1 mt-2"></div>
          <div className="row mb-3">
            <div className="col-md-3">
              <label>MMS Code</label>
              <input
                type="text"
                className="form-control"
                name="mmsCode"
                value={formData.mmsCode}
                onChange={handleChange}
              />
            </div>
            <div className="col-md-3">
              <label>MMS Item Name</label>
              <input
                type="text"
                className="form-control"
                name="mmsName"
                value={formData.mmsName}
                onChange={handleChange}
              />
            </div>
            <div className="col-md-3">
              <label>MMS Description</label>
              <input
                type="text"
                className="form-control"
                name="mmsDesc"
                value={formData.mmsDesc}
                onChange={handleChange}
              />
            </div>
          </div> */}

        <button type="submit" className="btn btn-success">
          Submit
        </button>
      </form>
      </div>
      )}
      <div className=" formcontainer mt-2">
        <h3>Items List</h3>
        <GlobalFilter filter={state.globalFilter} setFilter={setGlobalFilter} />
        <table className="table" {...getTableProps()} style={{ fontSize: '14px', verticalAlign: 'baseline' }}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="pagination d-flex justify-content-between align-items-center">
        <button
          className="btn btn-secondary"
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
          >
          Previous
        </button>
        <span>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>
        </span>
        <button
          className="btn btn-secondary"
          onClick={() => nextPage()}
          disabled={!canNextPage}
          >
          Next
        </button>
      </div>
      </div>
    </div>
  );
};

export default ItemMaster;
