import React, { useState, useEffect } from 'react';
import { useTable, usePagination, useGlobalFilter } from 'react-table';
import { SERVER_BASE } from '../config/server';
import Swal from 'sweetalert2';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios'

const CenterIncharge = () => {
  const [users, setUsers] = useState([]);
  const [name, setName] = useState('');
  const [mobile, setMobile] = useState('');
  const [editingIndex, setEditingIndex] = useState(null);

    useEffect(() => {
        fetchUsers();
    }, []);

    // Fetch all users from the database
    const fetchUsers = async () => {
        try {
            const response = await axios.get(SERVER_BASE+'/centerIncharge');
            setUsers(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    // Add or edit user in the database
    const addUser = async () => {
        if (name && mobile) {
            if (editingIndex !== null) {
                const userId = users[editingIndex].id;
                await axios.put(`${SERVER_BASE}/updateCenterIncharge/${userId}`, { name, mobile });
                setEditingIndex(null);
            } else {
                await axios.post(SERVER_BASE+'/addCenterIncharge', { name, mobile });
            }
            setName('');
            setMobile('');
            fetchUsers();
        } else {
            Swal.fire('Error', 'Please enter both name and mobile number', 'error');
        }
    };

    // Edit a user
    const editUser = (index) => {
        const user = users[index];
        setName(user.name);
        setMobile(user.mobile);
        setEditingIndex(index);
    };

    // Delete a user from the database
    const deleteUser = async (index) => {
        const userId = users[index].id;
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                await axios.delete(`${SERVER_BASE}/deletCenterIncharge/${userId}`);
                fetchUsers();
                Swal.fire('Deleted!', 'The user has been deleted.', 'success');
            }
        });
    };

  const data = React.useMemo(() => users, [users]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Mobile Number',
        accessor: 'mobile',
      },
      {
        Header: 'Center',
        accessor: 'centerName',
      },
      {
        Header: 'Action',
        Cell: ({ row }) => (
          <div>
            <button
              className="btn btn-warning btn-sm me-2"
              onClick={() => editUser(row.index)}
            >
              Edit
            </button>
            <button
              className="btn btn-danger btn-sm"
              onClick={() => deleteUser(row.index)}
            >
              Delete
            </button>
          </div>
        ),
      },
    ],
    [users]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 5 },
    },
    useGlobalFilter,
    usePagination
  );

  const { globalFilter, pageIndex, pageSize } = state;

  return (
    <div className="container my-5">
      <h3 className="mb-4">Center Incharge</h3>
      <div className="formcontainer mb-4">
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="row align-items-end">
            <div className="col-md-5 form-group">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                id="name"
                className="form-control"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="col-md-5 form-group">
              <label htmlFor="mobile">Mobile Number</label>
              <input
                type="text"
                id="mobile"
                className="form-control"
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
              />
            </div>
            <button
              type="button"
              className="btn btn-success col-md-2"
              onClick={addUser}
            >
              {editingIndex !== null ? 'Update' : 'Add'}
            </button>
          </div>
        </form>
      </div>

          <div className="formcontainer">
      {/* Search Filter */}
      <div className="mb-3">
        <input
          type="text"
          className="form-control"
          placeholder="Search..."
          value={globalFilter || ''}
          onChange={(e) => setGlobalFilter(e.target.value)}
        />
      </div>

      {/* Table */}
        <table className="table table-striped" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>

        {/* Pagination */}
        <div className="pagination d-flex justify-content-between align-items-center">
        <button
          className="btn btn-secondary"
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          Previous
        </button>
        <span>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>
        </span>
        <button
          className="btn btn-secondary"
          onClick={() => nextPage()}
          disabled={!canNextPage}
        >
          Next
        </button>
      </div>
      </div>
    </div>
  );
};

export default CenterIncharge;
