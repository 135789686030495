import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { SERVER_BASE } from '../config/server';
import { useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';

const SetPasswordFirstTime = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [pin, setPin] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showPin, setShowPin] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const centerCode = sessionStorage.getItem('code');
  useEffect(() => {
    // This will run only once, fetching the email from session storage
    const sessionEmail = sessionStorage.getItem('email');
    if (sessionEmail) {
      setEmail(sessionEmail);
    }
  }, []);  // Empty dependency array ensures this runs only once when the component mounts

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      toast.success("Please Wait...");
      const response = await axios.post(`${SERVER_BASE}/setPassword`, { email, password,centerCode, pin });
      toast.success(response.data.message);
      await delay(1500);
      navigate('/');
    } catch (error) {
      if (error.response && error.response.data) {
        toast.error(error.response.data.message);
      } else {
        toast.error('An unexpected error occurred');
        console.error(error);
      }
    } finally {
      setLoading(false);
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const toggleShowPin = () => {
    setShowPin(!showPin);
  };

  return (
    <div className="d-flex justify-content-center align-items-center vh-100 bg-light">
      <div className="card shadow p-4" style={{ width: '400px', height: 'auto' }}>
        <h2 className="text-center mb-4">Set Password</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="email" className="form-label">Email</label>
            <input
              type="text"
              className="form-control"
              id="email"
              value={email}
              disabled
            />
          </div>
          <div className="mb-3 position-relative">
            <label htmlFor="password" className="form-label">Password</label>
            <input
              type={showPassword ? 'text' : 'password'}
              className="form-control"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <span
              className="position-absolute"
              style={{ right: '10px', top: '73%', transform: 'translateY(-50%)', cursor: 'pointer' }}
              onClick={toggleShowPassword}
            >
              <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
            </span>
          </div>
          <div className="mb-3 position-relative">
            <label htmlFor="pin" className="form-label">Pin</label>
            <input
              type="text"
              className="form-control"
              id="pin"
              value={pin}
              onChange={(e) => setPin(e.target.value)}
              required
            />
            <span
              className="position-absolute"
              style={{ right: '10px', top: '73%', transform: 'translateY(-50%)', cursor: 'pointer' }}
              onClick={toggleShowPin}
            >
              <FontAwesomeIcon icon={showPin ? faEye : faEyeSlash} />
            </span>
          </div>
          <div className="d-grid mb-3">
            <button type="submit" className="btn btn-success" disabled={loading}>
              {loading ? 'Setting password...' : 'Set Password'}
            </button>
          </div>
        </form>
        <ToastContainer
          position="top-right"
          autoClose={1500} // Increased to give users more reading time
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          closeButton={false}
        />
      </div>
    </div>
  );
};

export default SetPasswordFirstTime;
