import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { SERVER_BASE } from '../config/server';
import axios from "axios";

const InventoryModal = ({ isOpen, onRequestClose, data, refNo, prefix ,fetchTotalQuantityForItem}) => {
  const [rows, setRows] = useState([]);
  const [userQuantities, setUserQuantities] = useState({});
  const [loading, setLoading] = useState(false);
  const centerCode = sessionStorage.getItem('code');

  const fetchLocations = async () => {
    setLoading(true);
    try {
      const response = await axios.get(SERVER_BASE+"/locations", {
        params: { item: data?.itemName, centerCode },
      });

      const options = response.data.map((loc) => ({
        location: loc.location,
        quantity: loc.quantity,
        uom: loc.uom,
        userQuantity: 0,
      }));
      setRows(options);
    } catch (error) {
      console.error("Error fetching locations:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchUserQuantities = async (itemName) => {
    setLoading(true);
    try {
      const response = await axios.get(SERVER_BASE+"/userQuantities", {
        params: { item: itemName, centerCode, refNo, prefix },
      });

      const quantities = response.data.reduce((acc, curr) => {
        acc[curr.location] = curr.userQuantity;
        return acc;
      }, {});
      setUserQuantities(quantities);
    } catch (error) {
      console.error("Error fetching user quantities:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (data?.itemName && isOpen) {
      fetchLocations();
      fetchUserQuantities(data?.itemName);
    }
  }, [data, isOpen, centerCode]);

  useEffect(() => {
    if (Object.keys(userQuantities).length > 0) {
      setRows((prevRows) =>
        prevRows.map((row) => ({
          ...row,
          userQuantity: userQuantities[row.location] || 0,
        }))
      );
    }
  }, [userQuantities]);

  const handleRowChange = (index, field, value) => {
    const updatedRows = [...rows];
    updatedRows[index][field] = value;
    setRows(updatedRows);
  };
  
  const handleSaveAndClose = async () => {
    const hasEmptyUserQuantity = rows.some(
      (row) => !row.userQuantity || row.userQuantity <= 0
    );
  
    if (hasEmptyUserQuantity) {
      onRequestClose();
      return;
    }
  
    try {
      const payload = {
        itemName: data?.itemName,
        centerCode: centerCode,
        refNo: refNo,
        prefix: prefix,
        rows: rows.map((row) => ({
          location: row.location,
          quantity: row.quantity,
          userQuantity: row.userQuantity || 0, // Default to 0 if not entered
          remaining: row.quantity - (parseFloat(row.userQuantity) || 0), // Calculate remaining
          uom: row.uom,
        })),
      };
  
      const response = await axios.post(SERVER_BASE+"/saveInventory", payload);
  
      if (response.status === 200) {
        alert("Data saved successfully!");
        fetchTotalQuantityForItem();
        onRequestClose();

      } else {
        alert("Failed to save data.");
      }
    } catch (error) {
      console.error("Error saving data:", error);
      alert("An error occurred while saving data.");
    }
  };
  

  // Calculate totals for the table footer
  const totalAvailable = rows.reduce((acc, row) => acc + (row.quantity || 0), 0);
  const totalChallan = rows.reduce((acc, row) => acc + (parseFloat(row.userQuantity) || 0), 0);
  const totalRemaining = totalAvailable - totalChallan;
  useEffect(() => {
    if (isOpen && data?.itemName) {
      fetchLocations();
      fetchUserQuantities(data?.itemName);
    }
  }, [isOpen, data?.itemName, centerCode]);
  
  return (
    <Modal
      isOpen={isOpen}
      contentLabel="Edit Location"
      style={{
        content: {
          height: "80%",
          width: "50%",
          margin: "auto",
        },
      }}
    >
      <div className="modal-content">
        <div className="modal-header d-flex justify-content-between">
          <h5 className="modal-title">Edit Item Details</h5>
          <button
            type="button"
            className="btn-close"
            onClick={handleSaveAndClose}
          ></button>
        </div>
        <div className="modal-body">
          <div className="row mb-3">
            <div className="col-md-4">
              <label className="form-label">Item Name</label>
              <input
                type="text"
                className="form-control"
                value={data?.itemName || ""}
                disabled
              />
            </div>
          </div>

          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Location</th>
                <th>Available</th>
                <th>Challan</th>
                <th>Remaining</th>
                <th>UOM</th>
              </tr>
            </thead>
            <tbody>
              {rows.map((row, index) => (
                <tr key={index}>
                  <td>{row.location}</td>
                  <td>{row.quantity}</td>
                  <td>
                    <input
                      type="number"
                      className="form-control"
                      value={row.userQuantity || ""}
                      onChange={(e) =>
                        handleRowChange(index, "userQuantity", e.target.value)
                      }
                      placeholder="Enter Quantity"
                    />
                  </td>
                  <td>{row.quantity - (parseFloat(row.userQuantity) || 0)}</td>
                  <td>{row.uom}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td><strong>Total</strong></td>
                <td><strong>{totalAvailable}</strong></td>
                <td><strong>{totalChallan}</strong></td>
                <td><strong>{totalRemaining}</strong></td>
                <td></td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </Modal>
  );
};

export default InventoryModal;
