import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { useTable, usePagination, useGlobalFilter, useSortBy } from 'react-table';
import Swal from 'sweetalert2';
import { SERVER_BASE } from '../config/server';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from 'react-tooltip';

const ItemGroupPage = () => {
  const [itemGroups, setItemGroups] = useState([]);
  const [itemGroupName, setItemGroupName] = useState('');
  const [itemGroupCode, setItemGroupCode] = useState('');
  const [inclusions, setInclusions] = useState('');
  const [exclusions, setExclusion] = useState('');
  const [editIndex, setEditIndex] = useState(null);

  const code = sessionStorage.getItem('code');

  useEffect(() => {
    fetchItemGroups();
  }, []);

  const fetchItemGroups = async () => {
    try {
      const response = await axios.get(SERVER_BASE+'/item-groups', {
        params: { code },
      });
      setItemGroups(response.data);
    } catch (error) {
      console.error('Error fetching item groups:', error);
    }
  };

  const handleItemGroupChange = (e) => setItemGroupName(e.target.value);
  const handleItemGroupCodeChange = (e) => setItemGroupCode(e.target.value);
  const handleItemGroupInclusionsChange = (e) => setInclusions(e.target.value);
  const handleItemGroupExclusionsChange = (e) => setExclusion(e.target.value);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!itemGroupName.trim() || !itemGroupCode.trim()) {
      Swal.fire('Please complete all fields', '', 'warning');
      return;
    }

    Swal.fire({
      title: editIndex !== null ? 'Confirm Update' : 'Confirm Create',
      text: editIndex !== null ? 'Are you sure you want to update this item group?' : 'Are you sure you want to create this item group?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          let responseMessage = '';
  
          if (editIndex !== null) {
            const response = await axios.put(`${SERVER_BASE}/updateItemGroup/${itemGroups[editIndex].id}`, {
              name: itemGroupName,
              groupCode: itemGroupCode,
              inclusions,
              exclusions,
              code,
            });
            responseMessage = response.data || 'Item Group updated successfully';
          } else {
            const response = await axios.post(SERVER_BASE+'/addItemGroup', {
              name: itemGroupName,
              groupCode: itemGroupCode,
              inclusions,
              exclusions,
              code,
            });
            responseMessage = response.data || 'Item Group created successfully';
          }

          setItemGroupName('');
          setItemGroupCode('');
          setExclusion('')
          setInclusions('')
          setEditIndex(null);
          fetchItemGroups();
  
          Swal.fire({
            icon: 'success',
            title: editIndex !== null ? 'Updated!' : 'Created!',
            text: responseMessage,
            position: 'top-end',
            toast: true,
            timer: 2000,
            showConfirmButton: false,
          });
        } catch (error) {
          console.error('Error saving item group:', error);
          Swal.fire({
            icon: 'error',
            text: error.response?.data || 'There was an error processing your request.',
            position: 'top-end',
            toast: true,
            timer: 2000,
            showConfirmButton: false,
          });
        }
      }
    });
  };

  const handleEdit = (index) => {
    setItemGroupName(itemGroups[index].groupName);
    setItemGroupCode(itemGroups[index].groupCode);
    setInclusions(itemGroups[index].inclusions);
    setExclusion(itemGroups[index].exclusions);
    setEditIndex(index);
  };

  const handleDelete = (index) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this item group!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(`${SERVER_BASE}/deleteItemGroup/${itemGroups[index].id}`, {
            data: { code },
          });
          fetchItemGroups();
          Swal.fire('Deleted!', 'Your item group has been deleted.', 'success');
        } catch (error) {
          console.error('Error deleting item group:', error);
        }
      }
    });
  };
  
  const columns = useMemo(
    () => [
      {
        Header: 'IGC',
        accessor: 'groupCode',
      },
      {
        Header: 'Item Group Name',
        accessor: 'groupName',
      },
      {
        Header: 'Inclusions',
        Cell: ({ row }) => (
          row.original.inclusions ? (
            <>
              <FontAwesomeIcon
                icon={faInfoCircle}
                data-tooltip-id={`tooltip-inclusions-${row.index}`}
                // className="text-primary"
              />
              <Tooltip id={`tooltip-inclusions-${row.index}`} place="top">
                {row.original.inclusions}
              </Tooltip>
            </>
          ) : null
        ),
      },
      {
        Header: 'Exclusions',
        Cell: ({ row }) => (
          row.original.exclusions ? (
            <>
              <FontAwesomeIcon
                icon={faInfoCircle}
                data-tooltip-id={`tooltip-exclusions-${row.index}`}
                // className="text-primary"
              />
              <Tooltip id={`tooltip-exclusions-${row.index}`} place="top">
                {row.original.exclusions}
              </Tooltip>
            </>
          ) : null
        ),
      },
      {
        Header: 'Action',
        Cell: ({ row }) => (
          <>
            <button className="btn btn-sm btn-success me-2" onClick={() => handleEdit(row.index)}>
              <FontAwesomeIcon icon={faEdit} />
            </button>
            <button className="btn btn-sm btn-danger" onClick={() => handleDelete(row.index)}>
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </>
        ),
      },
    ],
    [itemGroups]
  );
  

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setGlobalFilter,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    state: { pageIndex, globalFilter },
  } = useTable(
    {
      columns,
      data: itemGroups,
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <div className="container mt-5">
      <h3>Item Groups</h3>
      <form onSubmit={handleSubmit} className="formcontainer mb-3">
        <div className="row align-items-end">
          <div className="form-group col-md-3">
            <label htmlFor="itemGroupName">Item Group Name</label>
            <input
              type="text"
              id="itemGroupName"
              className="form-control"
              value={itemGroupName}
              onChange={handleItemGroupChange}
              placeholder="Enter Item Group Name"
            />
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="itemGroupCode">Item Group Code</label>
            <input
              type="text"
              id="itemGroupCode"
              className="form-control"
              value={itemGroupCode}
              onChange={handleItemGroupCodeChange}
              placeholder="Enter Item Group Code"
            />
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="inclusions">Inclusions</label>
            <input
              type="text"
              id="inclusions"
              className="form-control"
              value={inclusions}
              onChange={handleItemGroupInclusionsChange}
              placeholder="Enter Item Group inclusions"
            />
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="exclusions">Exclusions</label>
            <input
              type="text"
              id="exclusions"
              className="form-control"
              value={exclusions}
              onChange={handleItemGroupExclusionsChange}
              placeholder="Enter Item Group exclusions"
            />
          </div>
          <div className="col-md-2">
            <button type="submit" className="btn btn-success mt-2">
              {editIndex !== null ? 'Update' : 'Add'}
            </button>
          </div>
        </div>
      </form>

      <div className="formcontainer">
        <div className="mb-3">
          <input
            type="text"
            className="form-control"
            placeholder="Search item groups"
            value={globalFilter || ''}
            onChange={(e) => setGlobalFilter(e.target.value)}
          />
        </div>

        <table {...getTableProps()} className="table table-striped">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}
                    <span>
                      {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>

        <div className="d-flex justify-content-between align-items-center">
          <button className="btn btn-sm btn-secondary" onClick={() => previousPage()} disabled={!canPreviousPage}>
            Previous
          </button>
          <span>
            Page{' '}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{' '}
          </span>
          <button className="btn btn-sm btn-secondary" onClick={() => nextPage()} disabled={!canNextPage}>
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default ItemGroupPage;
