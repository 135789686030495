import React, { useState, useEffect, useMemo, useRef } from 'react';
import axios from 'axios';
import { SERVER_BASE } from '../config/server';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { useTable, usePagination, useGlobalFilter, useSortBy } from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';

const SecurityIn = () => {
  const [validationErrors, setValidationErrors] = useState({});
  const [showForm, setShowForm] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [formData, setFormData] = useState({
    cameFrom: '',
    purpose: '',
    vehicleNumber: '',
    driverName: '',
    mobileNo: '',
    entryDate: new Date().toISOString().split('T')[0], // Prefill with today's date
    entryTime: new Date().toLocaleTimeString('en-US', { hour12: false }).slice(0, 5), // Prefill with current time (HH:mm)
    documentStatus: '',
    documentNo: '',
    documentDate: '',
    ledgerName: '',
    itemGroups: [],
    remarks: '',
  });
  
  const [submittedData, setSubmittedData] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editSecurityId, setSecurityId] = useState(null);
  const code = sessionStorage.getItem('code');
  const [itemGroups, setItemGroups] = useState([]);
  const [ledgerGroups, setLedgerGroups] = useState([]);
  const [ledgers, setLedgers] = useState([]);
  const [purposes, setPurposes] = useState([]);
  const [securityIns, setSecurityIns] = useState([]);
  const documentOptions = [
    { value: 'original', label: 'Original Document Collected' },
    { value: 'copyDocs', label: 'Copy Of Document Collected' },
    { value: 'withoutDocs', label: 'Material Received Without Documents' },
  ];

  const handleInputChange = (e) => {
    fetchPurpposeByLedger();
    fetchLedgerMasterByLedger();
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [e.target.name]: undefined,
    }));
  };
  const validateForm = () => {
    const errors = {};

    if (!formData.cameFrom.trim()) {
      errors.cameFrom = 'Came From is required.';
    }
    if (!formData.purpose.trim()) {
      errors.purpose = 'Purpose is required.';
    }
    if (!formData.mobileNo.trim()) {
      errors.mobileNo = 'Mobile No is required.';
    }

    if (
      ['RSSB Center', 'Local Supplier', 'Zonal Purchase Supplier', 'Transporters'].includes(formData.cameFrom) &&
      !formData.vehicleNumber.trim()
    ) {
      errors.vehicleNumber = 'Vehicle No is required';
    }

    if (
      ['Sewadar', 'Sangat'].includes(formData.cameFrom) &&
      !formData.driverName.trim()
    ) {
      errors.driverName = 'Name is required';
    }

    if (
      ['RSSB Center', 'Local Supplier', 'Zonal Purchase Supplier', 'Transporters'].includes(formData.cameFrom)
    ) {
      if (!formData.entryDate.trim()) {
        errors.entryDate = 'Entry Date is required for the selected "Came From".';
      }
      if (!formData.entryTime.trim()) {
        errors.entryTime = 'Entry Time is required for the selected "Came From".';
      }
    }

        // Validate "Document Status" dependent fields
        if (
          ['original', 'copyDocs'].includes(formData.documentStatus)
        ) {
          if (!formData.documentNo.trim()) {
            errors.documentNo = 'Document No is required.';
          }
          if (!formData.documentDate.trim()) {
            errors.documentDate = 'Document Date is required.';
          }
        }
         if (!formData.documentStatus.trim()) {
          errors.documentStatus = 'Document Status is required.';
        }
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };
  useEffect(() => {
    fetchLedgerGroups();
    fetchSecurityIn();
    // fetchPurpposeByLedger();
    fetchItemGroups();
  }, []);
  const previousCameFrom = useRef(formData.cameFrom);

  useEffect(() => {
    if (formData.cameFrom && formData.cameFrom !== previousCameFrom.current) {
      previousCameFrom.current = formData.cameFrom;
      fetchPurpposeByLedger();
      fetchLedgerMasterByLedger();
    }
  }, [formData.cameFrom]);

  const fetchLedgerGroups = async () => {
    try {
      const response = await axios.get(SERVER_BASE+'/ledger-groups', {
        params: { code },
      });
      setLedgerGroups(response.data);
    } catch (error) {
      console.error('Error fetching ledger groups:', error);
    }
  };
  const fetchPurpposeByLedger = async () => {
    if (!formData.cameFrom) {
      return; // Don't fetch if cameFrom is not set
    }
    
    try {
      const response = await axios.get(SERVER_BASE+'/getPurposeByLedger', {
        params: { code, ledgerName: formData.cameFrom },
      });
      setPurposes(response.data);
    } catch (error) {
      console.error('Error fetching purposes by ledger:', error);
    }
  };

  const fetchSecurityIn = async () => {
    try {
      const response = await axios.get(SERVER_BASE+'/getSecurityIn', {
        params: { code: code },
      });
      setSecurityIns(response.data);
    } catch (error) {
      console.error('Error fetching Sewadars:', error);
    }
};
  
  const fetchLedgerMasterByLedger = async () => {
    if (!formData.cameFrom) {
      return; // Don't fetch if cameFrom is not set
    }
  
    try {
      const response = await axios.get(SERVER_BASE+'/getLedgerMasterByLedger', {
        params: { code, ledgerName: formData.cameFrom },
      });
      // console.log(response.data);
      setLedgers(response.data);
    } catch (error) {
      console.error('Error fetching ledger master:', error);
    }
  };
  const fetchItemGroups = async () => {
    try {
      const response = await axios.get(SERVER_BASE+"/item-groups", {
        params: { code },
      });
      setItemGroups(
        response.data.map((group) => ({
          value: group.groupName,
          label: group.groupName,
        }))
      );
    } catch (error) {
      console.error("Error fetching item groups:", error);
    }
  };

  const handleSelectChange = (selectedOption, name) => {
    setFormData({
      ...formData,
      [name]: selectedOption,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    Swal.fire({
      title: editSecurityId !== null ? 'Confirm Update' : 'Confirm Add',
      text: editSecurityId !== null ? 'Are you sure you want to update this entry?' : 'Are you sure you want to add this entry?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel',
    }).then(async (result) => {
      if (result.isConfirmed) {
        
        
        let response
        try {
          if (editSecurityId !== null) {
            const newData = { ...formData,code, itemGroups: itemGroups.map((d) => d.value), };
            console.log(newData);
            await axios.post(`${SERVER_BASE}/updateSecurity-in/${editSecurityId}`, {
              newData
            });
            fetchSecurityIn();
            setSecurityId(null);
          } else {
            const newData = { ...formData,code };
            console.log(newData);
            // Create new purpose
            response = await axios.post(SERVER_BASE+'/security-in', newData);
            fetchSecurityIn();
          }

          resetForm();
          Swal.fire(editSecurityId !== null ? 'Updated!' : 'Created!', `Your SI Number is ${response.data.siCode}`, 'success');
        } catch (error) {
          console.error('Error saving entry:', error);
          Swal.fire('Error', 'There was an issue saving the entry. Please try again later.', 'error');
        }
      }
    });
  
  };
  
  const handleEdit = (entry) => {
    setShowForm(true)
    setIsEditing(true);
    setSecurityId(entry.id)
    setFormData((prevState) => ({
      ...prevState, // Preserve the existing formData state
      cameFrom: entry.cameFrom,
      purpose:entry.purpose,
      vehicleNumber:entry.vehicleNumber,
      driverName:entry.driverName,
      mobileNo:entry.mobileNo,
      entryDate:entry.entryDate,
      entryTime:entry.entryTime,
      documentStatus:entry.documentStatus,
      documentDate:entry.documentDate,
      documentNo:entry.documentNo,
      ledgerName:entry.ledgerName,
      remarks:entry.remarks
    }));
    const itemArray = Array.isArray(entry.itemGroup)
  ? entry.itemGroup
  : entry.itemGroup
  ? entry.itemGroup.split('~') // If the string is separated by `~`
  : [];
  // console.log(itemArray);
  

  setFormData((prevState) => ({
    ...prevState,
    itemGroups: itemArray.map((item) => ({ value: item, label: item })), // Assuming you want to store this array in `itemGroup`
  }));
  };
  

  const handleDelete = async (row) => {
    const { id } = row;  // Destructure the necessary fields from row.original
  
    const confirmation = await Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to delete this security in entry? This action cannot be undone!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel',
    });
  
    if (confirmation.isConfirmed) {
      try {
        // Send the entire row data to the server
        await axios.delete(SERVER_BASE+'/deleteSecurityIn', {
          data: { id, code},
        });
  
        fetchSecurityIn();
  
        Swal.fire({
          icon: 'success',
          title: 'Entry deleted successfully!',
          toast: true,
          position: 'top-end',
          timer: 3000,
          showConfirmButton: false,
        });
      } catch (error) {
        console.error('Error deleting Entry:', error);
  
        Swal.fire({
          icon: 'error',
          title: 'An error occurred while deleting the Entry.',
          toast: true,
          position: 'top-end',
          timer: 3000,
          showConfirmButton: false,
        });
      }
    }
  };
  
  const columns = React.useMemo(
    () => [
      {
        Header: 'SI',
        accessor: 'siCode',
        Cell: ({ value }) => `#SI-${value}`, // Add '#SI-' prefix
      },
      { Header: 'Vehicle Entry', accessor: 'vehicleNumber' },
      { Header: 'Came From', accessor: 'cameFrom' },
      {
        Header: 'Item Group',
        accessor: 'itemGroup',
        Cell: ({ value }) => (
          <ul>
            {value?.split('~').map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        ),
      },
      { Header: 'Purpose', accessor: 'purpose' },
      {
        Header: 'Doc Status',
        accessor: 'documentStatus',
        Cell: ({ value }) => {
          const option = documentOptions.find((opt) => opt.value === value);
          return option ? option.label : value; // Show label if found, otherwise show raw value
        },
      },
      { Header: 'Document Number', accessor: 'documentNo' },
      { Header: 'Document Date', accessor: 'documentDate' },
      { Header: 'Driver Name', accessor: 'driverName' },
      { Header: 'Mobile Number', accessor: 'mobileNo' },
      {
        Header: 'Action',
        Cell: ({ row }) => (
          <div>
            {/* <button onClick={() => handleEdit(row.original)} className="btn btn-warning btn-sm"><FontAwesomeIcon icon={faEdit} /></button> */}
            <button
                className="btn btn-sm btn-danger"
                onClick={() => handleDelete(row.original)}
              >
                <FontAwesomeIcon icon={faTrash} />
            </button>
          </div>
        ),
      },
    ],
    [submittedData, securityIns]
  );   

  const data = useMemo(() => {
    return securityIns.filter(row => {
      const searchValue = searchInput.toLowerCase();
      return Object.values(row).some(value =>
        value?.toString().toLowerCase().includes(searchValue)
      );
    });
  }, [securityIns, searchInput]);
  
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    setGlobalFilter,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    pageOptions,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const resetForm = () => {
    setIsEditing(false)
    setSecurityId(null)
    setFormData({
      cameFrom: '',
      purpose: '',
      vehicleNumber: '',
      driverName: '',
      mobileNo: '',
      entryDate: new Date().toISOString().split('T')[0], // Prefill with today's date
      entryTime: new Date().toLocaleTimeString('en-US', { hour12: false }).slice(0, 5), // Prefill with current time (HH:mm)
      documentStatus: '',
      documentNo: '',
      documentDate: '',
      ledgerName: '',
      itemGroups: [],
      remarks: '',
    });
    setValidationErrors({});
  };

  return (
    <div className="container mt-5">
      <h3>Security In</h3>
      <button
        className="btn btn-success mb-3"
          onClick={() => {
            if (showForm) {
              resetForm(); // Reset the form when closing
            }
            setShowForm(!showForm);
          }}
        >
        {showForm ? 'Close' : 'Add'}
      </button>
    {showForm && (
      <form onSubmit={handleSubmit} className="formcontainer mb-3">
        <div className="row">
          <div className="col-md-3">
            <label>Came From</label>
            <select
                className={`form-control ${validationErrors.cameFrom ? 'is-invalid' : ''}`}
                name="cameFrom"
                onChange={handleInputChange}
                value={formData.cameFrom}
                
                >
                <option value="">Select Came From</option>
                {ledgerGroups.map((option) => (
                    <option key={option.id} value={option.groupName}>
                    {option.groupName}
                    </option>
                ))}
            </select>
            {validationErrors.cameFrom && (
              <div className="invalid-feedback">{validationErrors.cameFrom}</div>
            )}
          </div>
          <div className="col-md-3">
            <label>Purpose</label>
            <select
                className={`form-control ${validationErrors.purpose ? 'is-invalid' : ''}`}
                name="purpose"
                onChange={handleInputChange}
                value={formData.purpose}
                
                >
                <option value="">Select Purpose</option>
                {purposes.map((option) => (
                    <option key={option.id} value={option.purposeName}>
                    {option.purposeName}
                    </option>
                ))}
                </select>
                {validationErrors.purpose && (
              <div className="invalid-feedback">{validationErrors.purpose}</div>
            )}
          </div>
          <div className="col-md-3">
            <label>Vehicle Number</label>
            <input
              type="text"
              className={`form-control ${validationErrors.vehicleNumber ? 'is-invalid' : ''}`}
              name="vehicleNumber"
              onChange={handleInputChange}
              value={formData.vehicleNumber}
            />
            {validationErrors.vehicleNumber && (
              <div className="invalid-feedback">{validationErrors.vehicleNumber}</div>
            )}
          </div>
          <div className="col-md-3">
            <label>Name / Driver Name</label>
            <input
              type="text"
              className={`form-control ${validationErrors.driverName ? 'is-invalid' : ''}`}
              name="driverName"
              onChange={handleInputChange}
              value={formData.driverName}
            />
            {validationErrors.driverName && (
              <div className="invalid-feedback">{validationErrors.driverName}</div>
            )}
          </div>
          <div className="col-md-3">
            <label>Mobile No</label>
            <input type="text" className={`form-control ${validationErrors.mobileNo ? 'is-invalid' : ''}`} name="mobileNo" onChange={handleInputChange} value={formData.mobileNo} />
            {validationErrors.mobileNo && (
              <div className="invalid-feedback">{validationErrors.mobileNo}</div>
            )}
          </div>
          <div className="col-md-3">
            <label>Entry Date</label>
            <input
              type="date"
              className={`form-control ${validationErrors.entryDate ? 'is-invalid' : ''}`}
              name="entryDate"
              onChange={handleInputChange}
              value={formData.entryDate}
            />
            {validationErrors.entryDate && (
              <div className="invalid-feedback">{validationErrors.entryDate}</div>
            )}
          </div>

          <div className="col-md-3">
            <label>Entry Time</label>
            <input
              type="time"
              className={`form-control ${validationErrors.entryTime ? 'is-invalid' : ''}`}
              name="entryTime"
              onChange={handleInputChange}
              value={formData.entryTime}
            />
            {validationErrors.entryTime && (
              <div className="invalid-feedback">{validationErrors.entryTime}</div>
            )}
          </div>
          <div className="col-md-3">
            <label>Document Status</label>
            <select
                className={`form-control ${validationErrors.documentStatus ? 'is-invalid' : ''}`}
                name="documentStatus"
                onChange={handleInputChange}
                value={formData.documentStatus}
                >
                <option value="">Select Document Status</option>
                {documentOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                    {option.label}
                    </option>
                ))}
            </select>
            {validationErrors.documentStatus && (
              <div className="invalid-feedback">{validationErrors.documentStatus}</div>
            )}
          </div>
          <div className="col-md-3">
            <label>Document No</label>
            <input
              type="text"
              className={`form-control ${validationErrors.documentNo ? 'is-invalid' : ''}`}
              name="documentNo"
              onChange={handleInputChange}
              value={formData.documentNo}
            />
            {validationErrors.documentNo && (
              <div className="invalid-feedback">{validationErrors.documentNo}</div>
            )}
          </div>

          <div className="col-md-3">
            <label>Document Date</label>
            <input
              type="date"
              className={`form-control ${validationErrors.documentDate ? 'is-invalid' : ''}`}
              name="documentDate"
              onChange={handleInputChange}
              value={formData.documentDate}
            />
            {validationErrors.documentDate && (
              <div className="invalid-feedback">{validationErrors.documentDate}</div>
            )}
          </div>
          <div className="col-md-3">
            <label>Ledger Name</label>
            {['RSSB Center', 'Local Supplier', 'Zonal Purchase Supplier', 'Transporters'].includes(formData.cameFrom) ? (
              <select
                className={`form-control ${validationErrors.ledgerName ? 'is-invalid' : ''}`}
                name="ledgerName"
                onChange={handleInputChange}
                value={formData.ledgerName}
              >
                <option value="">Select Ledger</option>
                {ledgers.map((option) => (
                  <option key={option.id} value={option.ledgerName}>
                    {option.ledgerName}
                  </option>
                ))}
              </select>
            ) : (
              <input
                type="text"
                className={`form-control ${validationErrors.ledgerName ? 'is-invalid' : ''}`}
                name="ledgerName"
                onChange={handleInputChange}
                value={formData.ledgerName}
              />
            )}
            {validationErrors.ledgerName && (
              <div className="invalid-feedback">{validationErrors.ledgerName}</div>
            )}
          </div>
          <div className="col-md-3">
            <label>Item Groups</label>
            <Select
              isMulti
              options={itemGroups}
              value={formData.itemGroups}
              onChange={(selected) => handleSelectChange(selected, 'itemGroups')}
            />
          </div>
          <div className="col-md-3">
            <label>Remarks</label>
            <input className="form-control" name="remarks" onChange={handleInputChange} value={formData.remarks} />
          </div>
          <div className="col-12 mt-3">
            <button type="submit" className="btn btn-success">Submit</button>
          </div>
        </div>
      </form>
    )}

      <div className="formcontainer">
      <div className="mt-4">
        <input
          type="text"
          className="form-control mb-2"
          placeholder="Search..."
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
        />
      </div>
      <div className="table-responsive">
      <table {...getTableProps()} className="table table-striped mt-3" style={{ fontSize: '12px', verticalAlign: 'baseline' }}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      </div>
      <div className="pagination d-flex justify-content-between align-items-center">
          <button
            className="btn btn-secondary"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            Previous
          </button>
          <span>
            Page{' '}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>
          </span>
          <button
            className="btn btn-secondary"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default SecurityIn;
