import React, { useState, useMemo,useEffect } from 'react';
import Select from 'react-select';
import { useTable, useSortBy, usePagination } from 'react-table';
import axios from 'axios'
import { SERVER_BASE } from '../config/server';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import imageCompression from 'browser-image-compression';
import Swal from 'sweetalert2';
// import 'sweetalert2/src/sweetalert2.scss';

const SewadarForm = () => {
  const [showForm, setShowForm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [sewadars, setSewadars] = useState([]);
  const [editSewadarId, setEditSewadarId] = useState(null);

  // Form state
  const [badgeId, setBadgeId] = useState('');
  const [zonalBadge, setZonalBadge] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [contactNo, setContactNo] = useState('');
  const [department, setDepartment] = useState([]);
  const [position, setPosition] = useState('Sewadar');
  const [prefix, setPrefix] = useState([]);
  const [prefixes, setPrefixes] = useState([]);
  const [signature, setSignature] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [signatureFileName, setSignatureFileName] = useState("No signature uploaded");

  const [departments, setDepartments] = useState([]);
  const [departmentGroupOptions, setdepartmentGroupOptions] = useState([]);
  const [departmentGroup, setDepartmentGroup] = useState([]);

  const centerCode = sessionStorage.getItem('code');

  
  useEffect(() => {
    fetchDepartments();
    fetchPrefix();
    fetchSewadars();
  }, [centerCode]); // Re-fetch departments when centerCode changes

  const fetchDepartments = async () => {
    try {
      const response = await axios.get(SERVER_BASE+'/departments', {
        params: { code:centerCode }, // Pass 'centerCode' as a parameter in the request
      }); 

      // Map the response data to options for the Select component
      const options = response.data.map((dept) => ({
        value: dept.name,
        label: dept.name,
      }));

      // const uniqueDepartmentsCode = Array.from(
      //   new Set(
      //     response.data
      //       .map(dept => dept.departmentalGroupCode) // Extract group codes
      //       .filter(groupCode => groupCode && groupCode.trim() !== '') // Remove null, undefined, or empty strings
      //   )
      // );
      
  
      // // Map the distinct values to options for the Select component
      // const deparmentOptions = uniqueDepartmentsCode.map(groupCode => ({
      //   value: groupCode,
      //   label: groupCode,
      // }));


      
      // setdepartmentGroupOptions(deparmentOptions)
      setDepartments(options);
    } catch (error) {
      console.error('Error fetching departments:', error);
    }
  };

  

  
  const fetchPrefix = async () => {
    try {
      const response = await axios.get(SERVER_BASE+'/prefixDetails', {
        params: { code:centerCode }, // Pass 'centerCode' as a parameter in the request
      });

      // Map the response data to options for the Select component
      const options = response.data.map((pre) => ({
        value: pre.prefix,
        label: pre.prefix,
      }));
      setPrefixes(options);
    } catch (error) {
      console.error('Error fetching prefix:', error);
    }
  };
 

    const fetchSewadars = async () => {
      try {
        const response = await axios.get(SERVER_BASE+'/getSewadar', {
          params: { code: centerCode },
        });
  console.log(response.data,'sjdf');
  
        setSewadars(response.data);
      } catch (error) {
        console.error('Error fetching Sewadars:', error);
      }
  };
  
const handleFileChange = async (e) => {
  const file = e.target.files[0];
  if (file) {
    try {
      // Compress the image
      const options = {
        maxSizeMB: 1, // Maximum file size in MB
        maxWidthOrHeight: 1024, // Max width/height of the image
        useWebWorker: true, // Use multi-threading for performance
      };
      const compressedFile = await imageCompression(file, options);

      // Convert to Base64
      const reader = new FileReader();
      reader.onloadend = () => {
        setSignature(reader.result); // Base64 string
      };
      reader.readAsDataURL(compressedFile);
    } catch (error) {
      console.error('Error compressing the image:', error);
    }
  }
};
const handleSubmit = async (e) => {
  e.preventDefault();

  // Validation: At least one of Badge ID or Zonal Badge ID must be filled
  if (!badgeId && !zonalBadge) {
    Swal.fire({
      icon: 'error',
      text: 'Please fill at least one of Badge ID or Zonal Badge ID.',
      toast: true,
      position: 'top-end',
      timer: 3000,
      showConfirmButton: false,
    });
    return;
  }

  // Validation: Check for unique email
  const emailExists = sewadars.some(
    (sewadar) => sewadar.email.toLowerCase() === email.toLowerCase() && sewadar.id !== editSewadarId
  );
  if (emailExists) {
    Swal.fire({
      icon: 'error',
      text: 'Email already exists!',
      toast: true,
      position: 'top-end',
      timer: 3000,
      showConfirmButton: false,
    });
    return;
  }

  const payload = {
    badgeId,
    zonalBadge,
    firstName,
    lastName,
    username,
    email,
    contactNo,
    position,
    departments: department.map((d) => d.value),
    prefixes: prefix.map((p) => p.value),
    // departmentalGroupCode:departmentGroup.map((t)=>t.value),
    centerCode,
    signature,
  };

  try {
    if (isEdit) {
      // Update API call
      await axios.post(`${SERVER_BASE}/editSewadar/${editSewadarId}`, payload);
      Swal.fire({
        icon: 'success',
        title: 'Sewadar updated successfully!',
        toast: true,
        position: 'top-end',
        timer: 3000,
        showConfirmButton: false,
      });
    } else {
      // Create API call
      const response = await axios.post(SERVER_BASE+'/addSewadar', payload);
      setSewadars((prev) => [...prev, response.data]);
      Swal.fire({
        icon: 'success',
        title: 'Sewadar added successfully!',
        toast: true,
        position: 'top-end',
        timer: 3000,
        showConfirmButton: false,
      });
    }
    resetForm();
    fetchSewadars();
  } catch (error) {
    console.error('Error:', error);
    Swal.fire({
      icon: 'error',
      title: 'An error occurred while saving the Sewadar.',
      toast: true,
      position: 'top-end',
      timer: 3000,
      showConfirmButton: false,
    });
  }
};




const handleEdit = (sewadar) => {
  console.log(sewadar,'sewa');
  
  setShowForm(true);
  setIsEdit(true);
  setEditSewadarId(sewadar.id);
  setBadgeId(sewadar.badgeId);
  setZonalBadge(sewadar.zonalBadgeId);
  setFirstName(sewadar.firstName);
  setLastName(sewadar.lastName);
  setUsername(sewadar.username);
  setEmail(sewadar.email);
  setContactNo(sewadar.contactNo);

  // Ensure department and prefix are arrays
  const departmentArray = Array.isArray(sewadar.department)
    ? sewadar.department
    : sewadar.department
    ? sewadar.department.split(',') // If comma-separated string
    : [];
  setDepartment(departmentArray.map((d) => ({ value: d, label: d })));

  const prefixArray = Array.isArray(sewadar.prefix)
    ? sewadar.prefix
    : sewadar.prefix
    ? sewadar.prefix.split(',') // If comma-separated string
    : [];
  setPrefix(prefixArray.map((p) => ({ value: p, label: p })));

  // const departmentGroupArray = Array.isArray(sewadar.departmentGroupCode)
  // ? sewadar.departmentGroupCode
  // : sewadar.departmentGroupCode
  // ? sewadar.departmentGroupCode.split(',') // If comma-separated string
  // : [];
  // setDepartmentGroup(departmentGroupArray.map((d) => ({ value: d, label: d })));
  
  setPosition(sewadar.position);
  if (sewadar.signImage) {
    const base64String = sewadar.signImage;
    const mimeType = base64String.match(/data:(.*?);base64/)[1]; // Extract MIME type
    const extension = mimeType.split('/')[1]; // Get file extension
    const signatureFileName = `signature.${extension}`; // Generate file name
    setSignatureFileName(signatureFileName);
  } else {
    setSignatureFileName("No signature uploaded");
  }
};  

const handleDelete = async (row) => {
  const { id, email, code,username } = row;  // Destructure the necessary fields from row.original

  const confirmation = await Swal.fire({
    title: 'Are you sure?',
    text: 'Do you want to delete this Sewadar? This action cannot be undone!',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#d33',
    cancelButtonColor: '#3085d6',
    confirmButtonText: 'Yes, delete it!',
    cancelButtonText: 'Cancel',
  });

  if (confirmation.isConfirmed) {
    try {
      // Send the entire row data to the server
      await axios.delete(SERVER_BASE+'/deleteSewadar', {
        data: { sewadarId:id, email, code:centerCode ,username},
      });

      setSewadars((prev) => prev.filter((sewadar) => sewadar.id !== id));

      Swal.fire({
        icon: 'success',
        title: 'Sewadar deleted successfully!',
        toast: true,
        position: 'top-end',
        timer: 3000,
        showConfirmButton: false,
      });
    } catch (error) {
      console.error('Error deleting Sewadar:', error);

      Swal.fire({
        icon: 'error',
        title: 'An error occurred while deleting the Sewadar.',
        toast: true,
        position: 'top-end',
        timer: 3000,
        showConfirmButton: false,
      });
    }
  }
};


const resetForm = () => {
  setBadgeId('');
  setZonalBadge('');
  setFirstName('');
  setLastName('');
  setUsername('');
  setEmail('');
  setContactNo('');
  setDepartment([]);
  setPosition('Sewadar');
  setPrefix([]);
  // setDepartmentGroup([]);
  setSignature(null);
  setShowForm(false);
  setIsEdit(false);
  setEditSewadarId(null);
};

const columns = useMemo(() => [
  { Header: 'Badge ID', accessor: 'badgeId' },
  { Header: 'Zonal ID', accessor: 'zonalBadgeId' },
  { Header: 'Name', accessor: (row) => `${row.firstName} ${row.lastName}` },
  { Header: 'Username', accessor: 'username' },
  { Header: 'Email', accessor: 'email' },
  { Header: 'Contact No', accessor: 'contactNo' },
  { Header: 'Department', accessor: 'department' },
  // { Header: 'Dep. grp code', accessor: 'departmentGroupCode' },
  { Header: 'Position', accessor: 'position' },
  { Header: 'Prefix', accessor: 'prefix' },
  {
    Header: 'Actions',
    Cell: ({ row }) => (
      <>
        <button
          className="btn btn-sm btn-warning me-2"
          onClick={() => handleEdit(row.original)}
        >
          <FontAwesomeIcon icon={faEdit} />
        </button>
        <button
  className="btn btn-sm btn-danger"
  onClick={() => handleDelete(row.original)}
>
  <FontAwesomeIcon icon={faTrash} />
</button>

      </>
    ),
  },
], []);


  // Filter the data based on the search term
  const filteredData = useMemo(() => 
    sewadars.filter(sewad => 
      `${sewad.firstName} ${sewad.lastName}`
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    ),  
    [sewadars, searchTerm]
  );

  const data = useMemo(() => filteredData, [filteredData]);

  // Use the useTable hook with sorting and pagination
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page, // Use page instead of rows for pagination
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useSortBy, // Place useSortBy before usePagination
    usePagination // Place usePagination after useSortBy
  );

  return (
    <div className="container mt-5">
      <h3>Sewadar</h3>
      <button
  className="btn btn-success mb-3"
  onClick={() => {
    if (showForm) {
      resetForm(); // Reset the form when closing
    }
    setShowForm(!showForm);
  }}
>
  {showForm ? 'Close Form' : 'Add Sewadar'}
</button>


      {showForm && (
        <form onSubmit={handleSubmit} className="formcontainer mb-4">
          <div className="row mb-3">
            <div className="col-md-3">
              <label htmlFor="badgeId">Badge ID *</label>
              <input
                type="text"
                id="badgeId"
                className="form-control"
                value={badgeId}
                onChange={(e) => setBadgeId(e.target.value)}
                
              />
            </div>
            <div className="col-md-3">
              <label htmlFor="badgeId">Zonal Badge ID *</label>
              <input
                type="text"
                id="zonalBadge"
                className="form-control"
                value={zonalBadge}
                onChange={(e) => setZonalBadge(e.target.value)}
                
              />
            </div>
            <div className="col-md-3">
              <label htmlFor="firstName">First Name*</label>
              <input
                type="text"
                id="firstName"
                className="form-control"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
            </div>
            <div className="col-md-3">
              <label htmlFor="lastName">Last Name*</label>
              <input
                type="text"
                id="lastName"
                className="form-control"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-md-3">
              <label>Position *</label>
              <select className="form-select" value={position} onChange={(e) => setPosition(e.target.value)}>
                <option value="Sewadar">Sewadar</option>
                <option value="Authorized Person">Authorized Person</option>
                <option value="Approver">Approver</option>
              </select>
            </div>
            <div className="col-md-3">
              <label htmlFor="username">Username *</label>
              <input
                type="text"
                id="username"
                className="form-control"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
            <div className="col-md-3">
              <label htmlFor="email">Email *</label>
              <input
                type="email"
                id="email"
                className="form-control"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="col-md-3">
              <label htmlFor="contactNo">Contact No *</label>
              <input
                type="text"
                id="contactNo"
                className="form-control"
                value={contactNo}
                onChange={(e) => setContactNo(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="row mb-3">

      <div className="col-md-3">
      <label> Access of Department Stores *</label>
      <Select
        isMulti
        options={departments} // Use the fetched department options
        value={department} // Selected departments
        onChange={setDepartment} // Update the state on change
      />
    </div>
            {/* <div className="col-md-3">
              <label>Department Group Code *</label>
              <Select isMulti options={departmentGroupOptions} value={departmentGroup} onChange={setDepartmentGroup} />
            </div> */}
            <div className="col-md-3">
              <label>Prefix *</label>
              <Select isMulti options={prefixes} value={prefix} onChange={setPrefix} />
            </div>
            <div className="col-md-3">
    <label>Upload Signature as Image</label>
    <input 
      type="file" 
      className="form-control form-control-sm" 
      id="signature"
      accept="image/*"
      onChange={handleFileChange} 
    />
    {isEdit && <p>{signatureFileName}</p>}
  </div>
  
          </div>
          <div>
          <button type="submit" className="btn btn-success me-2">Save Sewadar</button>
          {/* {isEdit && <button type="submit" className="btn btn-success" onClick={resetForm}>Cancel</button>} */}

          </div>
        </form>
      )}

      {/* Search Input */}
      <div className="formcontainer">
      <div className="mb-3">
        <input
          type="text"
          placeholder="Search"
          className="form-control"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className="table-responsive">
      <table className="table mt-4" {...getTableProps()} style={{ fontSize: '13px', verticalAlign: 'baseline' }}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())} key={column.id}>
                  {column.render('Header')}
                  {/* Add sort indicator */}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽' // Descending sort
                        : ' 🔼' // Ascending sort
                      : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
  {page.map(row => {
    prepareRow(row);
    return (
      <tr {...row.getRowProps()} key={row.id}>
        {row.cells.map(cell => (
          <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
        ))}
      </tr>
    );
  })}
</tbody>

      </table>
    </div>  

      {/* Updated Pagination Section */}
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div>
          <button
            className="btn btn-secondary me-2"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            {'< Previous'}
          </button>
          <button
            className="btn btn-secondary"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            {'Next >'}
          </button>
        </div>
        
        <div className="text-center">
          Page {pageIndex + 1} of {pageOptions.length}
        </div>
        
        <div>
          <select
            className="form-select"
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
          >
            {[5, 10, 20, 30].map(size => (
              <option key={size} value={size}>
                Show {size}
              </option>
            ))}
          </select>
        </div>
      </div>
      </div>
    </div>
  );
};

export default SewadarForm;
