import React, { useState, useEffect } from "react";
import Select from "react-select";
import { SERVER_BASE } from '../config/server';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faPlusCircle, faMinusCircle, faEdit } from '@fortawesome/free-solid-svg-icons';
import "bootstrap/dist/css/bootstrap.min.css";
import InventoryModal from "./InventoryModal";

const DeliveryChallan = ({ setUnsavedChanges, setPrefix,setRefNo }) => {
  const [prefixOptions, setPrefixOptions] = useState([]);
  const centerCode = sessionStorage.getItem('code');
  const email = sessionStorage.getItem('email');
  const username = sessionStorage.getItem('username');
  const [ledgerOptions, setLedgerOptions] = useState([]);
  const [purposeOptions, setPurposeOptions] = useState([]);
  const [TranportersOptions, setTranportersOptions] = useState([]);
  const [certificateText, setCertificateText] = useState('');
  const [activeTab, setActiveTab] = useState(0);
  const [inventoryOptions, setInventoryOptions] = useState([]);
  const [isPrefixEmpty, setIsPrefixEmpty] = useState(true);
  const [isReturnable, setIsReturnable] = useState(true);
  const [challanInfo, setChallanInfo] = useState({
    dispatchFrom: "Radha Soami Satsang Beas",
    prefix: "",
    refNo: "",
    date: new Date().toISOString().split('T')[0], // Prefill with today's date,
    ledger: "",
    purpose: "",
    vehicleNo: "",
    name: "",
    mobileNo: "",
    // onSewa: false,
    totalFreight: "",
    advancePaid: "",
    toPay: "",
    transporterName: "",
    transporterGSTIN: "",
    documentGRNo: "",
    documentGRDate: "",
    receivedGoods:'',
    centerCode:centerCode,
    onAccount:'',
    modeOfTransport:'',
    returnable:false,
    FreightOn:''
  });
  const tabs = ["Challan-Info-tab","Vehicle-Info-tab","Inventory-tab"];
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const handleCertificateChange = (newText) => {
    setCertificateText(newText);
  };
  

  const fetchRefNo = async (prefix) => {
    try {
      // console.log(prefix,'pere');
      
      const response = await axios.get(SERVER_BASE+"/generateRefNo", { params: { prefix,centerCode } });
      // console.log(response.data,'lof');
      const formattedRefNo = response.data.refNo.toString().padStart(3, '0');
      setChallanInfo((prev) => ({ ...prev, refNo: formattedRefNo }));
      setPrefix(prefix)
      setRefNo(formattedRefNo)
    } catch (error) {
      console.error("Error fetching reference number:", error);
    }
  };
  

  const handleNext = () => {
    
    if (!challanInfo.prefix) {
      alert("Please select a prefix before proceeding.");
    } else {
      // Proceed to the next step
      if (activeTab < tabs.length - 1) setActiveTab(activeTab + 1);
    }
  };

  const handleBack = () => {
    if (activeTab > 0) setActiveTab(activeTab - 1);
  };

  const handleSubmit = async () => {
    const payload = {
      challanInfo,
      inventoryRows,
      certificateText
    };

    try {
      const response = await axios.post(SERVER_BASE+"/saveDeliveryChallan", payload);
      alert("Delivery Challan saved successfully!");
      setUnsavedChanges(false);
      
    } catch (error) {
      console.error("Error saving delivery challan:", error);
    }
  };
  
  const openModal = (item) => {
    // console.log(item,'itemm');
    
    setModalData(item);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalData(null); 
    fetchTotalQuantityForItem()
  };
  // Mock data for Items and their UOMs
  const itemData = [
    { name: "Item 1", uom: "PCS" },
    { name: "Item 2", uom: "KG" },
    { name: "Item 3", uom: "MTR" },
  ];

  const onAccountOptions = [
    { value: "Regular", label: "Regular" },
    { value: "Visit", label: "Visit" },
  ]; 

  const ModeOfTransportOptions = [
    { value: "By Train", label: "By Train" },
    { value: "By Road", label:  "By Road" },
    { value: "By Hand", label:  "By Hand" },
  ];

  const goodsOptions = [
    // { value: "Received Goods/Returnable Goods", label: "Received Goods/Returnable Goods" },
    { value: "Received on Returnable Basis", label: "Received on Returnable Basis" },
    { value: "Sent on Returnable Basis ", label: "Sent on Returnable Basis " },
    { value: "Transfer ", label: "Transfer " },
  ]; // Single option for Goods dropdown


  // State for inventory rows
  const [inventoryRows, setInventoryRows] = useState([
    { id: 1, item: "", quantity: "", uom: "", remarks: "" },
  ]);

  const updateCertificateText = () => {
    if (challanInfo?.receivedGoods === "Received on Returnable Basis") {
      setCertificateText(
        `This is to certify that goods below are the property of <strong>${challanInfo?.ledger?.value || ""}</strong> and are being received in our satsang ghar for satsang use. The goods listed below are taken <strong>${challanInfo?.purpose?.value || ""}</strong>. The same are being returned back to <strong>${challanInfo?.ledger?.value || ""}</strong>. These goods are being sent loaded vehicle no. <strong>${challanInfo.vehicleNo || ""}</strong>, driven by <strong>${challanInfo.name || ""}</strong> having Mobile no. <strong>${challanInfo.mobileNo || ""}</strong>. It is therefore requested that these may please be allowed to proceed to their destination without any delay or hindrance.`
      );
    } else {
      setCertificateText(
        `This is to certify that the goods listed below are the property of Radha Soami Satsang Beas and are being sent to <strong>${challanInfo?.ledger?.value || ""}</strong><strong> (${challanInfo?.ledger?.gstin || ""})</strong>, <strong>${challanInfo?.ledger?.address || ""}</strong>, <strong>${challanInfo?.purpose?.value || ""}</strong>. These goods are being sent duly loaded in vehicle no. <strong>${challanInfo.vehicleNo || ""}</strong> driven by <strong>${challanInfo.name || ""}</strong> having Mobile no. <strong>${challanInfo.mobileNo || ""}</strong>. These goods are not for the purpose of sale or any other commercial use. It is therefore requested that these may please be allowed to proceed to its destination without any delay or hindrance.`
      );
    }
  };

  // Automatically update the certificate text when challanInfo changes
  useEffect(() => {
    updateCertificateText();
  }, [challanInfo]);


  
useEffect(()=>{
  if (challanInfo.receivedGoods === "Received on Returnable Basis") {
    setIsReturnable(false)
  }
  else if(challanInfo.receivedGoods === "Sent on Returnable Basis "){
    setIsReturnable(true)
  }
  else{
    setIsReturnable(false)
  }

}, [challanInfo]);


  useEffect(() => {
    fetchPrefix();
    fetchTransportersDetails()
    // fetchInventory()
  }, [centerCode]);

  useEffect(() => {
    if (centerCode) {
      fetchInventory();

    }
  }, [centerCode]);
  

  useEffect(() => {
    fetchledgermasterdetails();
    
    fetchTotalQuantityForItem()
  }, []);
  

  const fetchPurposeByLedger = async (ledgerGroup) => {
    try {
      const response = await axios.get(SERVER_BASE+'/getPurposeByLedger', {
        params: { ledgerName: ledgerGroup, code: centerCode },
      });
      // console.log(response.data,'jdj');
      
      const options = response.data.map((purpose) => ({
        value: purpose.purposeName, // Adjust based on API response structure
        label: purpose.purposeName,
      }));
      setPurposeOptions(options);
    } catch (error) {
      console.error('Error fetching purposes:', error);
    }
  };
  
  
  const fetchInventory = async () => {
    try {
      const response = await axios.get(SERVER_BASE+'/inventoryDetails', {
        params: { code: centerCode },
      });
      // console.log(response.data,'jdj');
      
      const options = response.data.map((inventory) => ({
        value: inventory.item, // Adjust based on API response structure
        label: inventory.item,
        location:inventory.location,
        quantity:inventory.quantity,
        uom:inventory.uom
      }));
      setInventoryOptions(options);
    } catch (error) {
      console.error('Error fetching purposes:', error);
    }
  };

  const fetchTotalQuantityForItem = async (itemName, prefix, refNo, centerCode) => {
    try {
      const response = await axios.get(SERVER_BASE+"/inventoryTotalQuantity", {
        params: { itemName, prefix, refNo, centerCode },
      });
      console.log(response.data,'fetchTotalQuantityForItem');
      
      return response?.data; // Return the fetched data
    } catch (error) {
      console.error("Error fetching total quantity:", error);
      return null; // Return null if there's an error
    }
  };

  
  const handleItemChange = async (rowId, option) => { 
    const selectedValue = option ? option.value : "";
  fetchInventory()
  
    // Update item in the row
    handleRowChange(rowId, "item", selectedValue);
  
    if (selectedValue) {
      // Fetch the total quantity for the selected item
      const totalQuantity = await fetchTotalQuantityForItem(
        selectedValue,
        challanInfo.prefix.value,
        challanInfo.refNo,
        centerCode
      );
  
      if (totalQuantity) {
        // Use functional setState to ensure immediate state update
        setInventoryRows(prevRows =>
          prevRows.map(row =>
            row.id === rowId
              ? { ...row, inputValue: totalQuantity.total || 0, uom: option.uom || "" }
              : row
          )
        );
      } else {
        // Clear total quantity and UOM if no data is fetched
        setInventoryRows(prevRows =>
          prevRows.map(row =>
            row.id === rowId
              ? { ...row, inputValue: 0, uom: "" }
              : row
          )
        );
      }
    } else {
      // Clear total quantity and UOM if no item is selected
      setInventoryRows(prevRows =>
        prevRows.map(row =>
          row.id === rowId
            ? { ...row, inputValue: 0, uom: "" }
            : row
        )
      );
    }
  
    // Open modal if needed
    if (option) {
      openModal({ itemName: selectedValue, centerCode });
    }
  };
  
  

  const fetchPrefix = async () => {
    try {
      const response = await axios.get(SERVER_BASE+'/prefixDetailsBySewadar', {
        params: { code:centerCode,email,username }, // Pass 'centerCode' as a parameter in the request
      });



      // Map the response data to options for the Select component
      const options = response.data.map((pre) => ({
        value: pre.prefix,
        label: pre.prefix,
      }));
      setPrefixOptions(options);
    } catch (error) {
      console.error('Error fetching prefix:', error);
    }
  };


  const fetchTransportersDetails = async () => {
    try {
      const response = await axios.get(SERVER_BASE+'/getTransportersDetails', {
        params: { code:centerCode }
      });


      // Map the response data to options for the Select component
      const options = response.data.map((trans) => ({
        value: trans.ledgerName,
        label: trans.ledgerName,
        gstin: trans.gstin, 

      }));
      setTranportersOptions(options);
    } catch (error) {
      console.error('Error fetching prefix:', error);
    }
  };




  const fetchledgermasterdetails = async () => {
    try {
      const response = await axios.get(SERVER_BASE+'/ledgerMasters');
      const options = response.data.map((ledger) => ({
        value: ledger.ledgerName,
        label: ledger.ledgerName,
        group: ledger.ledgerGroup,
        address: ledger.address,  
        gstin:ledger.gstin
      }));
      setLedgerOptions(options);
      // setledgerMasterData(response.data);
    } catch (error) {
      console.error('Error fetching ledger master details:', error);
    }
  };
  
  // Add a new inventory row
  const addRow = () => {
    const newRow = { id: Date.now(), item: "", quantity: "", uom: "", remarks: "" };
    setInventoryRows([...inventoryRows, newRow]);
    setUnsavedChanges(true);

  };

  // Delete a row
  const deleteRow = (id) => {
    if (inventoryRows.length > 1) {
      setInventoryRows(inventoryRows.filter((row) => row.id !== id));
      setUnsavedChanges(true);
    } else {
      alert("At least one row must remain.");
    }
  };
  const handleRowChange = (id, field, value) => {
    setInventoryRows((prevRows) =>
      prevRows.map((row) => {
        if (row.id === id) {
          // Update for item name change
          if (field === "itemName") {
            // If the field is cleared, reset related fields
            if (!value) {
              return {
                ...row,
                itemName: null,
                uom: "", // Clear UOM
                inputValue: 0, // Reset total quantity
              };
            }
  
            // Find the selected item in inventoryOptions
            const selectedItem = inventoryOptions.find((option) => option.value === value);
  console.log(selectedItem,'sel');
  
            // Fetch total quantity for the selected item immediately
            fetchTotalQuantityForItem(selectedItem?.value, challanInfo.prefix.value, challanInfo.refNo, centerCode)
              .then((totalQuantity) => {
                // Update row with fetched total quantity
                setInventoryRows((prevRows) =>
                  prevRows.map((row) => {
                    if (row.id === id) {
                      return {
                        ...row,
                        itemName: value, // Set item name
                        uom: selectedItem?.uom || "", // Auto-fill UOM
                        inputValue: totalQuantity?.total || 0, // Set the fetched total quantity
                      };
                    }
                    return row;
                  })
                );
              })
              .catch((error) => {
                console.error("Error fetching total quantity:", error);
              });
  
            return { ...row, [field]: value };
          }
  
          // Handle other fields
          return {
            ...row,
            [field]: value,
          };
        }
  
        return row;
      })
    );
  
    setUnsavedChanges(true); // Track unsaved changes
  };
    
  useEffect(() => {
    setIsPrefixEmpty(challanInfo.prefix === ""); // Set flag if prefix is empty
  }, [challanInfo.prefix]);

  const handleChallanChange = (field, value) => {
    setChallanInfo((prevInfo) => {
      if (field === "ledger") {
        // If the field is cleared (value is null), reset the purpose field
        if (!value) {
          return {
            ...prevInfo,
            ledger: null,
            purpose: null, // Clear Purpose
          };
        }
  
        // Fetch purposes when a ledger is selected
        const selectedLedger = ledgerOptions.find((ledger) => ledger.value === value.value);
        if (selectedLedger) {
          fetchPurposeByLedger(selectedLedger.group);
        }
  
        return {
          ...prevInfo,
          [field]: value, // Update ledger
        };
      }
  
      if (field === "transporterName") {
        // If the field is cleared (value is null), reset the transporter-related fields
        if (!value) {
          return {
            ...prevInfo,
            transporterName: null,
            transporterGSTIN: "", // Clear GSTIN
          };
        }
  
        // Find the selected transporter option
        const selectedTransporter = TranportersOptions.find((option) => option.value === value.value);
  
        return {
          ...prevInfo,
          [field]: value, // Update transporter name
          transporterGSTIN: selectedTransporter?.gstin || "", // Auto-fill GSTIN or reset if not found
        };
      }
  
      return { ...prevInfo, [field]: value };
    });
    setUnsavedChanges(true);
    
  };
 
  return (
    <>
    <div className="container formcontainer mt-5">
      <h2 className="">Delivery Challan</h2>

      {/* Tabs */}
      <ul className="nav nav-tabs mt-4">
  {tabs.map((tab, index) => (
    <li className="nav-item" key={tab}>
      <button
        className={`nav-link ${activeTab === index ? "active" : ""}`}
        onClick={() => {
          if (!challanInfo.prefix && index !== 0) {
            alert("Please select a prefix before switching tabs.");
            return; // Prevent tab switch
          }
          setActiveTab(index); // Allow tab switch
        }}
        disabled={challanInfo.prefix === "" && index !== 0} // Disable other tabs when prefix is empty
      >
        {tab.replace("-tab", "").replace("-", " ")}
      </button>
    </li>
  ))}
</ul>



      {/* Tab Content */}
      <div className="tab-content mt-3">
        {/* Inventory Tab */}
          <div className={`tab-pane fade ${activeTab === 2 ? "show active" : ""}`} id="Inventory-tab">
    {inventoryRows.map((row, index) => {
      const selectedInventory = inventoryOptions.find((option) => option.value === row.item);

      return (
        <div key={row.id} className="row mb-3">
          <div className="col-md-1">
            <label className="form-label">S.No</label>
            <input type="number" className="form-control" value={index + 1} disabled />
          </div>

          <div className="col-md-3">
            <label className="form-label">Item Name</label>
            <Select
  options={inventoryOptions}
  value={inventoryOptions.find((option) => option.value === row.item)}
  onChange={(option) => handleItemChange(row.id, option)}
  placeholder="Select Item"
  isClearable
/>

            </div>

          <div className="col-md-2">
            <label className="form-label">Total Quantity</label>
            <input
              type="number"
              className="form-control"
              value={row.inputValue || ""}
              onChange={(e) => handleRowChange(row.id, "inputValue", e.target.value)}
              placeholder="Enter value"
              disabled
            />
          </div>
          <div className="col-md-2">
            <label className="form-label">UOM</label>
            <input
              type="text"
              className="form-control"
              value={row.uom || ""}
              onChange={(e) => handleRowChange(row.id, "uom", e.target.value)}
              placeholder="UOM"
              disabled
            />
          </div>
          <div className="col-md-2">
            <label className="form-label">Remarks</label>
            <input
              type="text"
              className="form-control"
              value={row.remarks}
              onChange={(e) => handleRowChange(row.id, "remarks", e.target.value)}
            />
          </div>

          <div className="col-md-1">
          <label className="form-label">Returnable</label>
          <div className="form-check form-switch">
          <input
            type="checkbox"
            className="form-check-input"
            checked={isReturnable || row.returnable || false}
            onChange={(e) => handleRowChange(row.id, "returnable", e.target.checked)}
            disabled={challanInfo.receivedGoods === "Received on Returnable Basis"}
          /></div>
        </div>

          <div className="col-md-1">
            <button
              className="btn btn-link text-danger"
              onClick={() => deleteRow(row.id)}
              disabled={inventoryRows.length === 1}
            >
              <FontAwesomeIcon icon={faMinusCircle} />
            </button>
            <button className="btn btn-link text-success p-0" onClick={addRow}>
              <FontAwesomeIcon icon={faPlusCircle} />
            </button>
          </div>
        </div>
      );
    })}

    {isModalOpen && (
      <InventoryModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        data={modalData}
        refNo={challanInfo.refNo}
        prefix={challanInfo.prefix}
        fetchTotalQuantityForItem={fetchTotalQuantityForItem}

      />
    )}
  </div>


        <div className={`tab-pane fade ${activeTab === 0 ? "show active" : ""}`} id="Challan-Info-tab">
          <div className="row">
          <div className="col-md-4">
              <label className="form-label">Prefix</label>
              <Select
  options={prefixOptions}
  value={challanInfo.prefix}
  onChange={(option) => {
    handleChallanChange("prefix", option);
    if (option) {
      // console.log(option,'option');
      
      fetchRefNo(option.value); // Fetch the refNo for the selected prefix
    } else {
      setChallanInfo((prev) => ({ ...prev, refNo: "" })); // Clear refNo if prefix is cleared
    }
  }}  
  placeholder="Select Prefix"
  
  isClearable
/>

            </div>

<div className="col-md-2">
  <label className="form-label">Ref No</label>
  <input
    type="text"
    className="form-control"
    value={challanInfo.refNo}
    readOnly // Make it read-only
  />
</div>


            <div className="col-md-3">
              <label className="form-label">Date</label>
              <input
                type="date"
                className="form-control"
                value={challanInfo.date}
                onChange={(e) => handleChallanChange("date", e.target.value)}
              disabled/> 
            </div>
           
            <div className="col-md-3">
        <label className="form-label">On Account</label>
        <Select
          options={onAccountOptions} 
          value={challanInfo.onAccount}
          onChange={(option) => handleChallanChange("onAccount", option)}
          placeholder="Select On Account"
          isClearable
        />
      </div>

          </div>

          {/* Challan Info Fields */}
          <div className="row">
          <div className="col-md-4">
  <label className="form-label">Status</label>
  <Select 
  options={goodsOptions}
  value={goodsOptions.find((option) => option.value === challanInfo.receivedGoods)}
  onChange={(option) => {
    handleChallanChange("receivedGoods", option ? option.value : null);
    updateCertificateText(option?.value); // Call the function to update certificate text
  }}
/>

</div>
            
            <div className="col-md-4">
              <label className="form-label">Ledger</label>
              <Select
                options={ledgerOptions}
                value={challanInfo.ledger}
                onChange={(option) => handleChallanChange("ledger", option)}
                isClearable
              />
            </div>
           

            <div className="col-md-4">
  <label className="form-label">Purpose</label>
  <Select
    options={purposeOptions}
    value={challanInfo.purpose}
    onChange={(option) => handleChallanChange("purpose", option)}
    placeholder="Select Purpose"
    isClearable
  />
</div>

          </div>

          {/* On Sewa Checkbox */}
         
          <div className="container formcontainer mt-3">
      <h4 className="mb-3">To whomsoever it may concern</h4>
      <div
        className="form-control"
        rows="8"
        contentEditable="true"
        dangerouslySetInnerHTML={{
          __html: certificateText
        }}
        onInput={(e) => handleCertificateChange(e.target.innerHTML)} // Update certificate text on input
      />
    </div>

        </div>
        <div className={`tab-pane fade ${activeTab === 1 ? "show active" : ""}`} id="Vehicle-Info-tab">
  <div className="row">
    <div className="col-md-3">
      <label className="form-label">Freight on</label>
      <Select
        options={[
          { label: "On Sewa", value: "On Sewa" },
          { label: "On Payment", value: "onPayment" },
        ]}
        value={challanInfo.FreightOn?.value}
        onChange={(option) => handleChallanChange("FreightOn", option?.value)}
        placeholder="Select Freight On"
        isClearable
      />
    </div>
  </div>

  <div className="row">
    <div className="col-md-3">
      <label className="form-label">Mode</label>
      <Select
        options={ModeOfTransportOptions}
        value={challanInfo.modeOfTransport} // Match selected option
        onChange={(option) => handleChallanChange("modeOfTransport", option)} // Handle null value
        placeholder="Mode of transport"
        isClearable
      />
    </div>

    <div className="col-md-3">
      <label className="form-label">{challanInfo.modeOfTransport?.value === "By Train"?'Train No/Name':'Vehicle No'}</label>
      <input
        type="text"
        className="form-control"
        value={challanInfo.vehicleNo}
        onChange={(e) => handleChallanChange("vehicleNo", e.target.value)}
        disabled={challanInfo.modeOfTransport?.value === "By Hand"} // Disable if "By Hand" is selected
      />
    </div>

    <div className="col-md-3">
      <label className="form-label">Name</label>
      <input
        type="text"
        className="form-control"
        value={challanInfo.name}
        onChange={(e) => handleChallanChange("name", e.target.value)}
      />
    </div>

    <div className="col-md-3">
      <label className="form-label">Mobile No</label>
      <input
        type="text"
        className="form-control"
        value={challanInfo.mobileNo}
        onChange={(e) => handleChallanChange("mobileNo", e.target.value)}
      />
    </div>
  </div>

  {/* Fields for Total Freight, Advance Paid, To Pay, Transporter Details */}
  {challanInfo.FreightOn !== "On Sewa" && (
    <div className="row">
      <div className="col-md-3">
        <label className="form-label">Total Freight</label>
        <input
          type="text"
          className="form-control"
          value={challanInfo.totalFreight}
          onChange={(e) => handleChallanChange("totalFreight", e.target.value)}
          disabled={challanInfo.modeOfTransport?.value === "By Hand"} // Disable if "By Hand" is selected
        />
      </div>

      <div className="col-md-3">
        <label className="form-label">Advance Paid</label>
        <input
          type="text"
          className="form-control"
          value={challanInfo.advancePaid}
          onChange={(e) => handleChallanChange("advancePaid", e.target.value)}
          disabled={challanInfo.modeOfTransport?.value === "By Hand"} // Disable if "By Hand" is selected
        />
      </div>

      <div className="col-md-3">
        <label className="form-label">To Pay</label>
        <input
          type="text"
          className="form-control"
          value={challanInfo.toPay}
          onChange={(e) => handleChallanChange("toPay", e.target.value)}
          disabled={challanInfo.modeOfTransport?.value === "By Hand"} // Disable if "By Hand" is selected
        />
      </div>

      <div className="col-md-3">
        <label className="form-label">Transporter Name</label>
        <Select
          options={TranportersOptions}
          value={challanInfo.transporterName}
          onChange={(option) => handleChallanChange("transporterName", option)}
          isClearable
          isDisabled={challanInfo.modeOfTransport?.value === "By Hand"} // Disable if "By Hand" is selected
        />
      </div>

      <div className="col-md-3">
        <label className="form-label">Transporter GSTIN</label>
        <input
          type="text"
          className="form-control"
          value={challanInfo.transporterGSTIN}
          onChange={(e) => handleChallanChange("transporterGSTIN", e.target.value)}
          readOnly
          disabled={challanInfo.modeOfTransport?.value === "By Hand"} // Disable if "By Hand" is selected
        />
      </div>

      <div className="col-md-3">
        <label className="form-label">Document/GR No.</label>
        <input
          type="text"
          className="form-control"
          value={challanInfo.documentGRNo}
          onChange={(e) => handleChallanChange("documentGRNo", e.target.value)}
          disabled={challanInfo.modeOfTransport?.value === "By Hand"} // Disable if "By Hand" is selected
        />
      </div>

      <div className="col-md-3">
        <label className="form-label">Document/GR Date</label>
        <input
          type="date"
          className="form-control"
          value={challanInfo.documentGRDate}
          onChange={(e) => handleChallanChange("documentGRDate", e.target.value)}
          disabled={challanInfo.modeOfTransport?.value === "By Hand"} // Disable if "By Hand" is selected
        />
      </div>
    </div>
  )}
</div>

        <div className="d-flex justify-content-between mt-4">
          <button className="btn btn-primary" onClick={handleBack} disabled={activeTab === 0}>
            Back
          </button>
          {activeTab < tabs.length - 1 ? (
            <button className="btn btn-primary" onClick={handleNext}>
            Next
          </button>
          ) : (
            <button className="btn btn-success" onClick={handleSubmit}>
              Submit
            </button>
          )}
        </div>
              
      </div>
    </div>
    
    </>
  );
};

export default DeliveryChallan;
